import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Form } from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";
import * as formHelpers from "../../scripts/formHelpers";
import { MotifButton, MotifIcon, MotifIconButton, MotifSelect, MotifOption, MotifLabel, MotifFormField, MotifDropdown, MotifDropdownItem, MotifTooltip, MotifToast } from '@ey-xd/motif-react';
import { IconoirNavArrowDown, IconoirInfoCircle } from '@ey-xd/motif-icon';
import { addAttribute } from "../../common/Attribute";

const ManageChecklistHeader = ({
    calendarYears,
    formSyncErrors,
    selectedCalendarYear,
    isSystemOrEngagementAdmin,
    isEngagementUser,
    selectedPsqArqc,
    clientIsAudit,
    handleChangePsqArqc,
    resetGridColumnState,
    toggleShowAddChecklistUserModal,
    toggleShowDeleteChecklistUserModal,
    toggleShowDeleteChecklistModal,
    toggleShowResendChecklistModal,
    toggleShowSignoffChecklistModal,
    toggleShowReopenForEYReviewChecklistModal,
    toggleShowApproveClientReopenRequestModal,
    toggleShowDenyClientReopenRequestModal,
    handleExportChecklistSummary,
    gridCurrentState,
    gridFilteredRowCurrentState,
    selectedRecipients,
    toggleShowDownloadChecklistsModal,
    externalUserVisibilityData,
}) => {

    const [actionOptionsOpen, setActionOptionsOpen] = useState(false);
    const [hideInfoMessage, setHideInfoMessage] = useState(false);
    useEffect(() => {

        //Add Attribute(type=button) to all button elements having attribute(role = 'combobox')
        addAttribute("button[role = 'combobox']", "type", "button");
    });

    const calendarYearsOptions = calendarYears.map(y => {
        return {
            key: y,
            text: y,
            value: y
        }
    });

    const psqArqcOptions = [
        { key: 'ALL', value: 'ALL', text: 'ALL' },
        { key: 'PSQ', value: 'PSQ', text: 'PSQ' },
        { key: 'ARQC', value: 'ARQC', text: 'ARQC' },
    ];

    const hasFormSyncErrors = Object.keys(formSyncErrors).length !== 0;

    const quarterStatusList = ['reporting closed', 'complete'];

    let resendChecklistActionStatus = true;
    let resendChecklistTooltipMessage = '';
    // Check if there is any recipient selected for resend
    if (!selectedRecipients || !selectedRecipients.length > 0) {
        resendChecklistActionStatus = false;
        resendChecklistTooltipMessage = "You have not selected any recipients in order to perform this action.";
    }
    // Check if there is any checklist (for selected recipients) falls into closed report period
    else if (gridCurrentState && selectedRecipients && selectedRecipients.length > 0) {

        //Get the selected checkist ids
        let distinctChecklistIDs = [...new Set(selectedRecipients.map(rcp => rcp.checklistID))];
        // Get the selected checkist record by distinct checklist ids
        let selectedChecklistRecords = [...new Set(gridCurrentState.filter(x => distinctChecklistIDs.includes(x.checklistID)))];
        let selectedDataVisibility = externalUserVisibilityData.filter(item => selectedChecklistRecords.map(x => x.reportPeriodID).includes(item.reportPeriodID)).filter(item => item.isReportPeriodVisibleToExternalUsers === false);
        let selectedRecords = gridCurrentState.filter(x => selectedRecipients.some(sr => sr.checklistID === x.checklistID && sr.checklistType === x.checklistType));
 
        //If any of seleced checklist record having Hidden visibility, then disable the Delete User action
        if ((selectedDataVisibility && selectedDataVisibility?.length > 0) && (isSystemOrEngagementAdmin || isEngagementUser)) {
             resendChecklistActionStatus = false;
        } else if (selectedRecords && selectedRecords.some(record => quarterStatusList.includes(record.quarterStatus.toLowerCase()))) {
                resendChecklistActionStatus = false;
                resendChecklistTooltipMessage = "You have selected a recipient within a Closed period.";
        }
    }

    // If checklist status is either in completed or pendingeyreview status then checklist should not be allowed to add/delete recipients.
    const checklistStatusList = ['completed', 'pendingeyreview'];

    let addUserActionStatus = true;
    let addUserValidationMessage = [];
    let externalUserVisibility = true;
    
    let isChecklistSelected = gridCurrentState && gridCurrentState.some(record => record.checklistSelection === true);
    if (isChecklistSelected) {        
        //Get the selected checkist records
        let selectedRecords = gridCurrentState.filter(x => x.checklistSelection === true);
        let selectedDataVisibility = externalUserVisibilityData && externalUserVisibilityData.filter(item => selectedRecords.map(x => x.reportPeriodID).includes(item.reportPeriodID)).filter(item => item.isReportPeriodVisibleToExternalUsers === false)
        
        if ((selectedDataVisibility && selectedDataVisibility?.length > 0) && (isSystemOrEngagementAdmin || isEngagementUser)) {
            addUserActionStatus = false;
        }     
        //If any Report period of selected checklist records having Reporting Closed/Complete status then disable the Add User action
        else if (selectedRecords.some(record => quarterStatusList.includes(record.quarterStatus.toLowerCase()))) {
            addUserActionStatus = false;
            addUserValidationMessage.push('Period of the selected checklist(s) is either Closed For Reporting or Completed.');
        }
    }
    else {
        addUserActionStatus = false;
        addUserValidationMessage.push('You have not selected any checklist(s) in order to perform this action.');
    }

    let deleteUserActionStatus = true;
    let deleteUserValidationMessage = [];
    if (!selectedRecipients || selectedRecipients.length === 0) {
        deleteUserActionStatus = false;
        deleteUserValidationMessage.push('You have not selected any user(s) in order to perform this action.');
    }
    else {
        //Get the selected checkist ids
        let distinctChecklistIDs = [...new Set(selectedRecipients.map(rcp => rcp.checklistID))];
        // Get the selected checkist record by distinct checklist ids
        let selectedRecords = [...new Set(gridCurrentState.filter(x => distinctChecklistIDs.includes(x.checklistID)))];
        let selectedDataVisibility = externalUserVisibilityData.filter(item => selectedRecords.map(x => x.reportPeriodID).includes(item.reportPeriodID)).filter(item => item.isReportPeriodVisibleToExternalUsers === false);
        
        //If any of seleced checklist record having Hidden visibility, then disable the Delete User action
        if ((selectedDataVisibility && selectedDataVisibility?.length > 0) && (isSystemOrEngagementAdmin || isEngagementUser)) {
            deleteUserActionStatus = false;
        } else {
            //If any seleced checklist record having Completed/Pending EY Review status, then disable the Delete User action
            if (selectedRecipients.some(record => checklistStatusList.includes(record.checklistState.toLowerCase()))) {
                deleteUserActionStatus = false;
                deleteUserValidationMessage.push('Selected checklist(s) is either Pending for EY Review or Completed.');
            }
            //If any Report period of selected checklist records having Reporting Closed/Complete status then disable Delete User action
            if (selectedRecipients.some(record => quarterStatusList.includes(record.quarterStatus.toLowerCase()))) {
                deleteUserActionStatus = false;
                deleteUserValidationMessage.push('Period of the selected checklist(s) is either Closed For Reporting or Completed.');
            }
        }
    }

    let deleteChecklistsActionStatus = true;
    let deleteChecklistValidationMessage = [];
    if (isChecklistSelected) {
        let selectedRecords = gridCurrentState.filter(x => x.checklistSelection === true);

        if (selectedRecords && selectedRecords.some(record => quarterStatusList.includes(record.quarterStatus.toLowerCase()))) {
            deleteChecklistsActionStatus = false;
            deleteChecklistValidationMessage.push('Period of the selected checklist(s) is either Closed For Reporting or Completed.');
        }
    } else {
        deleteChecklistsActionStatus = false;
        deleteChecklistValidationMessage.push('You have not selected any checklist(s) in order to perform this action.');
    }

    // If checklist status is either is with status 'created','sent', 'inprogress', 'pendingeyreview' status then only enable Sign-Off Action status.
    let signoffRequiredChecklistStatusList = ['created', 'sent', 'inprogress', 'pendingeyreview'];

    let signoffChecklistActionStatus = true;
    let reopenforEYReviewChecklistActionStatus = true;
    let signoffChecklistValidationMessage = [];
    let reopenforEYReviewChecklistValidationMessage = [];

    if (isChecklistSelected) {
        //Get the selected checkist records
        let selectedRecords = gridCurrentState.filter(x => x.checklistSelection === true);

        //  If any of selected checklist is not completed then disable the Reopen for EY Review Checklist Action  
        if (selectedRecords.some(record => record.checklistState.toLowerCase() !== 'completed')) {
            reopenforEYReviewChecklistActionStatus = false;
            reopenforEYReviewChecklistValidationMessage.push('Selected checklist(s) is not Signed Off.');
        }

        //If all checklists are with these statuses 'created','sent', 'inprogress', 'pendingeyreview' then only Enable the Sign-Off checklist Action
        if (selectedRecords.some(record => !signoffRequiredChecklistStatusList.includes(record.checklistState.toLowerCase()))) {
            signoffChecklistActionStatus = false;
            signoffChecklistValidationMessage.push('Selected checklist(s) is Signed Off.');
        }

        //If any Report period of selected checklist records having Reporting Closed/Complete status then disable both the actions Sign-off/Reopen for EY Review Checklist action
        if (selectedRecords.some(record => quarterStatusList.includes(record.quarterStatus.toLowerCase()))) {
            signoffChecklistActionStatus = false;
            signoffChecklistValidationMessage.push('Period of the selected checklist(s) is either Closed For Reporting or Completed.');

            reopenforEYReviewChecklistActionStatus = false;
            reopenforEYReviewChecklistValidationMessage.push('Period of the selected checklist(s) is either Closed For Reporting or Completed.');
        }
    }
    else {
        signoffChecklistActionStatus = false;
        reopenforEYReviewChecklistActionStatus = false;

        signoffChecklistValidationMessage.push('You have not selected any checklist(s) in order to perform this action.');
        reopenforEYReviewChecklistValidationMessage.push('You have not selected any checklist(s) in order to perform this action.');
    }

    let downloadChecklistActionStatus = true;
    let downloadChecklistValidationMessage = [];
    if (!isChecklistSelected) {
        downloadChecklistActionStatus = false;
        downloadChecklistValidationMessage.push('You have not selected any checklist(s) in order to perform this action.');
    }

    let approveDenyClientReopenRequestActionStatus = true;
    let approveDenyClientReopenRequestValidationMessage = [];
    if (isChecklistSelected) {
        let selectedRecords = gridCurrentState.filter(x => x.checklistSelection);
        // If any of the checklist has external user visibility hidden
        let selectedDataVisibility = externalUserVisibilityData && externalUserVisibilityData.filter(item => selectedRecords.map(x => x.reportPeriodID).includes(item.reportPeriodID)).filter(item => item.isReportPeriodVisibleToExternalUsers === false);
        
        if ((selectedDataVisibility && selectedDataVisibility?.length > 0) && (isSystemOrEngagementAdmin || isEngagementUser)) {
            externalUserVisibility = false;           
        }           
          
        //  If there are no pending open requests for selected checklists then disable the Approve/Deny Reopen Request Action
        if (selectedRecords.some(record => !record.reopenRequestStatusDescription || record.reopenRequestStatusDescription.toLowerCase() !== 'pending')) {
            approveDenyClientReopenRequestActionStatus = false;
            approveDenyClientReopenRequestValidationMessage.push('Selected checklist(s) is not in Pending Reopen Request status.');
        }

        //  If any Report period of selected checklist records having Reporting Closed/Complete status then disable both the actions Approve/Deny Client Reopen Request action
        if (selectedRecords && selectedRecords.some(record => quarterStatusList.includes(record.quarterStatus.toLowerCase()))) {
            approveDenyClientReopenRequestActionStatus = false;
            approveDenyClientReopenRequestValidationMessage.push('Period of the selected checklist(s) is either Closed For Reporting or Completed.');
        }
    }

     else {
        approveDenyClientReopenRequestActionStatus = false;
        approveDenyClientReopenRequestValidationMessage.push('You have not selected any checklist(s) in order to perform this action.');
    }
    
    let reopenRequestCount = gridFilteredRowCurrentState && gridFilteredRowCurrentState.length > 0
        && gridFilteredRowCurrentState.filter(record => record.reopenRequestStatusDescription && record.reopenRequestStatusDescription.toLowerCase() === 'pending').length
        || 0;

    let exportChecklistSummaryActionStatus = true;
    let exportChecklistSummaryValidationMessage = [];
    if (!isChecklistSelected) {
        exportChecklistSummaryActionStatus = false;
        exportChecklistSummaryValidationMessage.push('You have not selected any checklist(s) in order to perform this action.');
    }        

    // checking all the report periods of displayed checklists have external user visibility true
    let areAllReportPeriodsVisible = externalUserVisibilityData && externalUserVisibilityData.every(item => item.isReportPeriodVisibleToExternalUsers);

    return (<div>
        <div style={{ minHeight: '24px' }}></div>
        <form>
            {
                calendarYears && calendarYears.length > 0 &&
                <div className="row">
                    <div className="col-md-3">
                        <div className="row">
                            <div className="col-md-6 pl-3">
                                <Field 
                                    name="calendarYear"
                                    label="Calendar Year"
                                    placeholder="Select Calendar Year..."
                                    options={calendarYearsOptions}
                                    testingData={{ label: "lblCalendarYear", div: "divCalendarYear" }}
                                    component={formHelpers.SingleSelectComponent}/>
                            </div>
                            <div className="col-md-5 pl-2">
                                {/* <MotifFormField>
                                    <MotifLabel data-testid="lblPsqArqc">PSQ / ARQC</MotifLabel>
                                    <MotifSelect
                                        name="psqArqc"
                                        value={selectedPsqArqc}
                                        onChange={handleChangePsqArqc}
                                        data-testid="divPsqArqc"
                                        disabled={clientIsAudit}
                                    >
                                        {psqArqcOptions.map(pao => (
                                            <MotifOption data-testvalue={pao.text} key={pao.value} value={pao.value}>{pao.text}</MotifOption>
                                        ))}
                                    </MotifSelect>
                                </MotifFormField> */}
                                <Field 
                                    name="psqArqc"
                                    label="PSQ / ARQC"
                                    value={selectedPsqArqc}
                                    onChange={handleChangePsqArqc}
                                    data-testid="lblPsqArqc"
                                    options={psqArqcOptions}
                                    disabled={clientIsAudit}
                                    testingData={{ label: "lblPsqArqc", div: "divPsqArqc" }}
                                    component={formHelpers.SingleSelectComponent} 
                                    defaultValue={psqArqcOptions[0].value}/>
                            </div>                           
                        </div>
                    </div>
                    <div className="col-md-2 pl-3">
                    </div>
                    <div className="col-md-6 pl-3 d-flex justify-content-end">
                        {!hideInfoMessage &&
                            reopenRequestCount > 0 &&
                            <MotifToast className="mr-5" onClose={setHideInfoMessage}>EY action is required to reopen {reopenRequestCount} checklists to the client. Please see the Reopen Request column</MotifToast>
                        }
                    </div>
                    <div className="col-md-1 mt-1 d-flex justify-content-end">
                        {gridCurrentState != null && Array.isArray(gridCurrentState) && gridCurrentState.length > 0 &&
                            <div style={{ width: 'fit-content' }}>
                                <MotifDropdown
                                    open={actionOptionsOpen}
                                    placement="bottom-end"
                                    id={'action-dropdown'}
                                    className="checklist-dropdown-action"                                   
                                    aria-labelledby={'manage-checklist-trigger'}
                                    handleClickOutside={() => setActionOptionsOpen(false)}
                                    trigger={
                                        <MotifButton
                                            type="button"
                                            variant="primary"
                                            onClick={() => setActionOptionsOpen(!actionOptionsOpen)}
                                            aria-controls={'action-dropdown'}
                                            id={'action-dropdown-trigger'}
                                            aria-expanded={actionOptionsOpen}
                                            aria-label="Action dropdown"
                                            data-testid="actions"
                                        >
                                            {   !areAllReportPeriodsVisible && <MotifTooltip 
                                                trigger={
                                                <MotifIcon iconFunction={IconoirInfoCircle} size='24' fill='none' className="mt-1"/>
                                                }>
                                                    <div>Due to EY's independence policy, the following actions are disabled as the client no longer has access to these report periods: <br/>
                                                         'Add User(s)', 'Delete User(s)', 'Approve/Deny Client Reopen Request(s)', and 'Resend Checklist(s)'. <br />
                                                         Please email 'reitsuite@ey.com' for additional support.
                                                    </div>
                                                </MotifTooltip> }
                                            Actions <MotifIcon iconFunction={IconoirNavArrowDown} size='24' fill='none' />
                                        </MotifButton>
                                    }
                                >
                                <ul role="menu">
                                    {addUserActionStatus || addUserValidationMessage.length === 0 ?
                                        <li role="menuitem">                                    
                                            <MotifDropdownItem onClick={() => { setActionOptionsOpen(false); toggleShowAddChecklistUserModal(); }} type="button"
                                                disabled={!addUserActionStatus}
                                                data-testid="addUsers"
                                                style={{
                                                    display: 'block',
                                                    width: '100%',
                                                    boxSizing: 'border-box',
                                                    padding: '10px',
                                                    cursor: 'pointer'
                                                  }}
                                            >
                                        Add User(s)
                                            </MotifDropdownItem>
                                        </li>
                                        :
                                        <li role="menuitem">  
                                            <MotifTooltip
                                                trigger={
                                                    <MotifDropdownItem onClick={() => { setActionOptionsOpen(false); toggleShowAddChecklistUserModal(); }} type="button"
                                                        disabled={!addUserActionStatus} data-testid="addUsers"
                                                    >
                                                Add User(s)
                                                        <MotifIconButton className="ml-2 mr-5" aria-label="Add Users" type="button">
                                                            <MotifIcon iconFunction={IconoirInfoCircle} size='24' fill='none' />
                                                </MotifIconButton>
                                                    </MotifDropdownItem>}
                                            >
                                            <ul style={{ margin: '0px' }}>{(addUserValidationMessage.map(msg => <li className="checklistActionToolTipContent">{msg}</li>))}</ul>
                                            </MotifTooltip>
                                        </li>
                                }

                                    {deleteUserActionStatus || deleteUserValidationMessage.length === 0 || !externalUserVisibility?
                                        <li role="menuitem"> 
                                            <MotifDropdownItem onClick={() => { setActionOptionsOpen(false); toggleShowDeleteChecklistUserModal(); }} type="button"
                                                disabled={!deleteUserActionStatus}
                                                data-testid="deleteUsers"  
                                                style={{
                                                    display: 'block',
                                                    width: '100%',
                                                    boxSizing: 'border-box',
                                                    padding: '10px',
                                                    cursor: 'pointer'
                                                  }}
                                             >
                                                Delete User(s)
                                            </MotifDropdownItem>
                                        </li>
                                        :
                                        <li role="menuitem"> 
                                            <MotifTooltip
                                                trigger={
                                                    <MotifDropdownItem onClick={() => { setActionOptionsOpen(false); toggleShowDeleteChecklistUserModal(); }} type="button"
                                                        disabled={!deleteUserActionStatus} data-testid="deleteUsers">
                                                        Delete User(s)
                                                        <MotifIconButton className="ml-2" aria-label="Delete Users" type="button">
                                                            <MotifIcon iconFunction={IconoirInfoCircle} size='24' fill='none' />
                                                        </MotifIconButton>
                                                    </MotifDropdownItem>}
                                            >
                                                <ul style={{ margin: '0px' }}>{(deleteUserValidationMessage.map(msg => <li className="checklistActionToolTipContent">{msg}</li>))}</ul>
                                            </MotifTooltip>
                                        </li>
                                    }

                                    {(deleteChecklistsActionStatus && (
                                        <li role="menuitem">
                                            <MotifDropdownItem onClick={() => { setActionOptionsOpen(false); toggleShowDeleteChecklistModal(); }}
                                                type="button" disabled={!gridCurrentState || !gridCurrentState.some(record => record.checklistSelection === true)}
                                                title="Delete Checklist(s)" data-testid="deleteChecklists"
                                                style={{
                                                    display: 'block',
                                                    width: '100%',
                                                    boxSizing: 'border-box',
                                                    padding: '10px',
                                                    cursor: 'pointer'
                                                  }}
                                            >
                                                Delete Checklist(s)
                                            </MotifDropdownItem>
                                        </li>
                                        )) || (
                                            <li role="menuitem">
                                                <MotifTooltip
                                                    trigger={
                                                        <MotifDropdownItem onClick={() => { setActionOptionsOpen(false); toggleShowDeleteChecklistModal(); }} type="button"
                                                            disabled={!deleteChecklistsActionStatus} data-testid="deleteChecklists"
                                                        >
                                                            Delete Checklist(s)
                                                            <MotifIconButton className="ml-2" aria-label="Delete Checklists" type="button">
                                                                <MotifIcon iconFunction={IconoirInfoCircle} size='24' fill='none' />
                                                            </MotifIconButton>
                                                        </MotifDropdownItem>}
                                                >
                                                    <ul style={{ margin: '0px' }}>{(deleteChecklistValidationMessage.map(msg => <li className="checklistActionToolTipContent">{msg}</li>))}</ul>
                                                </MotifTooltip>
                                            </li>
                                        )}

                                    {(isSystemOrEngagementAdmin || isEngagementUser)? (externalUserVisibility && approveDenyClientReopenRequestActionStatus &&
                                        <li role="menuitem">
                                            <MotifDropdownItem onClick={() => { setActionOptionsOpen(false); toggleShowApproveClientReopenRequestModal(); }} type="button"
                                                disabled={false} data-testid="approveClientReopenRequest"
                                                style={{
                                                    display: 'block',
                                                    width: '100%',
                                                    boxSizing: 'border-box',
                                                    padding: '10px',
                                                    cursor: 'pointer'
                                                  }}
                                            >
                                                Approve Client Reopen Request(s)
                                            </MotifDropdownItem>
                                        </li>
                                        ||

                                        <li role="menuitem">
                                        {!externalUserVisibility && isChecklistSelected ?
                                        <MotifDropdownItem type="button"
                                        disabled={true} data-testid="approveClientReopenRequest">
                                        Approve Client Reopen Request(s)                                        
                                    </MotifDropdownItem>
                                       :  <MotifTooltip
                                       trigger={
                                           <MotifDropdownItem type="button"
                                               disabled={true} data-testid="approveClientReopenRequest">
                                               Approve Client Reopen Request(s)
                                               <MotifIconButton className="ml-2" aria-label="Approve Client Reopen Request(s)" type="button">
                                                   <MotifIcon iconFunction={IconoirInfoCircle} size='24' fill='none' />
                                               </MotifIconButton>
                                           </MotifDropdownItem>}
                                   >
                                               <ul style={{ margin: '0px' }}>{(approveDenyClientReopenRequestValidationMessage.map(msg => <li className="checklistActionToolTipContent">{msg}</li>))}</ul>
                               </MotifTooltip>}
                                </li>): <React.Fragment />
                                       
                                    }

                                {(isSystemOrEngagementAdmin || isEngagementUser)? (externalUserVisibility && approveDenyClientReopenRequestActionStatus &&                          
                                        <li role="menuitem">
                                            <MotifDropdownItem onClick={() => { setActionOptionsOpen(false); toggleShowDenyClientReopenRequestModal(); }} type="button"
                                                disabled={false} data-testid="denyClientReopenRequest"
                                                style={{
                                                    display: 'block',
                                                    width: '100%',
                                                    boxSizing: 'border-box',
                                                    padding: '10px',
                                                    cursor: 'pointer'
                                                  }}
                                            >
                                                Deny Client Reopen Request(s)
                                            </MotifDropdownItem>
                                        </li>
                                         ||
                                         <li role="menuitem">
                                         {!externalUserVisibility && isChecklistSelected ?
                                         <MotifDropdownItem type="button"
                                         disabled={true} data-testid="denyClientReopenRequest">
                                          Deny Client Reopen Request(s)                                        
                                     </MotifDropdownItem>
                                        :  <MotifTooltip
                                        trigger={
                                            <MotifDropdownItem type="button"
                                                disabled={true} data-testid="denyClientReopenRequest">
                                                Deny Client Reopen Request(s) 
                                                <MotifIconButton className="ml-2" aria-label="Deny Client Reopen Request(s)" type="button">
                                                    <MotifIcon iconFunction={IconoirInfoCircle} size='24' fill='none' />
                                                </MotifIconButton>
                                            </MotifDropdownItem>}
                                    >
                                                <ul style={{ margin: '0px' }}>{(approveDenyClientReopenRequestValidationMessage.map(msg => <li className="checklistActionToolTipContent">{msg}</li>))}</ul>
                                </MotifTooltip>}
                                 </li>): <React.Fragment />                                       
                                    }

                                    {resendChecklistActionStatus || resendChecklistTooltipMessage.length === 0  || !externalUserVisibility ? 
                                        <li role="menuitem">
                                            <MotifDropdownItem onClick={() => { setActionOptionsOpen(false); toggleShowResendChecklistModal(); }} type="button"
                                                disabled={!resendChecklistActionStatus} data-testid="resendChecklists"
                                                style={{
                                                    display: 'block',
                                                    width: '100%',
                                                    boxSizing: 'border-box',
                                                    padding: '10px',
                                                    cursor: 'pointer'
                                                  }}
                                            >
                                                Resend Checklist(s)
                                            </MotifDropdownItem>
                                        </li>
                                         :
                                            <li role="menuitem">
                                                <MotifTooltip
                                                    trigger={
                                                        <MotifDropdownItem onClick={() => { setActionOptionsOpen(false); toggleShowResendChecklistModal(); }} type="button"
                                                            disabled={!resendChecklistActionStatus} data-testid="resendChecklists">
                                                            Resend Checklist(s)
                                                            <MotifIconButton className="ml-2" aria-label="Tooltip" type="button">
                                                                <MotifIcon iconFunction={IconoirInfoCircle} size='24' fill='none' />
                                                            </MotifIconButton>
                                                        </MotifDropdownItem>}
                                                >
                                                    <ul style={{ margin: '0px' }}><li className="checklistActionToolTipContent">{resendChecklistTooltipMessage}</li></ul>
                                                </MotifTooltip>
                                            </li>
                                        }

                                    {isSystemOrEngagementAdmin ? (reopenforEYReviewChecklistActionStatus &&
                                        <li role="menuitem">
                                            <MotifDropdownItem onClick={() => { setActionOptionsOpen(false); toggleShowReopenForEYReviewChecklistModal(); }} type="button"
                                                data-testid="reopenforEYReviewChecklists"   
                                                style={{
                                                    display: 'block',
                                                    width: '100%',
                                                    boxSizing: 'border-box',
                                                    padding: '10px',
                                                    cursor: 'pointer'
                                                  }}
                                            >
                                                Reopen for EY Review
                                            </MotifDropdownItem>
                                         </li>
                                        ||
                                        <li role="menuitem">
                                            <MotifTooltip
                                                trigger={
                                                    <MotifDropdownItem onClick={() => { setActionOptionsOpen(false); toggleShowReopenForEYReviewChecklistModal(); }} type="button"
                                                        disabled={true} data-testid="reopenforEYReviewChecklists">
                                                        Reopen for EY Review
                                                        <MotifIconButton className="ml-2" aria-label="Reopen for EY Review" type="button">
                                                            <MotifIcon iconFunction={IconoirInfoCircle} size='24' fill='none' />
                                                        </MotifIconButton>
                                                    </MotifDropdownItem>}
                                            >
                                                <ul style={{ margin: '0px' }}>{(reopenforEYReviewChecklistValidationMessage.map(msg => <li className="checklistActionToolTipContent">{msg}</li>))}</ul>
                                        </MotifTooltip></li>) : <React.Fragment />
                                    }

                                    {(isSystemOrEngagementAdmin || isEngagementUser) ? (signoffChecklistActionStatus &&
                                        <li role="menuitem">
                                            <MotifDropdownItem onClick={() => { setActionOptionsOpen(false); toggleShowSignoffChecklistModal(); }} type="button"
                                                data-testid="signOffChecklists"  
                                                style={{
                                                    display: 'block',
                                                    width: '100%',
                                                    boxSizing: 'border-box',
                                                    padding: '10px',
                                                    cursor: 'pointer'
                                                  }}
                                             >
                                                Sign-Off Checklist(s)
                                            </MotifDropdownItem>
                                        </li>
                                        ||
                                        <li role="menuitem">
                                            <MotifTooltip
                                                trigger={
                                                    <MotifDropdownItem onClick={() => { setActionOptionsOpen(false); toggleShowSignoffChecklistModal(); }} type="button"
                                                        disabled={true} data-testid="signOffChecklists">
                                                        Sign-Off Checklist(s)
                                                        <MotifIconButton className="ml-2" aria-label="Sign-Off Checklists" type="button">
                                                            <MotifIcon iconFunction={IconoirInfoCircle} size='24' fill='none' />
                                                        </MotifIconButton>
                                                    </MotifDropdownItem>}
                                            >
                                                <ul style={{ margin: '0px' }}>{(signoffChecklistValidationMessage.map(msg => <li className="checklistActionToolTipContent">{msg}</li>))}</ul>
                                            </MotifTooltip> </li>) : <React.Fragment />
                                    }

                                    {exportChecklistSummaryActionStatus &&
                                        <li role="menuitem">
                                            <MotifDropdownItem onClick={() => { setActionOptionsOpen(false); handleExportChecklistSummary(); }} type="button"
                                                data-testid="exportChecklists" 
                                                style={{
                                                    display: 'block',
                                                    width: '100%',
                                                    boxSizing: 'border-box',
                                                    padding: '10px',
                                                    cursor: 'pointer'
                                                  }}
                                            >
                                                Export Checklist Summary
                                            </MotifDropdownItem>
                                        </li>
                                        ||
                                        <li role="menuitem">
                                            <MotifTooltip
                                                trigger={
                                                    <MotifDropdownItem onClick={() => { setActionOptionsOpen(false); handleExportChecklistSummary(); }} type="button"
                                                        disabled={true} data-testid="exportChecklists">
                                                        Export Checklist Summary
                                                        <MotifIconButton className="ml-2" aria-label="Export Checklist Summary" type="button">
                                                            <MotifIcon iconFunction={IconoirInfoCircle} size='24' fill='none' />
                                                        </MotifIconButton>
                                                    </MotifDropdownItem>}
                                            >
                                                <ul style={{ margin: '0px' }}>{(exportChecklistSummaryValidationMessage.map(msg => <li className="checklistActionToolTipContent">{msg}</li>))}</ul>
                                            </MotifTooltip>
                                        </li>
                                }
                                {downloadChecklistActionStatus &&
                                    <li role="menuitem">
                                        <MotifDropdownItem onClick={() => { setActionOptionsOpen(false); toggleShowDownloadChecklistsModal('WITH_ANSWERS'); }} type="button"
                                            data-testid="downloadChecklistWithAnswersOnly" 
                                            style={{
                                                display: 'block',
                                                width: '100%',
                                                boxSizing: 'border-box',
                                                padding: '10px',
                                                cursor: 'pointer'
                                              }}
                                        >
                                            Download - With Answers Only
                                        </MotifDropdownItem>
                                    </li>
                                    ||
                                    <li role="menuitem">
                                        <MotifTooltip
                                            trigger={
                                                <MotifDropdownItem onClick={() => { setActionOptionsOpen(false); toggleShowDownloadChecklistsModal('WITH_ANSWERS'); }} type="button"
                                                    disabled={true} data-testid="downloadChecklistWithAnswersOnly">
                                                    Download - With Answers Only
                                                    <MotifIconButton className="ml-2" aria-label="Download - With Answers Only" type="button">
                                                        <MotifIcon iconFunction={IconoirInfoCircle} size='24' fill='none' />
                                                    </MotifIconButton>
                                                </MotifDropdownItem>}
                                        >
                                            <ul style={{ margin: '0px' }}>{(downloadChecklistValidationMessage.map(msg => <li className="checklistActionToolTipContent">{msg}</li>))}</ul>
                                        </MotifTooltip>
                                    </li>
                                }
                                {downloadChecklistActionStatus &&
                                    <li role="menuitem">
                                        <MotifDropdownItem onClick={() => { setActionOptionsOpen(false); toggleShowDownloadChecklistsModal('WITH_ANSWERS_COMMENTS'); }} type="button"
                                            data-testid="downloadChecklistWithAnswersAndComments"  
                                            style={{
                                                display: 'block',
                                                width: '100%',
                                                boxSizing: 'border-box',
                                                padding: '10px',
                                                cursor: 'pointer'
                                              }}
                                        >
                                            Download - With Answers And Comments
                                        </MotifDropdownItem>
                                    </li>
                                    ||
                                    <li role="menuitem">                                        
                                        <MotifTooltip
                                            trigger={
                                                <MotifDropdownItem onClick={() => { setActionOptionsOpen(false); toggleShowDownloadChecklistsModal('WITH_ANSWERS_COMMENTS'); }} type="button"
                                                    disabled={true} data-testid="downloadChecklistWithAnswersAndComments">
                                                    Download - With Answers And Comments
                                                    <MotifIconButton className="ml-2" aria-label="Download - With Answers And Comments" type="button">
                                                        <MotifIcon iconFunction={IconoirInfoCircle} size='24' fill='none' />
                                                    </MotifIconButton>
                                                </MotifDropdownItem>}
                                        >
                                            <ul style={{ margin: '0px' }}>{(downloadChecklistValidationMessage.map(msg => <li className="checklistActionToolTipContent">{msg}</li>))}</ul>
                                        </MotifTooltip>
                                    </li>
                                }
                                {downloadChecklistActionStatus &&
                                    <li role="menuitem"> 
                                        <MotifDropdownItem onClick={() => { setActionOptionsOpen(false); toggleShowDownloadChecklistsModal('WITHOUT_ANSWERS'); }} type="button"
                                            data-testid="downloadChecklistWithoutAnswersorComments" 
                                            style={{
                                                display: 'block',
                                                width: '100%',
                                                boxSizing: 'border-box',
                                                padding: '10px',
                                                cursor: 'pointer'
                                              }}
                                        >
                                            Download - Without Answers or Comments
                                        </MotifDropdownItem>
                                     </li>
                                    ||
                                    <li role="menuitem">
                                        <MotifTooltip
                                            trigger={
                                                <MotifDropdownItem onClick={() => { setActionOptionsOpen(false); toggleShowDownloadChecklistsModal('WITHOUT_ANSWERS'); }} type="button"
                                                    disabled={true} data-testid="downloadChecklistWithoutAnswersorComments">
                                                    Download - Without Answers or Comments
                                                    <MotifIconButton className="ml-2" aria-label="Download - With Answers or Comments" type="button">
                                                        <MotifIcon iconFunction={IconoirInfoCircle} size='24' fill='none' />
                                                    </MotifIconButton>
                                                </MotifDropdownItem>}
                                        >
                                            <ul style={{ margin: '0px' }}>{(downloadChecklistValidationMessage.map(msg => <li className="checklistActionToolTipContent">{msg}</li>))}</ul>
                                        </MotifTooltip>
                                    </li>
                                }
                                </ul>
                                </MotifDropdown>
                            </div>
                        }
                    </div>
                </div>
            }
        </form>
    </div>);
};

ManageChecklistHeader.propTypes = {
    calendarYears: PropTypes.array,
    selectedClient: PropTypes.object,
    selectedCalendarYear: PropTypes.number,
    toggleShowDownloadChecklistsModal: PropTypes.bool,
    isEngagementUser: PropTypes.bool,
};

export default reduxForm({ form: "manageChecklist" })(ManageChecklistHeader);