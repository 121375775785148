import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getFormValues, getFormSyncErrors, getFormSubmitErrors, SubmissionError, reduxForm } from "redux-form";
import BulkReportManagementHeader from "../../components/bulkReportManagement/BulkReportManagementHeader";
import BulkReportManagementTable from "../../components/bulkReportManagement/BulkReportManagementTable";
import CreateAndRunReportPackageModal from "../../components/bulkReportManagement/CreateAndRunReportPackageModal";
import * as authActions from "../../actions/authActions";
import * as dateHelpers from "../../scripts/dateHelpers";
import * as reitActions from "../../actions/reitActions";
import * as periodActions from "../../actions/periodActions";
import * as customFdrActions from '../../actions/customFdrTemplateActions';
import * as reportExtractGroups from "../../constants/reportExtractGroups";
import * as reportActions from '../../actions/reportActions';
import * as bulkReportPackageActions from '../../actions/bulkReportPackageActions';
import * as Constants from '../../constants/other';
import DeleteModal from "../../components/shared/DeleteModal";
import { store }  from "../../store/configureStore";
import { reset } from "redux-form";
import * as actions from '../../actions/actionTypes';
import { withRouter } from "../../common/withRouter";
import * as securitiesSnapshotActions from "../../actions/securitiesSnapshotActions";
/**
 * BulkReportManagementContainer component
 * @extends {React.Component}
 */
export class BulkReportManagementContainer extends React.Component {
    /**
    * Creates a new BulkReportManagementContainer
    * @constructor
    * @param {Object} props The component properties
    * @param {Object} context The component context
    */
    constructor(props, context) {
        super(props, context);

        this.handleGridColumnStateChange = this.handleGridColumnStateChange.bind(this);
        this.handleGridChanges = this.handleGridChanges.bind(this);
        this.setGridColumnState = this.setGridColumnState.bind(this);
        this.handleDownload = this.handleDownload.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.toggleShowCreateAndRunReportPackageModal = this.toggleShowCreateAndRunReportPackageModal.bind(this);
        this.handleCreateAndRunPackage = this.handleCreateAndRunPackage.bind(this);
        this.handleShowGroupingofExtract = this.handleShowGroupingofExtract.bind(this);
        this.handleIncludeDiagnosticReportCheckboxChecked = this.handleIncludeDiagnosticReportCheckboxChecked.bind(this);
        this.handleAddLevelGroupBy = this.handleAddLevelGroupBy.bind(this);
        this.handleDeleteLevelGroupBy = this.handleDeleteLevelGroupBy.bind(this);
        this.handleGroupBySelection = this.handleGroupBySelection.bind(this);
        this.setItemSelection = this.setItemSelection.bind(this);
        this.setIncludePsqComments = this.setIncludePsqComments.bind(this);
        this.setIncludeArqcComments = this.setIncludeArqcComments.bind(this);
        this.handleTemplateSelect = this.handleTemplateSelect.bind(this);
        this.setInitialData = this.setInitialData.bind(this);
        this.handleFileSelection = this.handleFileSelection.bind(this);
        this.setFdrSelection = this.setFdrSelection.bind(this);
        this.handleConfirmDelete = this.handleConfirmDelete.bind(this);
        this.handleCancelDelete = this.handleCancelDelete.bind(this);
        this.handleAllSelection = this.handleAllSelection.bind(this);
        this.processETA = this.processETA.bind(this);
        this.populateHasIncompleteSecuritySnapshots = this.populateHasIncompleteSecuritySnapshots.bind(this);
        this.state = {
            gridColumnState: [],
            gridCurrentState: [],
            gridFilteredRowCurrentState: [],
            currentUserIsSysAdmin: this.props.currentUserIsSysAdmin,
            currentUserIsEngAdmin: this.props.currentUserIsEngAdmin,
            showCreateAndRunReportPackageModal: false,
            showGroupingofExtract: true,
            groupingofReportExtractList: [],
            includeDiagnosticReport: false,
            reits: [],
            periods: [],
            selectedTemplate: { templateId: -3, templateName: 'Assigned Defaults' },
            selectedItems: [],
            defaultFdrSelections: [],
            includePsqComments: false,
            includeArqcComments: false,
            selectedFilesForCreatePackage: [],
            groupByErrorMessage: '',
            fdrPdfSelection: false,
            fdrWordSelection: false,
            resetCheckboxes: false,
            showDeleteModal: false,
            bulkReportIdToBeDeleted: null,
            selectedPeriods: [],
            checkSelectedPeriod:[],
            selectedReits: [],
            checkSelectedReit:[],
            hasIncompleteSecuritySnapshotsList: []
        };
    }

    /**
    * Invoked immediately after mounting occurs
    * @returns {undefined}
    */
    componentDidMount() {

        if (this.props.clientId) {

            this.props.reitActions.fetchReits(this.props.clientId, true);
            this.props.periodActions.fetchReportPeriodsByClientId(this.props.clientId);
            this.props.customFdrActions.fetchCustomFdrTemplates(this.props.clientId);
            this.props.reportActions.fetchReportItemsForClient(this.props.clientId);
            this.isComponentMounted = true;
            this.props.bulkReportPackageActions.fetchBulkReportPackages(this.props.clientId).then(
                () => {
                    this.props.bulkReportPackageActions.fetchBulkReportPackagesETA(this.props.clientId).then(this.processETA);
                   
                }
            );
            this.populateHasIncompleteSecuritySnapshots(this.props.clientId,null,null);
        }

        this.setInitialData();
        this.setGridColumnState();
    }

    /**
    * Process ETA
    * Show ETA details for Bulk Report Packages having inprogress or queued status
    * @returns {undefined}
    */
    processETA() {
        if (!this.isComponentMounted) {
            return;
        }

        const data = this.props.bulkReportPackagesETA;

        clearInterval(this.intervalETA);

        // Prevent creating timer if there are no Bulk Report Packages having inprogress or queued status
        if (this.props.bulkReportPackages && this.props.bulkReportPackages.some(pkg => pkg.bulkReportStatusDescription.toLowerCase().includes('queued')
            || pkg.bulkReportStatusDescription.toLowerCase().includes('in progress'))) {

            clearTimeout(this.timeoutETA);
            this.timeoutETA = setTimeout(() => {
                this.props.bulkReportPackageActions.fetchBulkReportPackagesETA(this.props.clientId).then(this.processETA);
            }, 5000);
        }

        store.dispatch({
            type: actions.UPDATE_BULK_REPORT_PACKAGE_ETA,
            etas: data
        });

        this.intervalETA = setInterval(() => {

            // Get index of first Bulk Report Package having inprogress or queued status to decrement the counter(estimatedTimeRemainingSeconds)
            let firstIndex = 0;
            while (firstIndex < this.props.bulkReportPackagesETA.length && 
                this.props.bulkReportPackagesETA[firstIndex].estimatedTimeRemainingSeconds <= 0) {
                    firstIndex++;                
            }

            const updatedBulkReportPackagesETA = this.props.bulkReportPackagesETA.map((packageETA, index) => {                
                if (index == firstIndex) {
                    return {
                        ...packageETA,
                        estimatedTimeRemainingSeconds: packageETA.estimatedTimeRemainingSeconds == 0 ? 0 : packageETA.estimatedTimeRemainingSeconds - 1
                    }
                }
                
                return packageETA;
            });

            store.dispatch({
                type: actions.UPDATE_BULK_REPORT_PACKAGE_ETA,
                etas: updatedBulkReportPackagesETA
            });

            store.dispatch({
                type: actions.LOAD_BULK_REPORT_PACKAGES_ETA_SUCCESS,
                bulkReportPackagesETA: updatedBulkReportPackagesETA
            });

        }, 1000);
    }

    /**
    * Invoked immediately before unmounting occurs
    * @returns {undefined}
    */
    componentWillUnmount() {
        // On unmount, clear the calendar data from state to conserve memory
        // Calling this action with no argument will clear from redux-state

        this.setState({
            groupingofReportExtractList: [], reportPackages: [], reits: [], availableReportItems: [], selectedTemplate: [],
            selectedItems: [], periods: [], 
            selectedPeriods: [], checkSelectedPeriod: [], selectedReits: [], checkSelectedReit: [],
        });

        // Clear interval
        clearInterval(this.intervalETA);
        this.intervalETA = null;       

        // Clear timeout
        clearTimeout(this.timeoutETA);

        this.isComponentMounted = false;
    }

componentDidUpdate(prevProps) {
    if (this.props.clientId !== prevProps.clientId) {
        this.props.reitActions.fetchReits(this.props.clientId, true);
        this.props.periodActions.fetchReportPeriodsByClientId(this.props.clientId);
        this.props.customFdrActions.fetchCustomFdrTemplates(this.props.clientId);
    }
    
    if (this.props.toggleReloadBulkReportPackages != prevProps.toggleReloadBulkReportPackages) {
        this.props.bulkReportPackageActions.fetchBulkReportPackages(this.props.clientId).then(
            () => {
                this.props.bulkReportPackageActions.fetchBulkReportPackagesETA(this.props.clientId).then(this.processETA);
            }
        );
    }
    if (this.props.availableReportItems !== prevProps.availableReportItems
        && Array.isArray(this.props.availableReportItems)
        && this.props.availableReportItems.length > 0) {

        const selectedItems = JSON.parse(JSON.stringify(this.state.selectedItems));
        const defaultFdrSelections = JSON.parse(JSON.stringify(this.state.defaultFdrSelections));
        for (let i = 0; i < this.props.availableReportItems.length; i++) {
            if (this.props.availableReportItems[i].isRequired && selectedItems.find(x => x === this.props.availableReportItems[i].type) === undefined) {
                selectedItems.push(this.props.availableReportItems[i].type);
            }

            if (this.props.availableReportItems[i].isRequired) {
                defaultFdrSelections.push(this.props.availableReportItems[i].type);
            }
        }

        this.setState({ selectedItems: selectedItems, dataIsLoaded: true, defaultFdrSelections: defaultFdrSelections });
    }
}
    
    /**
     * Set initial data
    */
    setInitialData() {
        let groupingofReportExtracts = [...reportExtractGroups.groupOptions.filter(x => x.isDefault)];
        this.setState({ groupingofReportExtractList: groupingofReportExtracts });
    }

    /**
     * Handles Download button click of Grid Row
    */
    handleDownload(bulkReportPackageId, bulkReportPackageName, bulkReportPackageZipFileGUID) {
        this.props.bulkReportPackageActions
            .downloadBulkReportPackage(this.props.clientId, bulkReportPackageId, bulkReportPackageName, bulkReportPackageZipFileGUID);
    }

    /**
     * Handles Delete button click of Grid Row
    */
    handleDelete(bulkReportPackageId, bulkReportPackageName) {
        this.setState({ showDeleteModal: true, bulkReportIdToBeDeleted: bulkReportPackageId });
    }

    handleConfirmDelete() {
        const clientId = this.props.clientId;
        this.props.bulkReportPackageActions
            .deleteBulkReportPackage(
                clientId,
                this.state.bulkReportIdToBeDeleted,
            )
            .then((data) => {
                this.props.bulkReportPackageActions.fetchBulkReportPackages(this.props.clientId);
                this.setState({ showDeleteModal: false });
            })
            .catch((error) => {
                this.setState({ showDeleteModal: false });
            });
    }

    handleCancelDelete() {
        this.setState({ showDeleteModal: false, bulkReportIdToBeDeleted: null });
    }
    /**
     * Handle Grid changes
     * @returns {gridRowData}
    */
    handleGridChanges(gridRowData, gridRefApi) {
        let rowData = [];
        rowData =
            gridRowData &&
            gridRowData.length &&
            gridRowData.map((x) => {
                return {
                    bulkReportPackageID: x.bulkReportPackageID,
                    bulkReportPackageName: x.bulkReportPackageName,
                    bulkReportPackageStatus: x.bulkReportPackageStatus,
                    createdBy: x.createdBy,
                    createdDate: x.createdDate,
                };
            });

        this.setState({ gridCurrentState: rowData });

        if (gridRefApi) {
            gridRefApi.refreshCells();
        }
    }

    /**
    * Handle Grid column state chagne
    * @returns {undefined}
    */
    handleGridColumnStateChange(columnState) {
        this.setState({ gridColumnState: columnState });
    }

    /**
    * Set AG-Grid column state   
    * @returns {undefined}
    */
    setGridColumnState() {
        var columnState = localStorage.getItem('REITSuiteBulkReportPackageColumnState');
        if (columnState) {
            this.setState({ gridColumnState: JSON.parse(columnState) });
        }
        else {
            this.setState({ gridColumnState: [{ colId: '-1' }] });
        }
    }

    /**
    * Toggle Create and Run Report Package modal 
    * @returns {undefined}
    */
    toggleShowCreateAndRunReportPackageModal() {
        this.setState({
            showCreateAndRunReportPackageModal: !this.state.showCreateAndRunReportPackageModal, selectedFilesForCreatePackage: [], groupByErrorMessage: '',
            selectedItems: [], includeArqcComments: false, resetCheckboxes: !this.state.resetCheckboxes, fdrPdfSelection: false, fdrWordSelection: false,
            includePsqComments: false, selectedTemplate: { templateId: -3, templateName: 'Assigned Defaults' }, showGroupingofExtract: true,
            includeDiagnosticReport : false, periods: this.props.periods, selectedPeriods:[], checkSelectedPeriod:[], 
            reits: this.props.reits, selectedReits:[], checkSelectedReit:[],
        }, () => {
            if (!this.state.showAddClientAuthModal) {
                store.dispatch(reset('createAndRunReportPackage'));
            }
        });
        this.setInitialData();
    }

    handleFileSelection(fileType, value) {
        let selectedFiles = JSON.parse(JSON.stringify(this.state.selectedFilesForCreatePackage));
        const indexToRemove = selectedFiles.indexOf(fileType);
        if (value && indexToRemove == -1) {
            selectedFiles.push(fileType);
        }
        else if (!value && indexToRemove !== -1) {
            selectedFiles.splice(indexToRemove, 1);
        }
        this.setState({
            selectedFilesForCreatePackage: selectedFiles
        });
    }
    /**
    * Handle Create and Run Report Package
    * @returns {undefined}
    */
    handleCreateAndRunPackage() {
        let request = {
            clientID: this.props.clientId,
            bulkReportPackageName: this.props.createPackageValues.bulkReportPackageName,
            bulkReportPackageConfigData: {
                clientId: this.props.clientId,
                reportPeriodDescriptions: this.props.createPackageValues.reportPeriod,
                // reportPeriodDescriptions: this.state.selectedPeriods,
                reitIds: this.props.createPackageValues.reit,
                // reitIds: this.state.selectedReits,
                packageFileTypeIds: this.getAllSelectedFiles(),
                groupBy: this.getGroupByList(),
                fdrOptions: this.getFdrOptions(),
                includeDiagnosticReport: this.state.includeDiagnosticReport
            }
        };

        this.props.bulkReportPackageActions
            .createBulkReportPackage(request)
            .then(() => {
                this.props.bulkReportPackageActions.fetchBulkReportPackages(this.props.clientId).then(
                    () => {
                        this.props.bulkReportPackageActions.fetchBulkReportPackagesETA(this.props.clientId).then(this.processETA);

                    }
                );
                this.toggleShowCreateAndRunReportPackageModal();
            })
            .catch((error) => {
                this.toggleShowCreateAndRunReportPackageModal();

            });
    }

    //concatenate fdr template into selected files state and return complete set
    getAllSelectedFiles() {
        let selectedTemplateValues = [];
        switch (this.state.selectedTemplate.templateId) {
            case -3: //assigned default
                if (this.state.fdrPdfSelection) {
                    selectedTemplateValues.push(Constants.reportPackagesFileType.FinalDeliveryReportAssignedDefaultsPDF);

                }
                if (this.state.fdrWordSelection) {
                    selectedTemplateValues.push(Constants.reportPackagesFileType.FinalDeliveryReportAssignedDefaultsMSWord);

                }
                break;
            case -2: //standard 
                if (this.state.fdrPdfSelection) {
                    selectedTemplateValues.push(Constants.reportPackagesFileType.FinalDeliveryReportStandardTemplatePDF);

                }
                if (this.state.fdrWordSelection) {
                    selectedTemplateValues.push(Constants.reportPackagesFileType.FinalDeliveryReportStandardTemplateMSWord);

                }
                break;
            default: //custom 
                if (this.state.fdrPdfSelection) {
                    selectedTemplateValues.push(Constants.reportPackagesFileType.FinalDeliveryReportCustomTemplatePDF);

                }
                if (this.state.fdrWordSelection) {
                    selectedTemplateValues.push(Constants.reportPackagesFileType.FinalDeliveryReportCustomTemplateMSWord);

                }

        }

        return selectedTemplateValues.concat(this.state.selectedFilesForCreatePackage);

    }

   /**
   * Handle Include Diagnostic Report Checkbox Checked event
   * @returns {undefined}
   */
    handleIncludeDiagnosticReportCheckboxChecked(isChecked) {       
        this.setState({ includeDiagnosticReport: isChecked });      
    }

    /**
    * Handle Show Grouping of Extract toggle button status change
    * @returns {undefined}
    */
    handleShowGroupingofExtract() {
        if (this.state.showGroupingofExtract) {
            let groupingofReportExtracts = [...reportExtractGroups.groupOptions.filter(x => x.isDefault)];
            this.setState({ showGroupingofExtract: !this.state.showGroupingofExtract, groupingofReportExtractList: groupingofReportExtracts });
        }
        else {
            this.setState({ showGroupingofExtract: !this.state.showGroupingofExtract });
        }
    }

    /**
    * Handle Add level to Group by
    * @returns {undefined}
    */
    handleAddLevelGroupBy() {
        // Add Group By
        var grpofReportExtractList = JSON.parse(JSON.stringify(this.state.groupingofReportExtractList));
        grpofReportExtractList.push({
            groupingOfReportExtractDescription: ' ', groupingOfReportExtractID: -1
        });

        this.setState({ groupingofReportExtractList: JSON.parse(JSON.stringify(grpofReportExtractList)) });
        this.checkDuplicateInGroupBy(grpofReportExtractList);
    }

    /**
    * Handle Delete level from Group by
    * @returns {undefined}
    */
    handleDeleteLevelGroupBy(arrayIndex) {
        // Remove Group By
        var grpofReportExtractList = JSON.parse(JSON.stringify(this.state.groupingofReportExtractList));
        if (grpofReportExtractList && Array.isArray(grpofReportExtractList) && grpofReportExtractList.length > 0 && arrayIndex < grpofReportExtractList.length) {
            grpofReportExtractList.splice(arrayIndex, 1);
            this.setState({ groupingofReportExtractList: JSON.parse(JSON.stringify(grpofReportExtractList)) });
            this.checkDuplicateInGroupBy(grpofReportExtractList);
        }
    }

    checkDuplicateInGroupBy(groupByDetail) {
        // Initialize index of first
        // repeating element
        let min = -1;
        // Creates an empty hashset
        let set = new Set();
        let repeatedElement = "";

        groupByDetail.forEach(element => {
            if (element.groupingOfReportExtractID > 0) {
                // If element is already in
                // hash set, update min
                if (set.has(element.groupingOfReportExtractID)) {
                    min = element.groupingOfReportExtractID;
                    repeatedElement = element.groupingOfReportExtractID;
                    return;
                }

                // Else add element to hash set
                else
                    set.add(element.groupingOfReportExtractID);
            }
        });

        if (min != -1)
            this.setState({ groupByErrorMessage: Constants.reportPackagesGroupByValues[repeatedElement] + ' value is being used more than once on group by. Please delete the duplicate group by criteria and try again.' });
        else
            this.setState({ groupByErrorMessage: '' });

    }
    /**
    * Handle Group by selection
    * @returns {undefined}
    */
    handleGroupBySelection(selectedValue, arrayIndex) {
        var grpofReportExtractList = JSON.parse(JSON.stringify(this.state.groupingofReportExtractList));
        if (grpofReportExtractList && Array.isArray(grpofReportExtractList) && grpofReportExtractList.length > 0 && arrayIndex < grpofReportExtractList.length) {
            grpofReportExtractList[arrayIndex].groupingOfReportExtractID = parseInt(selectedValue);

        }

        this.setState({ groupingofReportExtractList: JSON.parse(JSON.stringify(grpofReportExtractList)) });
        this.checkDuplicateInGroupBy(grpofReportExtractList);
    }

    /**
    * Set Item selection
    * @returns {undefined}
    */
    setItemSelection(itemType, selected) {
        const currentSelectedItems = this.state.selectedItems;

        if (selected) {
            // Use a set to prevent duplicates.
            const newItemSet = new Set([...currentSelectedItems, itemType]);
            this.setState({ selectedItems: [...newItemSet] });
        } else {
            const selectedItems =
                currentSelectedItems.filter(_type => _type !== itemType);

            this.setState({ selectedItems });
        }
    }

    /**
    * Set Include PSQ Comments
    * @returns {undefined}
    */
    setIncludePsqComments(includePsqComments) {
        this.setState({ includePsqComments });
    }

    /**
    * Set Include ARQC Comments
    * @returns {undefined}
    */
    setIncludeArqcComments(includeArqcComments) {
        this.setState({ includeArqcComments });
    }

    /**
    * Handle Template select
    * @returns {undefined}
    */
    handleTemplateSelect(newValue) {
        let selectedItems = [];
        if (newValue.value === -2 || newValue.value == -3) {
            selectedItems = [...this.state.defaultFdrSelections];
        }

        this.setState({
            selectedTemplate: {
                templateId: newValue.value,
                templateName: newValue.label
            },
            selectedItems,
            includeArqcComments: false,
            includePsqComments: false
        });
    }

    getGroupByList() {
        let groupByList = [];
        if (this.state.showGroupingofExtract) {
            this.state.groupingofReportExtractList.forEach(element => {
                if (element.groupingOfReportExtractID > 0) {
                    groupByList.push(Constants.reportPackagesGroupBy[element.groupingOfReportExtractID]);
                }

            });
        }
        return groupByList;
    }

    getFdrOptions() {
        let finalFdrOptionsList = {};
        let isFdrTemplateSelected = false;

        if (this.state.fdrPdfSelection || this.state.fdrWordSelection) {
            isFdrTemplateSelected = true;
        }

        const mergeWithDefaultSelections = [this.state.selectedItems, this.state.defaultFdrSelections];

        finalFdrOptionsList["reportItems"] = isFdrTemplateSelected ? (this.state.selectedTemplate.templateId > 0 ? this.state.selectedItems : this.mergeDeDupArrays(mergeWithDefaultSelections)) : [];
        finalFdrOptionsList["includePsqComments"] = isFdrTemplateSelected ? this.state.includePsqComments : false;
        finalFdrOptionsList["includeArqcComments"] = isFdrTemplateSelected ? this.state.includeArqcComments : false;
        finalFdrOptionsList["fdrTemplateId"] = isFdrTemplateSelected && this.state.selectedTemplate.templateId > 0 ? this.state.selectedTemplate.templateId : null;
        finalFdrOptionsList["excludedPsqs"] = [];
        return finalFdrOptionsList;
    }

    mergeDeDupArrays(arr) {
        return [...new Set([].concat(...arr))];
    }


    setFdrSelection(fileType, value) {
        if (fileType == "word") {
            this.setState({
                fdrWordSelection: value
            });
        }
        else if (fileType == "pdf") {
            this.setState({
                fdrPdfSelection: value
            });
        }

    }

    handleAllSelection(event, allOptions, fieldName = ""){
        if(event.target !== undefined && event.target !== null && 
          (event.target?.textContent !== "Select one or more" && event.target.parentNode.innerText !== "Select one or more") && // when the cursor hit the dropdown initially, it is returning the placeholder
          (event.target?.className?.baseVal !== "motif-select-input-arrow" && event.target.className !=="motif-select-input-text" && event.target.className !=="motif-select-input") && // prevent changing the value when the cursor hit the arrow icon or the middle space in the dropdown
            (event.target.className === "motif-select-all" || event.target?.ariaSelected !== null || // check if selected is Select All/ Deselect All or individual selection
             allOptions.map(opt => (opt.key).toString()).includes(event.target.textContent) || // checking of key as sometimes the event.target.textContent capture the key instead of the text
             event.currentTarget.innerText.split('\n').includes("Deselect All") || event.currentTarget.innerText.split('\n').includes("Select All") || // when the selected is checkbox Select/Deselect all, event.target.textContent is empty
             event.target.parentNode.innerText !== "")) // when the selected is checkbox, event.target.textContent is empty
        {
            let selectedInnerValues = event.currentTarget.innerText.split(','); // innerText is a string that combine of all selected items. The separator for current selected item is ','

            // usually the event.target.textContent is empty when the cursor hit the checkbox
            let selectedValue = (event.target.textContent !== "" && event.target.textContent !== "-") ? event.target.textContent : event.target.parentNode.innerText;
            selectedValue = (selectedValue !== "" && selectedValue !== undefined && 
                                allOptions.filter(v => v.text.replace(/\s/g,'') === selectedValue.replace(/\s/g,''))?.length > 0 || 
                                selectedValue === "Select All") ? selectedValue : event.target.parentNode.innerText;
            selectedValue = (selectedValue !== "" && selectedValue !== undefined && !event.currentTarget.innerText?.startsWith("Deselect All")) ? selectedValue : 
                            selectedInnerValues.length > 1 ? selectedInnerValues[0] : "Deselect All";

            let newSelectionOptions = [];
            let isSelectAllIndeterminate = event.target.firstChild?.className === "motif-select-checkbox motif-select-checkbox-indeterminate" ||
                                             event.target.className?.baseVal === "motif-select-checkbox-icon-indeterminate";
            let isCheckboxDeselect = event.target?.parentNode?.className === "motif-select-checkbox motif-select-checkbox-selected motif-select-checkbox-indeterminate";
            let isSelectClicked = (selectedValue === "Select All" || selectedValue.startsWith("Select All") && !isSelectAllIndeterminate) 
            let isDeselectClicked = (selectedValue === "Deselect All" || selectedValue.startsWith("Deselect All")) || isCheckboxDeselect || isSelectAllIndeterminate;

            // set the value of isSelected property
            allOptions.forEach(period => {
                let periodText = period.text.replace(/\s/g,'');
                let selectedValueNoSpaces = selectedValue.replace(/\s/g,'');

                // checking for Select All and individual select
                if((isSelectClicked || (period.isSelected && selectedValueNoSpaces !== periodText) || 
                    (!period.isSelected && selectedValue === period.text)) && !isDeselectClicked){
                    period.isSelected = true;
                }
                // checking for Select All and individual select
                else if(isDeselectClicked || (selectedValueNoSpaces === periodText && period.isSelected)){
                    period.isSelected = false;
                }
                else{
                    period.isSelected = false;
                }
                
                newSelectionOptions.push(period);
            });

            if(fieldName === "period"){
                this.setState({checkSelectedPeriod : newSelectionOptions});
                if(isDeselectClicked){
                    this.setState({ selectedPeriods: []});
                } else{
                    this.setState({ selectedPeriods: allOptions.filter(option => option.isSelected).map(opt => opt.value) });
                }
            } else if(fieldName === "reit"){
                this.setState({checkSelectedReit : newSelectionOptions});
                if(isDeselectClicked){
                    this.setState({ selectedReits: []});
                } else{
                    this.setState({ selectedReits: allOptions.filter(option => option.isSelected).map(opt => opt.value) });
                }
            }
        }
    }

    populateHasIncompleteSecuritySnapshots(clientId,reitId,periodId) {
        this.props.securitiesSnapshotActions.getIncompleteSecuritySnapshotDetails(clientId,reitId,periodId).then((data) => {
            this.setState({ hasIncompleteSecuritySnapshotsList: data });
        });
    }

    /**
     * Render a React element
     * @returns {Object} A reference to the component
     */
    render() {
        return (
            <React.Fragment>
                <h2>Bulk Report Management</h2>
                <BulkReportManagementHeader
                    currentUserIsSysAdmin={authActions.isSystemAdministrator(this.props.currentUserAuthorizations)}
                    currentUserIsEngAdmin={authActions.isEngagementAdministrator(this.props.currentUserAuthorizations, this.props.clientId)}
                    currentUserIsEngagementUser={authActions.isUser(this.props.currentUserAuthorizations, this.props.clientId)}
                    toggleShowCreateAndRunReportPackageModal={
                        this.toggleShowCreateAndRunReportPackageModal
                    }
                    theme={this.props.theme}
                />
                
                {(this.props.bulkReportPackages == null || this.props.bulkReportPackages.length === 0) &&
                    <div>No bulk report packages found for this client.</div>}

                <BulkReportManagementTable
                    formSyncErrors={this.props.formSyncErrors}
                    clientId={this.props.clientId}
                    bulkReportPackages={this.props.bulkReportPackages}
                    gridColumnState={this.state.gridColumnState}
                    handleGridColumnStateChange={this.handleGridColumnStateChange}
                    handleGridChanges={this.handleGridChanges}
                    handleDownload={this.handleDownload}
                    handleDelete={this.handleDelete}
                    currentUser={this.props.authentication.currentUser}
                    currentUserIsSysAdmin={authActions.isSystemAdministrator(this.props.currentUserAuthorizations)}
                    currentUserIsEngAdmin={authActions.isEngagementAdministrator(this.props.currentUserAuthorizations, this.props.clientId)}
                    currentUserIsEngagementUser={authActions.isUser(this.props.currentUserAuthorizations, this.props.clientId)}
                    theme={this.props.theme}
                />

                <CreateAndRunReportPackageModal
                    showCreateAndRunReportPackageModal={
                        this.state.showCreateAndRunReportPackageModal
                    }
                    onSubmit={this.handleCreateAndRunPackage}
                    handleCreateAndRunPackage={this.handleCreateAndRunPackage}
                    handleFileSelection={this.handleFileSelection}
                    toggleShowCreateAndRunReportPackageModal={
                        this.toggleShowCreateAndRunReportPackageModal
                    }
                    includeDiagnosticReport={this.state.includeDiagnosticReport}
                    handleIncludeDiagnosticReportCheckboxChecked={this.handleIncludeDiagnosticReportCheckboxChecked}
                    handleShowGroupingofExtract={this.handleShowGroupingofExtract}
                    handleAddLevelGroupBy={this.handleAddLevelGroupBy}
                    handleDeleteLevelGroupBy={this.handleDeleteLevelGroupBy}
                    handleGroupBySelection={this.handleGroupBySelection}
                    setItemSelection={this.setItemSelection}
                    setIncludePsqComments={this.setIncludePsqComments}
                    setIncludeArqcComments={this.setIncludeArqcComments}
                    handleTemplateSelect={this.handleTemplateSelect}
                    reportPeriods={this.props.periods}
                    reits={this.props.reits}
                    customFdrTemplates={this.props.customFdrTemplate}
                    availableReportItems={this.props.availableReportItems}
                    showGroupingofExtract={
                        this.state.showGroupingofExtract
                    }
                    groupingofReportExtractList={
                        this.state.groupingofReportExtractList
                    }
                    selectedTemplate={
                        this.state.selectedTemplate
                    }
                    selectedItems={
                        this.state.selectedItems
                    }
                    includePsqComments={
                        this.state.includePsqComments
                    }
                    includeArqcComments={
                        this.state.includeArqcComments
                    }
                    invalid={this.props.invalid}
                    submitting={this.props.submitting}
                    groupByErrorMessage={this.state.groupByErrorMessage}
                    setFdrSelection={this.setFdrSelection}
                    resetCheckboxes={this.state.resetCheckboxes}
                    isAuditOnlyClient={this.props.clientIsAudit}
                    handleAllSelection={this.handleAllSelection}
                    checkedPeriods={this.state.checkSelectedPeriod}
                    checkedReits={this.state.checkSelectedReit}
                    hasIncompleteSecuritySnapshotsList={this.state.hasIncompleteSecuritySnapshotsList}
                />

                <DeleteModal
                    showDeleteModal={this.state.showDeleteModal}
                    confirmationMessage="Are you sure you want to delete this report package?"
                    size="md"
                    handleDelete={this.handleConfirmDelete}
                    toggleshowDeleteModal={this.handleCancelDelete}
                    closeTitle="Close report package delete modal"
                    headerTitle="Delete Report Package">
                </DeleteModal>
            </React.Fragment>
        );
    }
}

BulkReportManagementContainer.propTypes = {
    clientId: PropTypes.number,
    currentUserAuthorizations: PropTypes.array,
    currentUserIsSysAdmin: PropTypes.bool.isRequired,
    currentUserIsEngAdmin: PropTypes.bool.isRequired,
    currentUserIsEngagementUser: PropTypes.bool.isRequired,
    initialize: PropTypes.func,
    showCreateAndRunReportPackageModal: PropTypes.bool,
    includePsqComments: PropTypes.bool,
    includeArqcComments: PropTypes.bool,
    showGroupingofExtract: PropTypes.bool,
    reportExtractGroupByList: PropTypes.array,
    reits: PropTypes.array,
    periods: PropTypes.array,
    availableReportItems: PropTypes.array,
    selectedTemplate: PropTypes.object,
    selectedItems: PropTypes.object,
    toggleShowCreateAndRunReportPackageModal: PropTypes.func,
    handleAddLevelGroupBy: PropTypes.func,
    handleDeleteLevelGroupBy: PropTypes.func,
    handleGroupBySelection: PropTypes.func,
    handleCreateAndRunPackage: PropTypes.func,
    handleShowGroupingofExtract: PropTypes.func,
    setItemSelection: PropTypes.func,
    setIncludePsqComments: PropTypes.func,
    setIncludeArqcComments: PropTypes.func,
    handleTemplateSelect: PropTypes.func,
    reitActions: PropTypes.object,
    periodActions: PropTypes.object,
    reportActions: PropTypes.object,
    bulkReportPackageActions: PropTypes.object,
    initialValues: PropTypes.object,
    handleAllSelection: PropTypes.func,
    selectedPeriods: PropTypes.array,
    checkSelectedPeriod: PropTypes.array,
    selectedReits: PropTypes.array,
    checkSelectedReit: PropTypes.array,
    theme: PropTypes.string,
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    let cid = Number.parseInt(ownProps.router.params.clientId);
    if (isNaN(cid)) {
        cid = 0;
    }
    return {
        clientId: cid,
        clientIsAudit: state.client && state.client.isAudit,
        currentUserAuthorizations: state.currentUserAuthorizations,
        currentUserIsSysAdmin: Array.isArray(state.currentUserAuthorizations) && (authActions.isSystemAdministrator(state.currentUserAuthorizations)),
        currentUserIsEngAdmin: Array.isArray(state.currentUserAuthorizations) && (authActions.isEngagementAdministrator(state.currentUserAuthorizations, cid)),
        authentication: state.authentication,
        reits: state.reits,
        periods: state.periods,
        customFdrTemplate: state.customFdrTemplate,
        availableReportItems: state.availableReportItems,
        bulkReportPackages: state.bulkReportPackages.bulkReportPackages,
        toggleReloadBulkReportPackages: state.bulkReportPackages.toggleReloadBulkReportPackages,
        bulkReportPackagesETA: state.bulkReportPackagesETA,
        theme: state.themeServices,
        initialValues: {
        },
        createPackageValues: getFormValues('createAndRunReportPackage')(state),
    };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        reitActions: bindActionCreators(reitActions, dispatch),
        periodActions: bindActionCreators(periodActions, dispatch),
        customFdrActions: bindActionCreators(customFdrActions, dispatch),
        reportActions: bindActionCreators(reportActions, dispatch),
        bulkReportPackageActions: bindActionCreators(bulkReportPackageActions, dispatch),
        securitiesSnapshotActions: bindActionCreators(securitiesSnapshotActions, dispatch),
    };
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        reduxForm({
            form: "bulkReportManagementForm",
            enableReinitialize: true
        })(BulkReportManagementContainer)
    )
);
