import React from 'react';
import PropTypes from 'prop-types';
import FormattedCurrency from "../shared/FormattedCurrency";
import { Icon } from 'semantic-ui-react';
import { MotifButton, MotifIconButton, MotifIcon } from '@ey-xd/motif-react';
import { IconoirEditPencil, IconoirTrash } from '@ey-xd/motif-icon';

const TrialBalanceAdjustmentRow = ({ item, ownership, selectedItemId, itemAdjustmentId, doShowAdjustmentFormEdit, doShowAdjustmentFormDelete, disableButtons }) => {
    let account = `${item.toTrialBalanceItemAccountCode} - ${item.toTrialBalanceItemAccountDescription}`;
    let eyAccount = `${item.toTrialBalanceItemInternalAccountCode} : ${item.toTrialBalanceItemInternalAccountDescription}` +
    (item.toSubAttribute > 0 ? `\nSub Attribute - ${item.toSubAttribute}` : '');
   
    let bal = item.toTrialBalanceItemBalance;
    let adj = item.adjustment;
    let adjBal = item.toTrialBalanceItemBalance * 1 + item.adjustment * 1;
    let pctAdjBal = (item.toTrialBalanceItemBalance * 1 + item.adjustment * 1) * ownership / 100;

    if (item.toTrialBalanceItemId === selectedItemId){       
        account = `${item.fromTrialBalanceItemAccountCode} - ${item.fromTrialBalanceItemAccountDescription}`;
        eyAccount = `${item.fromTrialBalanceItemInternalAccountCode} : ${item.fromTrialBalanceItemInternalAccountDescription}` +
       (item.fromSubAttribute > 0 ? `\nSub Attribute - ${item.fromSubAttribute}` : '');
        bal = item.fromTrialBalanceItemBalance;
        adj = -item.adjustment;
        adjBal = item.fromTrialBalanceItemBalance * 1 - item.adjustment * 1;
        pctAdjBal = (item.fromTrialBalanceItemBalance* 1 - item.adjustment * 1) * ownership / 100;
    }

    let bgcolor = '';
    if(item.id == itemAdjustmentId){
        bgcolor = 'selected';
    }

    return (
        <tr className= {bgcolor}>            
            <td key={item.id}><b>{account}</b></td>
            <td><b className="ey-account">{eyAccount}</b></td>           
            <td style={{ whiteSpace: 'nowrap', textAlign: 'right'}}><FormattedCurrency value={bal} currency="USD" minimumFractionDigits={0} maximumFractionDigits={0} /></td>
            <td style={{ whiteSpace: 'nowrap', textAlign: 'right'}}><FormattedCurrency value={adj} currency="USD" minimumFractionDigits={0} maximumFractionDigits={0} /></td>
            <td style={{ whiteSpace: 'nowrap', textAlign: 'right'}}><FormattedCurrency value={adjBal} currency="USD" minimumFractionDigits={0} maximumFractionDigits={0} /></td>
            <td style={{ whiteSpace: 'nowrap', textAlign: 'right'}}><FormattedCurrency value={pctAdjBal} currency="USD" minimumFractionDigits={0} maximumFractionDigits={0} /></td>
            <td style={{ whiteSpace: 'nowrap', textAlign: 'right' }}><span>
                <div>
                    <MotifIconButton aria-label="Edit this Adjustment" type="button"
                        onClick={() => doShowAdjustmentFormEdit(item.id)}
                        disabled={disableButtons} >
                        <MotifIcon iconFunction={IconoirEditPencil} size='24' fill='none' title="Edit this Adjustment" />
                    </MotifIconButton>
                </div></span><span>
                    <div>                       
                        <MotifIconButton aria-label="Delete this Adjustment" type="button"
                            onClick={() => doShowAdjustmentFormDelete(item.id)}
                            disabled={disableButtons} >
                            <MotifIcon iconFunction={IconoirTrash} size='24' fill='none' title="Delete this Adjustment" />
                        </MotifIconButton>
                    </div></span></td>
        </tr>
    );  
     
};

TrialBalanceAdjustmentRow.propTypes = {
    item: PropTypes.object.isRequired,
    ownership: PropTypes.number.isRequired,
    selectedItemId: PropTypes.number.isRequired,
    itemAdjustmentId: PropTypes.number,
    doShowAdjustmentFormDelete: PropTypes.func,
    doShowAdjustmentFormEdit: PropTypes.func,
    disableButtons: PropTypes.bool
};

export default TrialBalanceAdjustmentRow;