import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as checklistActions from "../../actions/checklistActions";
import * as commonActions from "../../actions/commonActions";
import * as distributionActions from "../../actions/distributionsTestActions";
import * as periodActions from "../../actions/periodActions";
import * as serviceActions from "../../actions/servicesActions";
import * as trsActions from "../../actions/trsActions";
import * as fileActions from '../../actions/fileActions';
import * as reportActions from '../../actions/reportActions';
import ChecklistNavigation from "../../components/reits/ChecklistNavigation";
import ChecklistCounters from "../../components/reits/ChecklistCounters";
import ChecklistReadyModal from "../../components/reits/ChecklistReadyModal";
import ArqcCreateChecklistModal from "../../components/reits/ArqcCreateChecklistModal";
import ChecklistPageBase from "./ChecklistPageBase";
import ChecklistSendModal from "../../components/reits/ChecklistSendModal";
import ArqcChecklistDetailsForm from "../../components/reits/ArqcChecklistDetailsForm";
import { MotifIcon, MotifButton, MotifDropdown, MotifDropdownItem, MotifToggleSwitch } from '@ey-xd/motif-react';
import { IconoirNavArrowDown } from '@ey-xd/motif-icon';
import * as reitActions from "../../actions/reitActions";
import * as clientActions from "../../actions/clientActions";
import * as customChecklistTemplateActions from '../../actions/customChecklistTemplateActions';
import DeleteChecklistDetailGridRowModal from '../../components/reits/DeleteChecklistDetailGridRowModal';
import ShowCommentsTable from "../../components/customChecklistTemplate/ShowCommentsTable";
import ShowAttachmentTable from '../../components/customChecklistTemplate/ShowAttachmentTable';
import ShowDiscrepanciesTable from '../../components/customChecklistTemplate/ShowDiscrepanciesTable';
import * as  checklistCountRequest from '../../constants/checklistNavigationTabs';
import * as serviceConstants from '../../constants/services';
import { withRouter } from "../../common/withRouter";
import * as actionHelpers from '../../scripts/actionHelpers';
import { WebSocketContext } from '../../components/PermExternalApp';

/**
 * ExternalArqcChecklistPage container component
 * @extends {React.Component}
 */
export class ExternalArqcChecklistPage extends ChecklistPageBase {
  static contextType = WebSocketContext;

  /**
    * Creates a new ExternalArqcChecklistPage
    * @constructor
    * @param {Object} props The component properties
    * @param {Object} context The component context
    */
  constructor(props, context) {
    super(props, context);
    this.prevWS = null;

    this.setActiveItem = this.setActiveItem.bind(this);
    this.setChecklistState = this.setChecklistState.bind(this);
    this.handleAddArqcExhibitBRow = this.handleAddArqcExhibitBRow.bind(this);
    this.handleRemoveArqcExhibitBRow = this.handleRemoveArqcExhibitBRow.bind(this);
    this.handleArqcExhibitBItemAnswered = this.handleArqcExhibitBItemAnswered.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleExternalConfirmationAction = this.handleExternalConfirmationAction.bind(this);
    this.handleSaveExternalArqcChecklist = this.handleSaveExternalArqcChecklist.bind(this);
    this.populateMissingTaxableReitSubsidiaries = this.populateMissingTaxableReitSubsidiaries.bind(this);
    this.downloadChecklist = this.downloadChecklist.bind(this);

    this.state.showDetails = true;
    this.state.isSubmittedForEyReview = false;
    this.state.tbMode = 1;
    this.state.canEdit = true;
    this.state.period = null;
    this.state.checklistDropdownOpen = false;
    this.state.showAllQuestions = true;
    this.state.showUnansweredQuestions = false;
    this.state.selectedAttachmentId = null;
    this.state.selectedCommentId = null;
    this.state.showDeleteChecklistGridRowModal = false;
    this.state.gridColumnState = [];
    this.handleGridColumnStateChange = this.handleGridColumnStateChange.bind(this);
    this.handleGridChanges = this.handleGridChanges.bind(this);
    this.state.questionComments = [];
    this.state.attachmentDetails = [];
    this.state.discrepanciesDetail = [];
    this.state.deleteFor = null;
    this.handleGridRowDeleteById = this.handleGridRowDeleteById.bind(this);
    this.handleDeleteAttachment =
      this.handleDeleteAttachment.bind(this);
    this.toggleShowChecklistGridRowDelete = this.toggleShowChecklistGridRowDelete.bind(this);
    this.deleteAttachment = this.deleteAttachment.bind(this);
    this.deleteComment = this.deleteComment.bind(this);
    this.checkReportPeriodVisibilityConstraint = this.checkReportPeriodVisibilityConstraint.bind(this);
    this.handleSaveCommentExternal = this.handleSaveCommentExternal.bind(this);
    this.state.currentError = null;
  }

  /**
   * Invoked immediately after mounting occurs
   * @returns {undefined}
   */
  componentDidMount() {
    this.props.commonActions.clearErrorResponse();

    if (!this.props.isExternalPermUser) {
      this.props.checklistActions.fetchExternalArqcChecklistsById(this.props.checklistId);
    }
    else {
      this.props.checklistActions.fetchExternalPermArqcChecklists(this.props.internalChecklistId).then((response) => {
        //  Redirect unauthorized user to Access Denied Error page
        if (response && response.errorResponse && response.errorResponse.errorCode && response.errorResponse.errorCode.toLowerCase() == 'unauthorized') {
          this.props.router.navigate(`/ext/unauthorized`);
        }
      });
    }
  }

  componentDidUpdate(prevProps) {

    const ws = this.context;

    if(ws && this.prevWS !== ws){
        this.prevWS = ws;
        ws.on('userAuthorizationUpdate', (message) => {
          // If the user is external permanent user and the user is deleted for same client of the current checklist, reload the page
          if(message && message.ClientID && this.props && this.props.client && this.props.client.id && message.ClientID == this.props.client.id
              && message.ActionName && message.ActionName == 'delete' 
              && this.props.isExternalPermUser){
              window.location.reload();
          }
        });
    }

    if (this.props.arqcActiveChecklist !== prevProps.arqcActiveChecklist || this.props.client !== prevProps.client) {
      if (this.props.arqcActiveChecklist && this.props.client && this.props.client.id !== undefined) {
        this.getChecklistCount(this.props.client.id, this.props.arqcActiveChecklist.checklistID);
      }
    }

    if (this.props.arqcActiveChecklist !== prevProps.arqcActiveChecklist) {
      const checklist = this.props.arqcActiveChecklist;

      if (checklist && checklist.reportPeriodID) {
        if (!prevProps.isExternalPermUser) {
          this.props.reitActions.fetchExternalReitLookupTypes(prevProps.checklistId, serviceConstants.ARQC_SERVICE);
          this.props.serviceActions.fetchExternalServicesByPeriod(checklist.reportPeriodID, prevProps.checklistId, serviceConstants.ARQC_SERVICE);
          this.props.trsActions.fetchExternalTrsByPeriod(checklist.reportPeriodID, prevProps.checklistId, serviceConstants.ARQC_SERVICE);

          // Get the period (so we know the status), to know if we can add comments
          this.props.periodActions.fetchExternalPeriodById(checklist.reportPeriodID, this.props.checklistId, this.props.arqcActiveChecklist.checklistTypeID);

          // Load REIT & Client for external user
          reitActions.fetchReitByPeriodId(checklist.reportPeriodID, prevProps.checklistId, serviceConstants.ARQC_SERVICE).then((reit) => {
            this.setState({ reit: reit });
            if (reit && reit.clients && reit.clients.length > 0) {
              this.props.clientActions.loadExternalClient(reit.clients[0].id, prevProps.checklistId, serviceConstants.ARQC_SERVICE);
            }
          });
        }
        else {
          this.props.reitActions.fetchReitLookupTypes();
          this.props.serviceActions.fetchServicesByPeriod(checklist.reportPeriodID);
          this.props.trsActions.fetchTrsByPeriod(checklist.reportPeriodID);
          periodActions.fastFetchPeriodById(checklist.reportPeriodID).then((period) => {
            if (period && period.trialBalanceModeID === 2) {
              this.setState({ tbMode: 2 });
            }
            this.setCanEdit(period);
            this.setState({ period: period });
          });

          reitActions.fetchReitByPeriodId(checklist.reportPeriodID).then((reit) => {
            this.setState({ reit: reit });
            if (reit && reit.clients && reit.clients.length > 0) {
              this.props.clientActions.loadClient(reit.clients[0].id);
            }
          });
        }
      }

      this.setState({ checklist: checklist, initialValues: this.getArqcDetailsInitialValues(checklist, true) }, this.populateMissingTaxableReitSubsidiaries);
      if (!prevProps.period && this.props.period) {
        this.setCanEdit(this.props.period);
      }

      // set disabled sections
      if (this.props.arqcActiveChecklist.content !== undefined
        && this.props.arqcActiveChecklist.content.length > 0
        && this.props.services !== undefined && this.props.services !== null) {
        this.disableSections(checklist);

        if (this.state.activeItem == null) {
          for (let i = 0; i < this.props.arqcActiveChecklist.content.length; i++) {
            if (this.state.disabledSections.find(x => x === this.props.arqcActiveChecklist.content[i].id) === undefined) {
              this.setState({ activeItem: this.props.arqcActiveChecklist.content[0] });
              break;
            }
          }
        }
      }
    }

    if (this.props.taxableReitSubsidiaries !== prevProps.taxableReitSubsidiaries) {
      this.setState({ taxableReitSubsidiaries: this.props.taxableReitSubsidiaries }, this.populateMissingTaxableReitSubsidiaries);
    }

    // Populate previous answers on active checklist
    if (this.props.previousArqcChecklist !== prevProps.previousArqcChecklist && prevProps.arqcActiveChecklist) {
      this.props.checklistActions.setActiveChecklist(prevProps.arqcActiveChecklist, this.props.previousArqcChecklist);
    }

    // Refresh attachment details table post addition/deletion of attachments
    if (prevProps.attachmentDetails !== this.props.attachmentDetails) {
      if (this.props.arqcActiveChecklist && this.props.arqcActiveChecklist !== null && this.props.arqcActiveChecklist.content.length > 0) {
        const updatedAttachmentDetails = this.props.attachmentDetails.map((attachment) => {
          const updatedQuestionText = this.getUpdatedQuestionTitleBasedOnChecklistSectionQuestionId({ ...this.props.arqcActiveChecklist }, attachment.questionID);

          return {
            ...attachment,
            questionText: updatedQuestionText === '' ? attachment.questionText : updatedQuestionText
          };
        });

        this.setState({ attachmentDetails: updatedAttachmentDetails });
      }
    }

    // Refresh comments details table post addition/deletion of attachments
    if (this.props.questionComments !== prevProps.questionComments) {
      if (this.props.arqcActiveChecklist && this.props.arqcActiveChecklist !== null && prevProps.arqcActiveChecklist.content.length > 0) {
        const updatedCommentsDetails = this.props.questionComments.map((comment) => {
          const updatedQuestionText = this.getUpdatedQuestionTitleBasedOnChecklistSectionQuestionId({ ...prevProps.arqcActiveChecklist }, comment.checklistSectionQuestionID);
          return {
            ...comment,
            questionText: updatedQuestionText === '' ? comment.questionText : updatedQuestionText
          };
        });

        this.setState({ questionComments: updatedCommentsDetails });
      }
      }

    if(prevProps.errorResponse && (this.props.errorResponse !== prevProps.errorResponse)) {
      this.setState({currentError: prevProps.errorResponse}) 
    }
  }


  componentWillUnmount() {
    this.props.commonActions.clearErrorResponse();
  }

  setGridColumnState() {
    let localState = '';
    if (this.state.activeItem === 'Comments') {
      localState = 'REITSuiteShowCommentsColumnState';
    }
    else if (this.state.activeItem === 'Attachments') {
      localState = 'REITSuiteShowAttachmentColumnState';
    }
    else if (this.state.activeItem === 'Discrepancies') {
      localState = 'REITSuiteShowDiscrepanyColumnState';
    }
    var columnState = localStorage.getItem(localState);
    if (columnState) {
      this.setState({ gridColumnState: JSON.parse(columnState) });
    }
    else {
      this.setState({ gridColumnState: [{ colId: '-1' }] });
    }
  }

  /**
  * Reset AG-Grid column state
  * @returns {undefined}
  */
  resetGridColumnState() {
    let localState = '';
    if (this.state.activeItem === 'Comments') {
      localState = 'REITSuiteShowCommentsColumnState';
    }
    else if (this.state.activeItem === 'Attachments') {
      localState = 'REITSuiteShowAttachmentColumnState';
    }
    else if (this.state.activeItem === 'Discrepancies') {
      localState = 'REITSuiteShowDiscrepanyColumnState';
    }
    localStorage.removeItem(localState);
    this.setState({ gridColumnState: [] });
  }

  /**
  * Handle Grid column state chagne
  * @returns {undefined}
  */
  handleGridColumnStateChange(columnState) {
    this.setState({ gridColumnState: columnState });
  }

  handleGridChanges(gridRowData) {
    let rowData = [];

    switch (this.state.activeItem) {
      case 'Comments': {
        rowData = gridRowData && gridRowData.length && gridRowData.map(x => {
          return {
            snapshotSectionName: x.snapshotSectionName,
            questionText: x.questionText,
            commentText: x.commentText,
            commentedBy: x.commentedBy,
            dateCommented: x.dateCommented,
          }
        });
        break;
      }
      case 'Attachments': {
        rowData = gridRowData && gridRowData.length && gridRowData.map(x => {
          return {
            snapshotSectionName: x.snapshotSectionName,
            questionText: x.questionText,
            attachedFileName: x.attachedFileName,
            uploadedBy: x.uploadedBy,
            dateUploaded: x.dateUploaded,
          }
        });
        break;
      }
      case 'Discrepancies': {
        rowData = gridRowData && gridRowData.length && gridRowData.map(x => {
          return {
            sectionName: x.sectionName,
            questionText: x.questionText,
            discrepancyMessage: x.discrepancyMessage,
          }
        });
        break;
      }
    }
    this.setState({ gridCurrentState: rowData });
  }

  setActiveItem(e, { name }) {
    const item = this.props.arqcActiveChecklist.content.find(
      x => x.title === name,
    );
    if (item === undefined && name) {
      this.setState({ activeItem: name, isSubNavigation: true });
    }
    else if (this.state.disabledSections.find(x => x === item.id) === undefined) {
      this.setState({ activeItem: item, isSubNavigation: false });
    }
  }

  getChecklistCount(clientId, checklisId) {
    let checklistGuid = null;
    if (!this.props.isExternalPermUser) {
      checklistGuid = this.props.checklistId;
    }

    this.getAttachmentDetails(clientId, checklisId, checklistGuid, serviceConstants.ARQC_SERVICE);
    this.getCommentsDetails(clientId, checklisId, checklistGuid, serviceConstants.ARQC_SERVICE);
    this.props.checklistActions.fetchChecklistDiscrepancies(clientId, checklisId, checklistGuid,
      serviceConstants.ARQC_SERVICE);

    this.props.checklistActions
      .fetchChecklistNavCounts(
        clientId,
        checklisId,
        checklistCountRequest.ChecklistCountRequest,
        checklistGuid,
        serviceConstants.ARQC_SERVICE
      )
      .then((data) => {
        this.setState({ counters: data });
      });

  }

  getAttachmentDetails(clientId, checklisId, checklistGuid, checklistType) {
    this.props.checklistActions.fetchAttachmentDetail(clientId, checklisId, null, checklistGuid, checklistType)
      .then(() => {
        if (this.props.arqcActiveChecklist && this.props.arqcActiveChecklist !== null && this.props.arqcActiveChecklist.content.length > 0) {
          const updatedAttachmentDetails = this.props.attachmentDetails.map((attachment) => {
            const updatedQuestionText = this.getUpdatedQuestionTitleBasedOnChecklistSectionQuestionId({ ...this.props.arqcActiveChecklist }, attachment.questionID);

            return {
              ...attachment,
              questionText: updatedQuestionText === '' ? attachment.questionText : updatedQuestionText
            };
          });

          this.setState({ attachmentDetails: updatedAttachmentDetails });
        }
      });
  }

  getCommentsDetails(clientId, checklisId, checklistGuid, checklistType) {
    this.props.checklistActions.fetchQuestionComments(clientId, checklisId, checklistGuid, checklistType)
      .then(() => {
        if (this.props.arqcActiveChecklist && this.props.arqcActiveChecklist !== null && this.props.arqcActiveChecklist.content.length > 0) {
          const updatedCommentsDetails = this.props.questionComments.map((comment) => {
            const updatedQuestionText = this.getUpdatedQuestionTitleBasedOnChecklistSectionQuestionId({ ...this.props.arqcActiveChecklist }, comment.checklistSectionQuestionID);

            return {
              ...comment,
              questionText: updatedQuestionText === '' ? comment.questionText : updatedQuestionText
            };
          });

          this.setState({ questionComments: updatedCommentsDetails });
        }
      });
  }

  checkReportPeriodVisibilityConstraint() {
    return this.props.checklistActions
      .allowUpdateAsPerReportPeriodVisibilityConstraint(
        this.state.checklist.checklistID,
        "ARQC",
        !this.props.isExternalPermUser,
        !this.props.isExternalPermUser ?  
        actionHelpers.buildAuthHeader(
          this.props.checklistId,
          "ARQC"
        ) 
        : null
      );
  }

  handleGridRowDeleteById(id) {
    // Before delete, check whether report period of checklist having visibility status as hidden
    this.checkReportPeriodVisibilityConstraint().then((response) => {  
      let proceedWithAction = true;
      if(response && response.errorResponse && response.errorResponse.errorCode === 'RESOURCE_NOT_FOUND'){
        proceedWithAction = false;
      }

      if(proceedWithAction){      
        let activeTab = this.state.activeItem;
        if (this.state.activeItem === 'Attachments') {
          this.setState({ selectedAttachmentId: id });
        }
        else if (this.state.activeItem === 'Comments') {
          this.setState({ selectedCommentId: id });
        }
        else {
          activeTab = "Attachments";
          this.setState({ selectedAttachmentId: id });
        }
        this.setState({ deleteFor: activeTab });
        this.toggleShowChecklistGridRowDelete();
      }
      
    }); 
  }

  toggleShowChecklistGridRowDelete() {
    const showToggle = !this.state.showDeleteChecklistGridRowModal;
    this.setState({
      showDeleteChecklistGridRowModal: showToggle,
    });
  }

  deleteAttachment() {
    let checklistGuid = null;
    if (!this.props.isExternalPermUser) {
      checklistGuid = this.props.checklistId;
    }
    const clientId = this.props.client.id;
    this.props.checklistActions
      .deleteAttachment(
        this.state.selectedAttachmentId,
        clientId,
        checklistGuid,
        serviceConstants.ARQC_SERVICE
      )
      .then((data) => {
        this.getAttachmentDetails(clientId, this.props.arqcActiveChecklist.checklistID, checklistGuid, serviceConstants.ARQC_SERVICE);

        this.props.checklistActions
          .fetchChecklistNavCounts(
            clientId,
            this.props.arqcActiveChecklist.checklistID,
            checklistCountRequest.ChecklistCountRequest,
            checklistGuid,
            serviceConstants.ARQC_SERVICE
          );

        this.toggleShowChecklistGridRowDelete();
      })
      .catch((error) => {
        this.toggleShowChecklistGridRowDelete();
      });
  }


  deleteComment() {
    let checklistGuid = null;
    if (!this.props.isExternalPermUser) {
      checklistGuid = this.props.checklistId;
    }
    const clientId = this.props.client.id;
    this.props.checklistActions
      .deleteComment(
        this.state.selectedCommentId,
        clientId, checklistGuid, serviceConstants.ARQC_SERVICE
      )
      .then((data) => {
        this.getCommentsDetails(clientId, this.props.arqcActiveChecklist.checklistID, checklistGuid, serviceConstants.ARQC_SERVICE);

        this.props.checklistActions
          .fetchChecklistNavCounts(
            clientId,
            this.props.arqcActiveChecklist.checklistID,
            checklistCountRequest.ChecklistCountRequest,
            checklistGuid, serviceConstants.ARQC_SERVICE
          );


        this.toggleShowChecklistGridRowDelete();
      })
      .catch((error) => {
        this.toggleShowChecklistGridRowDelete();
      });
  }

  handleSaveCommentExternal(questionId, commentText) {
    return this.handleSaveComment(questionId, commentText, true, !this.props.isExternalPermUser);
  }

  getIsGridNoDataFound(name) {
    if ((name === "Comments"
      && this.props.questionComments
      && this.props.questionComments.length === 0)
      || (name === "Attachments"
        && this.props.attachmentDetails
        && this.props.attachmentDetails.length === 0)
      || (name === "Discrepancies"
        && this.props.discrepanciesDetail
        && this.props.discrepanciesDetail.length === 0)) {
      return true;
    }
    else { return false; }
  }


  setCanEdit(period) {
    const isEnabled = periodActions.isReportingEnabled(period);
    this.setState({ canEdit: isEnabled });
  }

  populateMissingTaxableReitSubsidiaries() {
    let trses = Object.assign([], this.state.taxableReitSubsidiaries);

    if (!Array.isArray(trses)) {
      trses = [];
    }

    let changed = false;

    if (this.state.initialValues && this.state.initialValues.propertyAmenities) {
      const propertyAmenities = this.state.initialValues.propertyAmenities;

      for (let i = 0; i < propertyAmenities.length; i++) {
        const item = propertyAmenities[i];
        if (item.taxableReitSubsidiary && !trses.some(x => x.name === item.taxableReitSubsidiary.label)) {
          trses.push({ name: item.taxableReitSubsidiary.label });
          changed = true;
        }
      }

      if (changed) {
        this.setState({ taxableReitSubsidiaries: trses });
      }
    }
  }

  handleSubmit() {
    const checklist = JSON.parse(JSON.stringify(this.state.checklist));
    checklist.sendNotification = true;
    this.saveExternalChecklist(checklist, this.props.checklistId, 'ARQC');

    this.showSendModal();
  }

  handleExternalConfirmationAction(e) {
    const value = e?.target?.value || "CANCEL"; 

  if (value === "CANCEL") {
    this.showConfirmationModal();
    return;
  }

    const checklist = JSON.parse(JSON.stringify(this.state.checklist));
    checklist.checklistStateID = e.target.value;
    this.setState({ checklist: checklist });
    if (e.target.value === "PENDINGEYREVIEW") {
      checklist.sendNotification = true;
      this.setState({ isSubmittedForEyReview: true });
    }

    this.saveExternalChecklist(checklist, this.props.checklistId, 'ARQC');
    this.showConfirmationModal();
  }

  handleAddArqcExhibitBRow(section) {
    const checklist = JSON.parse(JSON.stringify(this.state.checklist));

    const foundSection = checklist
      .content.find(x => x.id === section.id);

    const count = foundSection.items === undefined ? 0 : foundSection.items.length;

    if (foundSection.items === undefined) {
      checklist
        .content.find(x => x.id === section.id)
        .items = [];
    }

    checklist
      .content.find(x => x.id === section.id)
      .items.push({ "index": count, "serviceName": "", "E": false, "IK": false, "TRS": false });

    this.setState({ checklist: checklist });
  }

  handleRemoveArqcExhibitBRow(section, item) {
    const checklist = JSON.parse(JSON.stringify(this.state.checklist));

    checklist
      .content.find(x => x.id === section.id)
      .items.splice(item.index, 1);

    // re-index remaining items
    for (let i = 0; i < checklist.content.find(x => x.id === section.id).items.length; i++) {
      checklist.content.find(x => x.id === section.id).items[i].index = i;
    }

    this.setState({ checklist: checklist });
  }

  handleArqcExhibitBItemAnswered(e, section, item) {
    const checklist = JSON.parse(JSON.stringify(this.state.checklist));

    if (e.target !== undefined && e.target.type !== undefined && e.target.type.toLowerCase() === "checkbox") {
      if (e.target.name.toLowerCase() === "e") {
        checklist
          .content.find(x => x.id === section.id)
          .items.find(x => x.index === item.index).e = e.target.checked;
      } else if (e.target.name.toLowerCase() === "ik") {
        checklist
          .content.find(x => x.id === section.id)
          .items.find(x => x.index === item.index).ik = e.target.checked;
      } else if (e.target.name.toLowerCase() === "trs") {
        checklist
          .content.find(x => x.id === section.id)
          .items.find(x => x.index === item.index).trs = e.target.checked;
      }
    } else if (e.type !== undefined && e.type.toLowerCase() === "dropdown") {
      checklist
        .content.find(x => x.id === section.id)
        .items.find(x => x.index === item.index).serviceName = e.value;
    }

    this.setState({ checklist: checklist });
  }

  handleSaveExternalArqcChecklist() {
    const checklist = JSON.parse(JSON.stringify(this.state.checklist));
    this.saveExternalChecklist(checklist, this.props.checklistId, 'ARQC');
  }

  downloadChecklist(withAnswer) {
    this.checkReportPeriodVisibilityConstraint().then((response) => {
      let proceedWithAction = true;            
      if(response && response.errorResponse && response.errorResponse.errorCode === 'RESOURCE_NOT_FOUND'){
        proceedWithAction = false;
      }
    
      if(proceedWithAction){
        const reportPeriodDescription = this.props.isExternalPermUser ? this.state.period.reportPeriodDescription : this.props.period.reportPeriodDescription;
        const reportPeriodID = this.props.isExternalPermUser ? this.state.period.reportPeriodID : this.props.period.reportPeriodID;
        const checklistGuid = this.props.checklistId;
        const filename = `${reportPeriodDescription.replace(' ', '')}_${this.state.reit.reitName}_ARQC.docx`
        this.props.commonActions.clearErrorResponse();
        this.props.reportActions.downloadChecklist('reitName=' + this.state.reit.reitName + ',periodDesc=' + reportPeriodDescription, reportPeriodID, 'ARQC', this.state.checklist.checklistID, withAnswer, filename, true, checklistGuid);        
      }
    });
  }

  isChecklistContentEnabled() {
    return this.props.arqcActiveChecklist !== null &&
      this.props.arqcActiveChecklist.checklistStateID !== undefined &&
      this.props.arqcActiveChecklist.checklistStateID.toLowerCase() !== "completed" &&
      this.props.arqcActiveChecklist.checklistStateID.toLowerCase() !== "pendingeyreview" &&
      !this.state.isSubmittedForEyReview;
  }

  handleSaveBtnClick() {
    if(this.props.isExternalPermUser){
      this.props.checklistActions.fetchExternalPermArqcChecklists(this.props.internalChecklistId, true)
      .then((result) => {
        if (!(result && result.errorResponse && result.errorResponse.errorCode === "CHECKLIST_SUBMITTED")) {
          this.checkReportPeriodVisibilityConstraint().then((response) => {
            if (!(response && response.errorResponse && response.errorResponse.errorCode === 'RESOURCE_NOT_FOUND')) {
              this.handleSaveExternalArqcChecklist();
            }
          });
        }
      });
    }
    else{
     this.props.checklistActions.fetchExternalArqcChecklistsById(this.props.checklistId, true)
      .then((result) => {
        if (!(result && result.errorResponse && result.errorResponse.errorCode === "CHECKLIST_SUBMITTED")) {
          this.checkReportPeriodVisibilityConstraint().then((response) => {
            if (!(response && response.errorResponse && response.errorResponse.errorCode === 'RESOURCE_NOT_FOUND')) {
              this.handleSaveExternalArqcChecklist();
            }
          });
        }
      });
  }
}

  handleSubmitEyReviewBtnClick() {
    if(this.props.isExternalPermUser){
      this.props.checklistActions.fetchExternalPermArqcChecklists(this.props.internalChecklistId, true)
      .then((result) => {
        if (!(result && result.errorResponse && result.errorResponse.errorCode === "CHECKLIST_SUBMITTED")) {
          return this.checkReportPeriodVisibilityConstraint().then((response) => {
            if (!(response && response.errorResponse && response.errorResponse.errorCode === 'RESOURCE_NOT_FOUND')) {
              this.showConfirmationModal();
            }
          });
        }
      });
    
    }
    else{
    this.props.checklistActions.fetchExternalArqcChecklistsById(this.props.checklistId, true)
      .then((result) => {
        if (!(result && result.errorResponse && result.errorResponse.errorCode === "CHECKLIST_SUBMITTED")) {
          return this.checkReportPeriodVisibilityConstraint().then((response) => {
            if (!(response && response.errorResponse && response.errorResponse.errorCode === 'RESOURCE_NOT_FOUND')) {
              this.showConfirmationModal();
            }
          });
        }
      });
  }
}

  getDetails() {
    const isEnabled = this.isChecklistContentEnabled();
    if (this.state.showDetails && this.state.checklist) {
      return (
        <div>
          {this.props.arqcActiveChecklist &&
            this.props.arqcActiveChecklist.prePopulateAnswerOptionID === 1 &&
            <div className="mb-3">
              (Pre-populated with answers from prior questionnaire)
            </div>}
          <div className="row">
            <div className="col-md-3 mb-2">
              <ChecklistNavigation
                disabledSections={this.state.disabledSections}
                checklist={this.state.checklist}
                activeItem={this.state.activeItem}
                handleSelectionChanged={this.setActiveItem}
                checklistFiles={this.props.attachmentDetails}
                navigationCount={this.props.checklistCounter}
                isExternal={true}
              />
            </div>
            <div className="col-md-9 mb-2">
              {!this.state.isSubNavigation && (
                <>
                  <ArqcChecklistDetailsForm
                    onSubmit={this.handleArqcDetailsFormSubmit}
                    handleFormSubmit={this.handleSaveExternalArqcChecklist}
                    checklist={this.state.checklist}
                    isEnabled={isEnabled}
                    activeItem={this.state.activeItem}
                    handleItemAnswered={this.handleItemAnswered}
                    handleComments={this.handleComments}
                    isExternal={true}
                    showAllQuestions={this.state.showAllQuestions}
                    showUnansweredQuestions={this.state.showUnansweredQuestions}
                    checklistId={this.props.checklistId}
                    isArqc={true}
                    periodId={this.props.arqcActiveChecklist.reportPeriodID}
                    showConfirmationModal={this.showConfirmationModal}
                    taxableReitSubsidiaries={this.props.taxableReitSubsidiaries}
                    initialValues={this.state.initialValues}
                    tbMode={this.state.tbMode}
                    checkReportPeriodVisibilityConstraint={this.checkReportPeriodVisibilityConstraint}                      
                    handleSaveComment={this.handleSaveCommentExternal}
                    handleDeleteComment={this.handleDeleteCommentExternal}
                    handleEditComment={this.handleEditCommentExternal}
                    handleDeleteAttachment={this.handleGridRowDeleteById}
                    handleDownloadChecklistFile={this.handleDownloadChecklistFileExternal}
                    checklistFiles={this.props.attachmentDetails}
                    canEdit={this.state.canEdit && this.isChecklistContentEnabled()}
                    externalEmail={this.props.arqcActiveChecklist.emailFromIdentifier}
                    user={this.props.user}
                    previousArqcChecklist={this.props.previousArqcChecklist}
                    questionComments={this.props.questionComments}
                  />
                </>
              )}
              {this.state.isSubNavigation && (
                <>
                  <h5 className="sectionTitle">{this.state.activeItem}</h5>

                  {this.getIsGridNoDataFound(this.state.activeItem) &&
                    <div>No {this.state.activeItem.toLowerCase()} found for this checklist.</div>
                  }

                  {this.state.activeItem === 'Comments' &&
                    <ShowCommentsTable
                      clientId={this.props.client.id}
                      questionComments={this.state.questionComments}
                      gridColumnState={this.state.gridColumnState}
                      handleGridColumnStateChange={this.handleGridColumnStateChange}
                      handleGridChanges={this.handleGridChanges}
                      handleRowDelete={this.handleGridRowDeleteById}
                      canEdit={this.state.canEdit && this.isChecklistContentEnabled()}
                    />
                  }
                  {this.state.activeItem === 'Attachments' &&
                    <ShowAttachmentTable
                      attachmentDetail={this.state.attachmentDetails}
                      gridColumnState={this.state.gridColumnState}
                      handleGridColumnStateChange={this.handleGridColumnStateChange}
                      handleGridChanges={this.handleGridChanges}
                      handleDeleteAttachment={this.handleGridRowDeleteById}
                      handleDownloadChecklistFile={ (checklistFileAttachmentId, fileName) => {
                        this.checkReportPeriodVisibilityConstraint().then((response) => {
                          let proceedWithAction = true;                                  
                          if(response && response.errorResponse && response.errorResponse.errorCode === 'RESOURCE_NOT_FOUND'){
                            proceedWithAction = false;
                          }
                          if(proceedWithAction){
                            this.handleDownloadChecklistFileExternal(checklistFileAttachmentId, fileName);
                          }
                        });
                        }
                      }
                      canEdit={this.state.canEdit && this.isChecklistContentEnabled()}
                    />
                  }
                  {this.state.activeItem === 'Discrepancies' &&
                    <ShowDiscrepanciesTable
                      discrepanciesDetail={this.props.discrepanciesDetail}
                      gridColumnState={this.state.gridColumnState}
                      handleGridColumnStateChange={this.handleGridColumnStateChange}
                      handleGridChanges={this.handleGridChanges}
                    />
                  }
                </>
              )}
            </div>
          </div>
          <DeleteChecklistDetailGridRowModal
            showDeleteChecklistGridRowModal={
              this.state.showDeleteChecklistGridRowModal}
            toggleshowDeleteChecklistGridRowModal={
              this.toggleShowChecklistGridRowDelete}
            deleteRow={this.state.deleteFor === "Attachments" ? this.deleteAttachment : this.deleteComment}
            modalName={this.state.deleteFor} />

          <ChecklistReadyModal
            handleModalAction={this.handleExternalConfirmationAction}
            showModal={this.state.showConfirmationModal}
            isSendCheckListEmailEnabled={this.state.isSendCheckListEmailEnabled} />
        </div>

      );
    }

    return null;
  }

  /**
   * Render a React element
   * @returns {Object} A reference to the component
   */
  render() {
    if ((this.props.errorResponse && (this.props.errorResponse.errorCode === "LINK_EXPIRED" || this.props.errorResponse.errorCode === "UNKNOWN" || this.props.errorResponse.errorCode === 'FORBIDDEN'))
      || (this.state.currentError &&  (this.state.currentError.errorCode === "LINK_EXPIRED" || this.state.currentError.errorCode === "UNKNOWN" || this.state.currentError.errorCode === 'FORBIDDEN'))
      ){
      return (
        <div>
          <h2 data-testid="h2PageHeading">Annual REIT Qualification Checklist</h2>
          <br />
          <h4>
            We apologize this link has expired.
          </h4>
        </div>
      );
    }

    if (!this.props.isExternalPermUser && ((this.props.errorResponse && this.props.errorResponse.errorCode === "CHECKLIST_SUBMITTED")
      || (this.props.arqcActiveChecklist && this.props.arqcActiveChecklist.checklistStateID === "PendingEyReview")
      || this.state.isSubmittedForEyReview
      || (this.state.currentError && this.state.currentError.errorCode === "CHECKLIST_SUBMITTED"))) {
      return (
        <div>
          <h2 data-testid="h2PageHeading">Annual REIT Qualification Checklist</h2>
          <br />
          <h4>
            This ARQC has been submitted for EY review.
          </h4>
        </div>
      );
    }

    if ((this.props.errorResponse && this.props.errorResponse.errorCode === "RESOURCE_NOT_FOUND") ||
      (this.state.currentError && this.state.currentError.errorCode === "RESOURCE_NOT_FOUND")) {
      return (
        <div>
          <h2 data-testid="h2PageHeading">Annual REIT Qualification Checklist</h2>
          <br />
          <h4>
            We apologize this link has expired.
          </h4>
        </div>
      );
    }

    return (
      <div className="mt-2">
        <div className="row" style={{ alignItems: "center" }}>
          <div className="col">
            <h2 data-testid="h2PageHeading">{this.getArqcQuestionnaireTitle()}</h2>
          </div>
        </div>
        <div className="row mt-3 mb-3">
          <div className="col-md-4 checklistCounters">
            <ChecklistCounters
              previousChecklistFound={
                this.props.previousArqcChecklist &&
                this.props.previousArqcChecklist.content
              }
              isExternal={true}
              checklist={this.state.checklist}
              disabledSections={this.state.disabledSections}
              // Hide Changed Answers when user is external user accessing one time URL and 
              // checklist has not pre-populated previous answers and reference answers
              shouldHideChangedAnswers={!this.props.isExternalPermUser && this.props.arqcActiveChecklist && this.props.arqcActiveChecklist.prePopulateAnswerOptionID === 2}
            />
          </div>
          <div className="col-md-8">
            <div className="d-flex ml-auto justify-content-end">
              <span className="ml-3">
                <MotifDropdown
                  open={this.state.checklistDropdownOpen} 
                  onClick={()=>{
                    if(this.props.isExternalPermUser){
                    this.props.checklistActions.fetchExternalPermArqcChecklists(this.props.internalChecklistId);}
                    else{this.props.checklistActions.fetchExternalArqcChecklistsById(this.props.checklistId); }                               
                   }
                  }             
                  placement="bottom-end"
                  id={'checklist-dropdown'}
                  aria-labelledby={'client-management-trigger'}
                  data-testid="btnDownloadChecklist"
                  handleClickOutside={() => this.setState({ checklistDropdownOpen: false })}
                  trigger={
                    <MotifButton
                      disabled={!(this.props.arqcActiveChecklist && this.props.arqcChecklists !== undefined && this.props.arqcChecklists.length > 0)}
                      type="button"
                      variant="primary-alt"
                      onClick={() => this.setState({ checklistDropdownOpen: !this.state.checklistDropdownOpen })}
                      aria-controls={'checklist-dropdown'}
                      id={'checklist-dropdown-trigger'}
                      aria-expanded={this.state.checklistDropdownOpen}
                      aria-label="Download checklist dropdown"
                    >
                      Download Checklist <MotifIcon iconFunction={IconoirNavArrowDown} size='24' fill='none' />
                    </MotifButton>
                  }
                >
                  <MotifDropdownItem onClick={() => { this.setState({ checklistDropdownOpen: false }); this.downloadChecklist('WITH_ANSWERS'); }}>
                    With Answers Only
                  </MotifDropdownItem>
                  <MotifDropdownItem onClick={() => { this.setState({ checklistDropdownOpen: false }); this.downloadChecklist('WITH_ANSWERS_COMMENTS') }}>
                    With Answers And Comments
                  </MotifDropdownItem>
                  <MotifDropdownItem onClick={() => { this.setState({ checklistDropdownOpen: false }); this.downloadChecklist('WITHOUT_ANSWERS'); }}>
                    Without Answers or Comments
                  </MotifDropdownItem>
                </MotifDropdown>
              </span>

              <MotifButton variant="secondary" className="ml-2" type="button" data-testid="btnSubmitForEYReview"
                 onClick={()=>{
                 this.handleSubmitEyReviewBtnClick();            
                }
              }
                disabled={!this.isChecklistContentEnabled()}>
                Submit for EY Review
              </MotifButton>
              <MotifButton variant="primary" className="ml-2" type="submit" data-testid="btnSave"
                onClick={()=>{
                  this.handleSaveBtnClick();
                }}
                disabled={!this.isChecklistContentEnabled()}>
                Save
              </MotifButton>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col d-flex mr-auto">
            <div className="d-flex flex-row" style={{ alignItems: 'center' }} data-testid="toggleDisplayUnansweredQuestionsOnly">
              <div className="mr-3">
                <span className="right font-weight-bold">Display Unanswered Questions Only:</span>
              </div>
              <div>
                <div className="switch-box">
                  <MotifToggleSwitch
                    aria-label="Display Unanswered Questions Only toggle"
                    name="showUnanswered"
                    id="showUnanswered"
                    onChange={this.handleShowUnansweredQuestions}
                    checked={this.state.showUnansweredQuestions}
                  />
                </div>
              </div>
            </div>

            {/* Do Not show Display Changed Answers button to Unregistered External users*/}
            {(this.props.isExternalPermUser && this.state.showDetails && this.props.arqcActiveChecklist && this.props.previousArqcChecklist && Array.isArray(this.props.previousArqcChecklist.content) && this.props.previousArqcChecklist.content.length > 0 && (
              <div className="d-flex flex-row ml-4" style={{ alignItems: 'center' }} data-testid="toggleDisplayChangedAnswers">
                <div className="mr-3">
                  <span className="font-weight-bold">Display Changed Answers:</span>
                </div>
                <div>
                  <div className="switch-box">
                    <MotifToggleSwitch
                      aria-label="Display Changed Answers toggle"
                      name="showAll"
                      id="showAll"
                      onChange={this.handleShowAllQuestions}
                      checked={!this.state.showAllQuestions}
                    />
                  </div>
                </div>
              </div>)) || (<div />)}
          </div>
        </div>

        {this.props.authenticationScope !== 'external' &&
          <React.Fragment>
            <div className="inline rtable">
              <span>
                {this.state.checklist && this.state.checklist.reitName != "" ? `${this.state.checklist.reitName} ` : ''}
                ARQC Checklist
              </span>
            </div>
          </React.Fragment>
        }
        {this.getDetails()}
        <ArqcCreateChecklistModal
          properties={this.props.properties}
          handleFieldChanged={this.handleCreateField}
          handleModalAction={this.handleCreateAction}
          showModal={this.state.showCreateModal} />
        <ChecklistSendModal
          onSubmit={this.handleSubmit}
          handleFieldChanged={this.handleSendField}
          showModal={this.state.showSendModal}
          showSendModal={this.showSendModal}
          isSendCheckListEmailEnabled={this.state.isSendCheckListEmailEnabled} />
      </div>
    );
  }
}

ExternalArqcChecklistPage.propTypes = {
  client: PropTypes.object.isRequired,
  reit: PropTypes.object.isRequired,
  arqcChecklists: PropTypes.array,
  arqcActiveChecklist: PropTypes.object,
  checklistActions: PropTypes.object,
  propertyActions: PropTypes.object,
  fileActions: PropTypes.object,
  checklistId: PropTypes.string,
  internalChecklistId: PropTypes.string,
  errorResposne: PropTypes.shape({
    errorCode: PropTypes.string,
    message: PropTypes.string
  }),
  services: PropTypes.array,
  authenticationScope: PropTypes.string,
  previousArqcChecklist: PropTypes.object,
  user: PropTypes.string
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @param {Object} ownProps The properties of the component
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
  return {
    client: state.client,
    arqcChecklists: state.externalArqc,
    arqcActiveChecklist: state.arqcActiveChecklist,
    checklistFiles: state.checklistFiles,
    reit: state.reit,
    checklistId: ownProps.router.params.checklistId,
    internalChecklistId: ownProps.router.params.internalChecklistId,
    isExternalPermUser: ownProps.router.params.checklistId ? false : true,
    errorResponse: state.errorResponse,
    distributionDetails: state.distributionDetails,
    distributionTestSummary: state.distributionTestSummary,
    distributionTaxableIncome: state.distributionTaxableIncome,
    taxableReitSubsidiaries: state.taxableReitSubsidiaries,
    services: state.services,
    period: state.period,
    user: state.authentication.currentUser,
    authenticationScope: state.authenticationScope,
    previousArqcChecklist: state.previousArqcChecklist,
    lookupTypes: state.lookupTypes,
    questionComments: state.questionComments,
    attachmentDetails: state.attachmentDetail,
    discrepanciesDetail: state.discrepanciesDetail,
    checklistCounter: state.checklistsCounter,
    allowUpdateReportPeriodVisibilityConstraintResult: state.allowUpdateReportPeriodVisibilityConstraintResult
  };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
  return {
    checklistActions: bindActionCreators(checklistActions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
    distributionActions: bindActionCreators(distributionActions, dispatch),
    trsActions: bindActionCreators(trsActions, dispatch),
    serviceActions: bindActionCreators(serviceActions, dispatch),
    periodActions: bindActionCreators(periodActions, dispatch),
    reportActions: bindActionCreators(reportActions, dispatch),
    fileActions: bindActionCreators(fileActions, dispatch),
    reitActions: bindActionCreators(reitActions, dispatch),
    clientActions: bindActionCreators(clientActions, dispatch),
    customChecklistTemplateActions: bindActionCreators(customChecklistTemplateActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ExternalArqcChecklistPage));