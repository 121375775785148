import React from "react";
import PropTypes from "prop-types";
import { Icon } from 'semantic-ui-react';
import { FormattedNumber } from 'react-intl';
import { MotifChip } from '@ey-xd/motif-react';

const TestSummaryItem = ({ test, totalItems , incompleteSecuritiesSnapshots }) => {
    let testTitle = test.testTitle || "";
    const colSize = Math.floor(12 / totalItems);
    const className = `summary col-md-${colSize}`;
    const re = /(\d+%)(.*)/;
    const matches = testTitle.match(re);
    let hasIncompleteSecuritySnapshot = false;
    
    // Check for non-empty incompleteSecuritiesSnapshots array and set hasIncompleteSecurities flag accordingly
    let hasIncompleteSecurities = Array.isArray(incompleteSecuritiesSnapshots) && incompleteSecuritiesSnapshots.length > 0;
    
    if (hasIncompleteSecurities) {
        hasIncompleteSecuritySnapshot = incompleteSecuritiesSnapshots[0].hasIncompleteSecuritySnapshot;
    }
    
    if (Array.isArray(matches) && matches.length === 3) {
        testTitle = <span>{matches[1]}{matches[2]}</span>;
    }

    // Display 'Incomplete Securities' if title indicates 10% and incomplete securities exist, otherwise show 'PASS' or 'FAIL'.
    let hasTenPercentageTitle = Array.isArray(matches) && matches.length === 3 && matches[1] != null && matches[1] === '10%';

    return (
        <div className={className}>
            <div className="testname">{testTitle}</div>
            <div className={"teststatus testresults"}>
                {
                   !(hasTenPercentageTitle && hasIncompleteSecuritySnapshot) && (
                    <FormattedNumber value={(test.testResultPercent !== 0 && test.testResultPercent / 100) || 0} style="percent" minimumFractionDigits={3} maximumFractionDigits={3} />
                   )               
                }
            </div> 
                <div className="chip-container">
                <MotifChip
                variant={(hasTenPercentageTitle && hasIncompleteSecuritySnapshot) 
                    ? "error roundedCornerIncompleteSecurities" : (test.isPassing) ? "success" : "error"}
                value={(hasTenPercentageTitle && hasIncompleteSecuritySnapshot) 
                    ? "Incomplete Securities" 
                    :  test.isPassing  ? "PASS" : "FAIL"} 
                data-testid="badgeTestSummary"
                style={hasTenPercentageTitle && hasIncompleteSecuritySnapshot ? { marginTop: '13px' } : {}}
                >
                {(hasTenPercentageTitle && hasIncompleteSecuritySnapshot) 
                    ? "Incomplete Securities" 
                    :  test.isPassing  ? "PASS" : "FAIL"} 
                </MotifChip>
                </div>
        </div>       
    );
};

TestSummaryItem.propTypes = {
    test: PropTypes.shape({
        testTitle: PropTypes.string,
        isPassing: PropTypes.bool,
        testResultPercent: PropTypes.number
    }),
    totalItems: PropTypes.number.isRequired
};

export default TestSummaryItem;
