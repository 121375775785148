import React,{useState} from 'react';
import { Link } from 'react-router-dom';
import { MotifBadge, MotifCheckbox, MotifTextLink,MotifTooltip  } from '@ey-xd/motif-react';
import DataStewardAssignmentRequiredModal from '../../../components/dataStewardRoleManagement/dataStewardAssignmentRequiredModal';
import * as Constants from '../../../constants/other';
import * as authActions from "../../../actions/authActions";
export default (props) => {
    const client = props.node.data;
    let currentUserAuthorizations=props.currentUserAuthorizations;
    let isEngagementAdministrator=authActions.isEngagementAdministrator(props.currentUserAuthorizations, client.id);  
    let isEngagementUser=authActions.isUser(props.currentUserAuthorizations,client.id);
    let isSystemAdmin=authActions.isSystemAdministrator(currentUserAuthorizations);
    let hasIncompleteSecuritySnapshot;
        if (props.hasIncompleteSecuritySnapshotsList?.length > 0 && props.column.colId == "name") {
            hasIncompleteSecuritySnapshot = props?.hasIncompleteSecuritySnapshotsList.find(x => x.clientID === client.id)?.hasIncompleteSecuritySnapshot;
        }
            const [showEngagementAdminForDataStewardAssignmentModal, setShowEngagementAdminForDataStewardAssignmentModal] = useState(false);

  const toggleShowEngagementAdminForDataStewardAssignmentModal = () => {
    setShowEngagementAdminForDataStewardAssignmentModal(!showEngagementAdminForDataStewardAssignmentModal);
  };
  function hasClientDatasteward(client) {
		
    return !(client.isLockedForNotHavingDataSteward == null || client.isLockedForNotHavingDataSteward);
			
  }


    const rowIndex = props.node.rowIndex + 2;
    const toggleActiveFlag = (e) => {
        props.handleToggleActiveFlag(e, client.id, client.name, client.legalName);
    };

    if (client !== undefined) {
        return (
            <div>
                {(props.column.colId == "name") &&
                    <div aria-label="Press ENTER to open this client." aria-rowindex={rowIndex}>
                       
                       {!isSystemAdmin && isEngagementAdministrator  && !hasClientDatasteward(client) ?
                        (<Link to={'/client/' + client.id + '/dataStewardRoleManagement'} className='client-text-link' >
                        <MotifTextLink variant='alt'>{client.name}</MotifTextLink>
                    </Link>):!isSystemAdmin && isEngagementUser && !hasClientDatasteward(client) ?
                     ( <Link  onClick={(event) => {
                        event.preventDefault();
                        toggleShowEngagementAdminForDataStewardAssignmentModal();
                      }} >
                     <MotifTextLink variant='alt'>{client.name}</MotifTextLink>
                 </Link>
                ): ( <Link to={'/client/' + client.id} className='client-text-link' >
                            <MotifTextLink variant='alt'>{client.name}</MotifTextLink>
                        </Link>
                       )}
                        
                        {client.discrepancyCount > 0 && <MotifBadge variant="alt" className="ml-1" value={client.discrepancyCount} data-testid="badgeDiscrepancyCount" /> || null}
                        {hasIncompleteSecuritySnapshot && <MotifTooltip
                        placement="auto"
                        trigger={<MotifBadge variant="alt" className="ml-1 incomplete-security-count-S-badge" value="S" data-testid="badgeIncompleteSecurityCount" /> || null}>
                        {<span style={{ whiteSpace: 'nowrap' }}>Client has incomplete securities in one or more REITs. Review needed.</span>}
                        </MotifTooltip>
            }
                    </div>
                }

                {(props.column.colId == "isActive" &&
                    <div>
                        <MotifCheckbox
                            id={`activate-client-${client.id}`}
                            name="activate-client"
                            aria-label="Activate Deactivate client checkbox"
                            checked={(client.isActive && "checked") || ""}
                            onChange={toggleActiveFlag} />
                    </div>
                )
                    || null
                }
        <DataStewardAssignmentRequiredModal
        showEngagementAdminForDataStewardAssignmentModal={showEngagementAdminForDataStewardAssignmentModal}
        toggleShowEngagementAdminForDataStewardAssignmentModal={toggleShowEngagementAdminForDataStewardAssignmentModal}
        dataStewardAssignmentWarningHeader={Constants.dataStewardAssignmentWarningHeader}
        dataStewardAssignmentWarningBody={Constants.dataStewardAssignmentWarningBody}
        />
    </div>
        );
    }
};
