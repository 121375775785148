import React, { useState } from "react";
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import * as formHelpers from "../../scripts/formHelpers";
import * as formValidators from "../../scripts/formValidators";
import { MotifButton } from '@ey-xd/motif-react';
import * as formParsers from "../../scripts/formParsers";
import * as Constants from "../../constants/other";


const MappingForm = ({ handleSubmit, handleCloseForm, initialValues, formPurpose, error, submitting, handleDismissErrorMessage, tbMode, change, lookupTypes }) => {
    
    const [selectedAttribute, setSelectedAttribute] = useState(initialValues.reitTestingAttributeCode);
    const [isReadOnly, setIsReadOnly] = useState(false);
    const[isSubAttributeHidden, setisSubAttributeHidden] = useState(initialValues.reitTestingAttributeCode ==Constants.SUB_ATTRIBUTE ?

    true : false
     );

    const [isSubAttributeReadOnly, setisSubAttributeReadOnly] = useState(false);
  
    const handleDefaultSubAttributeFocus = () => {
        setIsReadOnly(true);
      };
   
    let tbType = "REIT";
    if (initialValues.propertyID && initialValues.propertyID > 0) {
        tbType = "Property";
    }
    if (tbMode === 2) {
        tbType = "";
    }

    let reitTestingAttributeLookupOptions = lookupTypes.reitTestingAttributes
    .filter(rta => rta.reitTestingAttributeID !== 0 && rta.reitTestingAttributeID !== initialValues.reitTestingAttributeID)
    .map(rta => {
        return { label: rta.reitTestingAttributeDescription, value: rta.reitTestingAttributeID }
});   

    function clearDropDownValueFromForm(dropdownName)
    { 
        change(dropdownName, '');
    }

    const conditionalRequiredSelect = () => (value) => { // Remove required validation for reitTestingAttribute if subAttribute is 'AO300'
        if (selectedAttribute != Constants.SUB_ATTRIBUTE) {
          return formValidators.requiredSelect(value);
        }        
      };

      function handleAttributeChange(selectedValue) {
        const selectedAttr = lookupTypes.reitTestingAttributes.find(rta => rta.reitTestingAttributeID == parseInt(selectedValue))?.reitTestingAttributeCode;
    
        setSelectedAttribute(selectedAttr);
    
        if (selectedAttr !== Constants.SUB_ATTRIBUTE && initialValues.reitTestingAttributeCode == Constants.SUB_ATTRIBUTE) {
            setisSubAttributeReadOnly(true);
        } else if (selectedAttr !== Constants.SUB_ATTRIBUTE && initialValues.reitTestingAttributeCode != Constants.SUB_ATTRIBUTE) {
            setisSubAttributeHidden(false);
        } else {
            if (selectedAttr === Constants.SUB_ATTRIBUTE) {
                setisSubAttributeHidden(true);
            }
        }
    } 

    let formTitle = "";
    let selectionHeader = "Map to:";
    if (formPurpose === "EDIT") {
        formTitle = "Change " + tbType + " Mapping";
        selectionHeader = "Change Mapping to:";
    }

    return (        
        <form onSubmit={handleSubmit} className="mapping">
            {formHelpers.showSubmissionError(error, handleDismissErrorMessage)}
            <div className="row col-md-12 field">
                <div className="field col-md-6">
                    <label htmlFor="name">Client Account Number: </label>
                    {(formPurpose === "EDIT" && (
                        <div>{initialValues.clientAccountCode}</div>
                    )) || (
                        <Field
                            name="clientAccountCode"
                            component={formHelpers.renderField}
                            type="text"
                            placeholder="Client Account Number"
                            maxLength={50}
                            validate={[
                                formValidators.required,
                                formValidators.maxLength50,
                            ]}
                        />
                    )}
                </div>
                <div className="field col-md-6">
                    <label htmlFor="name">Client Account Name: </label>
                    {(formPurpose === "EDIT" && (
                        <div>{initialValues.clientAccountDescription}</div>
                    )) || (
                        <Field
                            name="clientAccountDescription"
                            component={formHelpers.renderField}
                            type="text"
                            placeholder="Client Account Name"
                            maxLength={50}
                            validate={[
                                formValidators.required,
                                formValidators.maxLength50,
                            ]}
                        />
                    )}
</div>
            </div>
            {formPurpose === "EDIT" && (
                <div className="row col-md-12 field">
                    <div className="field col-md-12">
                        <label htmlFor="name">
                            REIT Testing Attribute Description:{" "}
                        </label>
                        <div>
                            {initialValues.reitTestingAttributeCode +
                                " - " +
                                initialValues.reitTestingAttributeDescription}
                        </div>
                    </div>
                </div>
            )}

             {isSubAttributeHidden  && ( <div className="row col-md-12">
                    <div className="field col-md-12">                 
                     <Field
                                name="subAttribute"
                                label="Sub Attribute"
                                component={formHelpers.renderField}
                                type="text"
                                isRequiredField={true}
                                maxLength={19}
                                isSubAttribute={true}
                                disabled={isSubAttributeReadOnly}  
                                parse={formParsers.positiveWholeNumberParser} 
                                onChange={handleDefaultSubAttributeFocus}
                                validate={[formValidators.required, 
                                           formValidators.number, 
                                           formValidators.maxIntValue, 
                                           formValidators.greaterThan0]} 
                            
                            />                         
                    </div>
                </div>
               )}

            <div className="row col-md-12">
                <div className="field col-md-12">   
                                      
                    <Field
                        name="reitTestingAttribute"
                        label={selectionHeader}
                        component={formHelpers.SingleSelectComponent}
                        type="select"
                        aria-label="Change mapping to"
                        filter
                        options={reitTestingAttributeLookupOptions}
                        validate={[conditionalRequiredSelect()]}
                        optionValue={(opt) => opt.value}
                        optionText={(opt) => opt.label}
                        onChange={handleAttributeChange}                      
                        className="motif-option-custom" 
                        disabled={isReadOnly}                                                                   
                    />      
                </div>
            </div>          
        
            <div className="row action" style={{ marginTop: '80px' }}>
                <br />
                <div className="col-md-12 container">
                    <MotifButton
                        variant="primary"
                        className="right ml-2"
                        type="submit"
                        disabled={submitting}>
                        Save
                    </MotifButton>
                    <MotifButton
                        variant="secondary"
                        className="right"
                        type="button"
                        onClick={handleCloseForm}>
                        Cancel
                    </MotifButton>
                </div>
            </div>
        </form>
    );
};

MappingForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleCloseForm: PropTypes.func.isRequired,
    trs: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        periodId: PropTypes.number,
        name: PropTypes.string,
        debt: PropTypes.number,
        equity: PropTypes.number
    })),
    initialValues: PropTypes.object,
    error: PropTypes.string,
    submitting: PropTypes.bool,
    formPurpose: PropTypes.string.isRequired,
    handleDismissErrorMessage: PropTypes.func.isRequired,
    tbMode: PropTypes.number,
};

export default reduxForm({ form: "mapping", enableReinitialize: true })(MappingForm);
