import React, { useMemo, useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { AgGridReact } from 'ag-grid-react';
import CustomPagination from '../../common/MotifPagination';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import SecuritiesSnapshotCellRenderer from '../shared/customRenderers/SecuritiesSnapshotCellRenderer';
import { handleFilterChanged,handleFirstDataRendered,handleGridReady,tableHeaderClass,handleItemsPerPageChange } from '../../common/AgGridEventHandlers';
import CustomHeaderTemplate from "../checklistDeliveryCalendar/customHeaders/CustomHeaderTemplate.js";
import { MotifTable } from "@ey-xd/motif-react";
import CustomHeaderWithBadge from '../shared/customRenderers/CustomHeaderWithBadge';

const editSecuritiesSnapshotTable = ({ securitiesSnapshots, showForm, handleEditButtonClick, handleDeleteButtonClick, canEdit }) => {
    const gridRef = useRef();
    const [rowData, setRowData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(100);    
        //Default column settings
        const defaultColDef = useMemo(() => {
            return {
                flex: 1,
                minWidth: 65,
                filter: true,
                resizable: true,
                sortable: true,
                wrapText: true,
                autoHeight: true,
            };
        }, []);

        const FormattedCurrency = ({ value }) => {
            return Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
            }).format(value);
        };
        const shouldDisplayBadge = (params) => {            
            return !!(params.data.hasIncompleteSecuritySnapshot===true);
          };
        const getColumnDefs = () => {
            return [
                {
                    field: 'securityName', headerName: 'Security', sortable: true, showRowGroup: false, rowGroup: false, minWidth: 250,
                    cellRenderer: CustomHeaderWithBadge,                    
                    cellRendererParams: (params) => ({
                      displayName: params.data.securityName,
                      condition: shouldDisplayBadge(params),
                    }),
                    headerComponentParams: {template: CustomHeaderTemplate("securityName"),},
                },
                {
                    field: 'reitOrPropertyName', headerName: 'REIT or Property Name', sortable: true, showRowGroup: false, rowGroup: false, minWidth: 350,
                    headerComponentParams: {template: CustomHeaderTemplate("reitPropertyName"),},
                },
                {
                    field: 'clientAccountCode', headerName: 'Client Account Number', sortable: true, showRowGroup: false, rowGroup: false, minWidth: 250,
                    headerComponentParams: {template: CustomHeaderTemplate("clientAccountCode"),},
                },
                {
                    field: 'issuer', headerName: 'Issuer', sortable: true, showRowGroup: false, rowGroup: false, minWidth: 200,
                    headerComponentParams: {template: CustomHeaderTemplate("issuer"),},
                },
                {
                    field: 'class', headerName: 'Class', sortable: true, showRowGroup: false, rowGroup: false, minWidth: 200,
                    headerComponentParams: {template: CustomHeaderTemplate("class"),},
                               
                },
                {
                    field: 'reitOwnedSharesValue',
                    headerName: 'REIT Owned Shares Value',
                    sortable: true,
                    showRowGroup: false,
                    rowGroup: false,
                    minWidth: 250,
                    cellRenderer: SecuritiesSnapshotCellRenderer, cellRendererParams: { fieldName: 'reitOwnedSharesValue' },
                    headerComponentParams: {template: CustomHeaderTemplate("reitOwnedSharesValue"),},
                    valueGetter: (params) => {
                        return FormattedCurrency({ value: params.data.reitOwnedSharesValue });
                    }
                },
                {
                    field: 'allSharesFromIssuerValue',
                    headerName: 'All Shares from Issuer Value',
                    sortable: true,
                    showRowGroup: false,
                    rowGroup: false,
                    minWidth: 250,
                    headerComponentParams: {template: CustomHeaderTemplate("allSharesFromIssuerValue"),},
                    cellRenderer: SecuritiesSnapshotCellRenderer, cellRendererParams: { fieldName: 'allSharesFromIssuerValue'},                   
                    valueGetter: (params) => {
                        return params.data.isTenPercentValueTestDisabled === true ? "N/A" : FormattedCurrency({ value: params.data.allSharesFromIssuerValue });
                    }
                },
                {
                    field: 'percentOfVote',
                    headerName: 'Percent of Vote',
                    sortable: true,
                    showRowGroup: false,
                    rowGroup: false,
                    minWidth: 200,
                    headerComponentParams: {template: CustomHeaderTemplate("percentOfVote"),},
                    cellRenderer: SecuritiesSnapshotCellRenderer, cellRendererParams: { fieldName: 'percentOfVote'},
                    valueGetter: (params) => {
                        const percentage = params.data && params.data.percentOfVote;
                        return percentage
                          ? ((percentage * 1000.0) / 100000.0).toLocaleString(undefined, {
                              style: "percent",
                              minimumFractionDigits: 4,
                              maximumFractionDigits: 10,
                            })
                          : "";
                    },
                },
                {
                    field: 'action',
                    headerName: '',
                    suppressMenu: true,
                    showRowGroup: false,
                    minWidth: 150,
                    rowGroup: false,
                    cellRenderer: SecuritiesSnapshotCellRenderer, cellRendererParams: { fieldName: 'actions', canEdit,handleDeleteButtonClick,handleEditButtonClick}
                },
                
            ];
        };

        //Handle deselect all event
        const handleDeselectAll = (val) => {
            // set current page and total pages to 0
            setCurrentPage(0);    
            setTotalPages(0);      
        };
   
        //Handle Grid Page change event
        const handlePageChange = (val) => {
            setCurrentPage(val);
            gridRef.current.api.paginationGoToPage(val-1);
        };
    
        const [columnDefs, setColumnDefs] = useState(getColumnDefs());
        useEffect(() => {
            if(rowData.length > 0){
                let calcTotalPages = gridRef.current.api.paginationGetTotalPages();
                setTotalPages(calcTotalPages);
                //If total page is zero then set the current page as zero
                if (calcTotalPages == 0) {
                    setCurrentPage(calcTotalPages);
                }
            }
        }, [rowData]);
    
        useEffect(() => {
            let aggregatedSnapshots = securitiesSnapshots.reduce((acc, snapshot) => {
                const key = `${snapshot.securitiesSnapshotID}`;
                if (!acc[key]) {
                    acc[key] = { 
                        ...snapshot, 
                        clientAccountCode: '' 
                    };
                }
                acc[key].clientAccountCode = acc[key].clientAccountCode 
                    ? `${acc[key].clientAccountCode}; ${snapshot.clientAccountCode}` 
                    : snapshot.clientAccountCode;
                return acc;
            }, {});
                    
            const aggregatedSecuritiesSnapshots = Object.values(aggregatedSnapshots);
            setRowData(aggregatedSecuritiesSnapshots);
            if (gridRef && gridRef.current && gridRef.current.api) {
                
                // Get total pages from Grid's default pagination control and apply it to custom pagination control
                let calcTotalPages = gridRef.current.api.paginationGetTotalPages();
                setTotalPages(calcTotalPages);
            }
        }, [securitiesSnapshots]);

        const rowHeight = 76; 
        const headerHeight = 45; 
        const totalHeight = 5 * rowHeight + headerHeight; // set 5 rows as default
    
    return (
        <>
         {(securitiesSnapshots == null || securitiesSnapshots.length === 0) &&
                    <div className="mt-2">No Securities Snapshot found for this report period.</div>}
          {securitiesSnapshots && securitiesSnapshots.length > 0 &&
        <div>
        <div className="row mt-2">
        <div className="col">
            <div className="ag-theme-quartz motif-table table-bd-full-height-width">
                <MotifTable zebra={true} compact={true}
                    rowData={rowData}
                    columnDefs={getColumnDefs()}
                    defaultColDef={defaultColDef}
                    accentedSort={true}
                    rowSelection="multiple"
                    suppressRowClickSelection={true}
                    onGridReady={event => handleGridReady(event, gridRef,setCurrentPage,setItemsPerPage,setTotalPages,'REITSuiteSecuritySnapshotColumnState', handleDeselectAll)}
                    onFirstDataRendered={handleFirstDataRendered(gridRef)}
                    ref={gridRef}
                    pagination={true}
                    suppressPaginationPanel={true}
                    paginationPageSize={100}
                    groupDisplayType={'custom'}
                    groupSelectsChildren={true}
                    groupDefaultExpanded={0}
                    onFilterChanged={handleFilterChanged(gridRef, currentPage, setCurrentPage, setTotalPages)}
                    data-testid="divSecuritySnapShotListGrid"
                    deltaRowDataMode={true}        
                    getRowId={(data) => data.data.securitiesSnapshotID}  
                />
                <div className="mt-3">                 
                    <span className="right mb-3">
                    <CustomPagination
                        currentPage={currentPage}
                        handlePageChange={handlePageChange}
                        totalPages={totalPages}
                        handleItemsPerPageChange={handleItemsPerPageChange}
                        gridRef={gridRef}
                        setCurrentPage={setCurrentPage}
                        setItemsPerPage={setItemsPerPage}
                        setTotalPages={setTotalPages}/>
                    </span>
                </div>
            </div>
        </div>
    </div>
    
    </div>
}
    </>
    );
};

editSecuritiesSnapshotTable.propTypes = {
    securitiesSnapshots: PropTypes.arrayOf(PropTypes.shape({
        allSharesFromIssuerValue: PropTypes.number,
        class: PropTypes.string,
        securitiesSnapshotID: PropTypes.number,
        issuer: PropTypes.string,
        securityName: PropTypes.string,
        reitOwnedSharesValue: PropTypes.number,
        percentOfVote: PropTypes.number,
        reportPeriodID: PropTypes.number,
    })),
    showForm: PropTypes.bool,
    handleEditButtonClick: PropTypes.func,
    handleDeleteButtonClick: PropTypes.func,
    canEdit: PropTypes.bool
};

export default editSecuritiesSnapshotTable;