import React from 'react';
import PropTypes from 'prop-types';
import {
  MotifAccordion,
  MotifAccordionContent,
  MotifAccordionTrigger,
  MotifAccordionGroup,
  MotifIcon,
  MotifBadge,
} from '@ey-xd/motif-react';

import QuestionsComponent from './QuestionsComponent';
import { IconoirEditPencil, IconoirTrash } from '@ey-xd/motif-icon';

const SectionAccordionComponent = ({
  sections,
  handleDeleteClick,
  handleEditClick,
  handleAddNewChildQuestion,
  handleSectionQuestionDragStart,
  handleSectionQuestionDragOver,
  handleQuestionDropComponent,
  handleQuestionDropPanel,
  handleSectionDragStart,
  handleAvailableQuestionDragStart,
  handleSectionChildQuestionDragStart,
  handleSectionCommentDragStart,
  dropCategory,
  handleOpenAccordionOnTemplate,
  handleCloseAccordionOnTemplate,
  visibleQuestionTypes,
  isTemplateEditedByOtherUser,
  handleDownloadAttachmentClick
}) => {
  const onEditClick = (event, item, internalSectionId) => {
    event.stopPropagation();
    let itemToEdit = {
      ...item,
      internalSectionId,
      accordionCategory: 'section',
    };
    handleEditClick(itemToEdit);
  };

  const onDeleteClick = (event, item, internalSectionId) => {
    event.stopPropagation();
    let itemToDelete = {
      ...item,
      internalSectionId,
      accordionCategory: 'section',
    };
    handleDeleteClick(itemToDelete);
  };

  return (
    <MotifAccordionGroup>
      {sections.map((item, index) => (
        <div
          key={item.sectionID}
          onDragStart={(e) => handleSectionDragStart(e, item.sectionID)}
          draggable
          className="draggable"
          style={{ minWidth: '450px' }}
          onDrop={(e) => {
            handleQuestionDropComponent(e, 'section', item.sectionID);
          }}
        >
          <MotifAccordion
            name="questionAccordion"
            key={index}
            data-testid="accordionSection"
            open={item.isAccordionOpen}
            onOpen={(e) => {
              handleOpenAccordionOnTemplate(
                e,
                item.isAccordionOpen,
                item.sectionID
              );
            }}
            onClose={(e) => {
              handleCloseAccordionOnTemplate(
                e,
                item.isAccordionOpen,
                item.sectionID
              );
            }}
          >
            <MotifAccordionTrigger className={item.sectionHighlightClass}>
              <div style={{ display: 'flex', flexDirection: 'row', width:'100%'}}>
                <div
                  style={{ flex: '11', width: '0' }}
                  className="sectionNavigation"
                  title={item.sectionName}
                  //aria-live="polite"
                  role="alert"
                >
                  {index + 1}
                  {' - '}
                  {item.sectionName}
                </div>
                <div
                  className="ml-auto"
                  style={{
                    flex: '1',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <MotifBadge
                    value={item.questions.length}
                    data-testid="badgeNoOfQuestions"
                              />
                  {!isTemplateEditedByOtherUser && <React.Fragment>
                      <div className="pl-3" />
                      <MotifIcon
                          iconFunction={IconoirEditPencil} size='24' fill='none'
                          data-testid="btnEditIcon"
                          onClick={(e) => onEditClick(e, item, index)}
                          title="Edit this Section"
                      />
                      <div className="pl-3" />
                      <MotifIcon
                          iconFunction={IconoirTrash} size='24' fill='none'
                          data-testid="btnDeleteIcon"
                          onClick={(e) => onDeleteClick(e, item, index)}
                          title="Delete this Section"
                      /> </React.Fragment>}
                </div>
              </div>
            </MotifAccordionTrigger>
            <MotifAccordionContent>
              <QuestionsComponent
                questions={item.questions}
                internalSectionId={index}
                handleDeleteClick={handleDeleteClick}
                handleEditClick={handleEditClick}
                handleAddNewChildQuestion={handleAddNewChildQuestion}
                hasSection={true}
                handleSectionQuestionDragOver={handleSectionQuestionDragOver}
                handleQuestionDropPanel={handleQuestionDropPanel}
                handleSectionQuestionDragStart={handleSectionQuestionDragStart}
                handleQuestionDropComponent={handleQuestionDropComponent}
                handleAvailableQuestionDragStart={
                  handleAvailableQuestionDragStart
                }
                handleSectionChildQuestionDragStart={
                  handleSectionChildQuestionDragStart
                }
                handleSectionCommentDragStart={handleSectionCommentDragStart}
                handleDownloadAttachmentClick={handleDownloadAttachmentClick}
                dragSectionId={item.sectionID}
                dropCategory={dropCategory}
                handleOpenAccordion={(
                  e,
                  isAccordionOpen,
                  questionID,
                  parentQuestionID
                ) =>
                  handleOpenAccordionOnTemplate(
                    e,
                    isAccordionOpen,
                    item.sectionID,
                    questionID,
                    parentQuestionID
                  )
                }
                handleCloseAccordion={(
                  e,
                  isAccordionOpen,
                  questionID,
                  parentQuestionID
                ) =>
                  handleCloseAccordionOnTemplate(
                    e,
                    isAccordionOpen,
                    item.sectionID,
                    questionID,
                    parentQuestionID
                  )
                }
                visibleQuestionTypes={visibleQuestionTypes}
                isTemplateEditedByOtherUser={isTemplateEditedByOtherUser}
              />
            </MotifAccordionContent>
          </MotifAccordion>
        </div>
      ))}
    </MotifAccordionGroup>
  );
};

SectionAccordionComponent.propTypes = {
  sections: PropTypes.array,
  handleDeleteClick: PropTypes.func,
  handleEditClick: PropTypes.func,
  handleAddNewChildQuestion: PropTypes.func,
  handleOpenAccordionOnTemplate: PropTypes.func,
  handleCloseAccordionOnTemplate: PropTypes.func,
  handleSectionQuestionDragStart: PropTypes.func,
  handleSectionQuestionDragOver: PropTypes.func,
  handleQuestionDropComponent: PropTypes.func,
  handleQuestionDropPanel: PropTypes.func,
  handleSectionDragStart: PropTypes.func,
  handleAvailableQuestionDragStart: PropTypes.func,
  handleSectionChildQuestionDragStart: PropTypes.func,
  handleSectionCommentDragStart: PropTypes.func,
  dropCategory: PropTypes.string,
  visibleQuestionTypes: PropTypes.array,
  handleDownloadAttachmentClick:PropTypes.func
};

export default SectionAccordionComponent;
