// userAuditActions.js
import { fetchFromAuthApi as fetch } from "./fetchFromApi";

// Action Types
export const LOG_USER_AUDIT_TRAIL = 'LOG_USER_AUDIT_TRAIL';

/**
 * Log user audit trail event.
 * @param {Object} auditData The data to be logged.
 * @returns {Function} A function that returns a Promise.
 */
export function logUserAuditTrail(auditData) {
    return function (dispatch) {
        // Construct the URL for the audit trail endpoint
        const url = '/userAuditTrail/logLoginEvent';

        // Prepare the request options
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(auditData)
        };

        // Perform the fetch operation
        return fetch(url, requestOptions)
            .then(response => {
                if (!response.ok) {
                    // Handle non-2xx responses if necessary, without dispatching an error
                    console.error('Failed to log audit trail:', response.statusText);
                }
                return response.json();
            })
            .catch(error => {
                // Handle errors silently or log them without dispatching an error action
                console.error('Error logging audit trail:', error);
            });
    };
}
