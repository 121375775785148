import React from 'react';
import PropTypes from 'prop-types';
import TestSummaryItem from './TestSummaryItem';
import VarianceDetailsTable from './VarianceDetailsTable';
import { MotifToggleSwitch } from '@ey-xd/motif-react';

const TestSummary = ({
  testSummary,
  testHeader,
  showDetails,
  handleShowDetails,
  varianceDetails,
  incompleteSecuritiesSnapshots,
}) => { 
  var testType = testHeader.split(" ")[0];   
  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <h2 className="pageheader">{testHeader}</h2>
        </div>
      </div>
      <div className="row">
        <section className="inline col-md-9">
          <div className="row">
            {testSummary.tests.map(test => (
              <TestSummaryItem
                key={test.testTitle}
                test={test}
                totalItems={testSummary.tests.length}
                incompleteSecuritiesSnapshots={incompleteSecuritiesSnapshots}
              />
            ))}
          </div>
        </section>
        <section className="col-md-3">
          <div>
            <h6 className="pageheader">Trial Balance Account Overview</h6>
            <div className="overview table-responsive">
              <VarianceDetailsTable
                testHeader={testHeader}
                varianceDetails={varianceDetails}
              />
            </div>
          </div>
        </section>
      </div>
      <div className="row">
        <div className="col-md-9" style={{ marginTop: '1em' }}>
          <div className="row">
            <div className="col-md-6 text-left">
              <span className="mr-3">Display {testType} Details:</span>
              <div
                className="switch-box"
                style={{
                  display: 'inline-block',
                  position: 'relative',
                  textAlign: 'left',
                }}
              >
                <MotifToggleSwitch
                  aria-label={`Display ${testType} Details toggle`}
                  name="switchOff"
                  id="switchOff"
                  onChange={handleShowDetails}
                  checked={showDetails}
                />
              </div>
            </div>
            <div className="col-md-3 text-center" />
            <div className="col-md-3" />
          </div>
        </div>
      </div>
    </div>
  );
};

const clientAccountDataShape = PropTypes.shape({
  balance: PropTypes.number,
  clientAccountDescription: PropTypes.string,
  clientAccountNumber: PropTypes.string,
  internalAccountCode: PropTypes.string,
  internalAccountDescription: PropTypes.string,
  type: PropTypes.number,
});

TestSummary.propTypes = {
  testSummary: PropTypes.shape({
    tests: PropTypes.arrayOf(
      PropTypes.shape({
        testTitle: PropTypes.string,
        isPassing: PropTypes.bool,
        testResultPercent: PropTypes.number,
      }),
    ),
    newAccounts: PropTypes.number,
    deletedAccounts: PropTypes.number,
  }).isRequired,
  testHeader: PropTypes.string,
  handleShowDetails: PropTypes.func,
  varianceDetails: PropTypes.shape({
    addedAccounts: PropTypes.arrayOf(clientAccountDataShape),
    modifiedAccounts: PropTypes.arrayOf(
      PropTypes.shape({
        after: clientAccountDataShape,
        balanceDelta: PropTypes.number,
        balanceDeltaPercentage: PropTypes.number,
        before: clientAccountDataShape,
      }).isRequired,
    ),
    removedAccounts: PropTypes.arrayOf(clientAccountDataShape),
  }),
};

export default TestSummary;
