import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Icon, Progress, Button, Header } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as trialBalanceActions from "../../actions/trialBalanceActions";
import {
  MotifButton,
  MotifModal,
  MotifModalHeader,
  MotifModalBody,
  MotifModalFooter, 
  MotifFileUploader,
  MotifFormField,
  MotifTextLink,
  MotifIcon,
  MotifMessage
} from "@ey-xd/motif-react";
import { IconoirXmarkCircle, IconoirCheckCircle } from '@ey-xd/motif-icon';
import * as Constants from "../../constants/other";

const UploadStatusComponent = ({
  rejectedFiles,
  acceptedFiles,
  status,
  validationErrors,
}) => {
  return (
    <React.Fragment>
      {status == "Success" &&
        acceptedFiles.map((file, index) => {
          var acceptedfileNames = file.name;
          let msg =
            acceptedfileNames + " has been added to the queue for processing.";

          return (
            <div key={index} style={{ textAlign: "left" }}>            
                <div className="row">
                    <div className="col-md-1">
                        <div className="mt-1">
                            <MotifIcon iconFunction={IconoirCheckCircle} title="success" size='24' fill='none' stroke="#34c768" />
                        </div>
                    </div> 
                    <div className="col-md-11"> 
                        <div style={{ marginLeft:'-18px' }}> 
                                <MotifMessage className="motifLabelh3 upload-success-header ml-2 mb-5" >{msg}</MotifMessage>
                        </div>
                    </div>
                </div> 
            </div>
          );
        })}
      {status == "Error" && (
        <div style={{ textAlign: "left" }}>          
          <div className="row">
                <div className="col-md-1">
                    <div className="mt-1">
                        <MotifIcon iconFunction={IconoirXmarkCircle} title="error" size='24' fill='none' stroke="#ff4136" />
                    </div>
                </div> 
                <div className="col-md-11"> 
                    <div style={{ marginLeft:'-18px' }}> 
                            <MotifMessage className="motifLabelh3 upload-error-header" >Upload failed.</MotifMessage>
                    </div>
                </div>
        </div>
          {validationErrors.map((e, index) => (
            <div key={index} style={{ marginLeft: 37, fontSize: '14px' }}>
              {e.error}
            </div>
          ))}
        </div>
      )}
      {status == "Rejected" && (
        <div className="mb-2">          
          <div className="row">
            <div className="col-md-1">
                <div className="mt-1">
                    <MotifIcon iconFunction={IconoirXmarkCircle} title="error" size='24' fill='none' stroke="#ff4136" />
                </div>
            </div> 
            <div className="col-md-11"> 
                <div style={{ marginLeft:'-18px' }}> 
                        <MotifMessage className="motifLabelh3 upload-error-header" >Upload failed.</MotifMessage>
                </div>
            </div>
        </div>
          {rejectedFiles &&
            rejectedFiles.length > 0 &&
            rejectedFiles.map((f, index) => (
              <div key={index} className="row bg-danger text-white mr-3 ml-3">
                <div className="col-md-6">{f.file.name}</div>
                <div className="col-md-6">
                  {f.file.size > Constants.MAX_UPLOAD_FILE_SIZE_IN_BYTES ? (
                    <span>
                      The file size has exceeded max limit of{" "}
                      {Constants.MAX_UPLOAD_FILE_SIZE_IN_MB}MB
                    </span>
                  ) : (
                    <span>
                      Invalid file extension (Supports only XLSX format)
                    </span>
                  )}
                </div>
              </div>
            ))}
        </div>
      )}
      {status == "Uploading" && (
        <div>
          <MotifMessage className="motifLabelh3" >Uploading file(s)</MotifMessage>
        </div>
      )}
    </React.Fragment>
  );
};

/**
 * Container component used for uploading chart files
 */
export class AccountMappingsUploadModal extends React.Component {
  /**
   * Creates a new AccountMappingsUploadModal
   * @constructor
   * @param {Object} props The component properties
   * @param {Object} context The component context
   */
  constructor(props, context) {
    super(props, context);

    this.onDrop = this.onDrop.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      status: null,
      rejectedFiles: [],
      acceptedFiles: [],
      validationErrors: [],
      acceptedFileExtensions:{
        "application/vnd.ms-excel": [".xlsx"],
      },
    };

    this.acceptedFileExtensions = ".xlsx";
  }

  handleClose() {
    this.setState({
      status: null,
      rejectedFiles: [],
      acceptedFiles: [],
      validationErrors: [],
    });
    //this.props.handleChartSelection(this.props.chartOfAccountsFormValues.chartID);
    this.props.toggleShowUploadAccountMappingsModal();
  }

  onDrop(acceptedFiles, rejectedFiles) {
    if (Array.isArray(rejectedFiles) && rejectedFiles.length > 0) {
      this.setState({
        status: "Rejected",
        rejectedFiles,
        acceptedFiles: [],
        validationErrors: [],
      });
    } else if (Array.isArray(acceptedFiles)) {
      this.setState({
        status: "Uploading",
        rejectedFiles: [],
        acceptedFiles: acceptedFiles,
        validationErrors: [],
      });
      this.props.trialBalanceActions
        .uploadAccountMappings(
          this.props.clientId,
          this.props.periodId,
          acceptedFiles
        )
        .then((response) => {
          if (response && response.error) {
            //SERVER ERROR, BAD REQUEST
            this.setState({
              status: null,
              rejectedFiles: [],
              acceptedFiles: [],
              validationErrors: [],
            });
            this.props.toggleShowUploadAccountMappingsModal();
          } else if (
            response &&
            response.uploadChartValidationError &&
            Array.isArray(response.uploadChartValidationError) &&
            response.uploadChartValidationError.length > 0
          ) {
            this.setState({
              status: "Error",
              acceptedFiles: acceptedFiles,
              validationErrors: response.uploadChartValidationError,
            });
          } else {
            this.setState({
              status: "Success",
              acceptedFiles: acceptedFiles,
              validationErrors: [],
            });
          }
        });
    }
  }
  /**
   * Render a React element
   * @returns {Object} A reference to the component
   */
  render() {
    const HeaderWrapper = ({ children }) => (
      <div
        style={{
          display: "flex",
          alignItems: "start",
          justifyContent: "center",
        }}
      >
        {children}
      </div>
    );
    return (
      <MotifModal variant="alt" show={this.props.showUploadAccountMappingsModal}
      onClose={this.handleClose}>
     <MotifModalHeader closeButtonProps={this.props.handleClose}>
     <HeaderWrapper>
     <span className="ml-2">Upload Account Mappings</span>
     </HeaderWrapper>
     </MotifModalHeader>
     <MotifModalBody>
     <MotifFormField className="mt-6"> 
     <MotifFileUploader
         onDrop={this.onDrop}                
         label="Drop files here or click to upload"
         accept= {this.state.acceptedFileExtensions}
         maxSize={Constants.MAX_UPLOAD_FILE_SIZE_IN_BYTES}
         data-test-id="attachmentFileUpload"
         tabIndex="-1"/>                  
     </MotifFormField>
             <br />
             {
                 this.state.status != null && 
                     <UploadStatusComponent 
                         acceptedFiles={this.state.acceptedFiles}
                         rejectedFiles={this.state.rejectedFiles}
                         status={this.state.status}
                         validationErrors={this.state.validationErrors}
                     />
             }
     </MotifModalBody>
     <MotifModalFooter>
     <MotifButton variant="primary" className="right" type="button" 
     onClick={this.handleClose}>Close</MotifButton>
     </MotifModalFooter>
     </MotifModal>
    );
  }
}

AccountMappingsUploadModal.propTypes = {
  toggleShowUploadAccountMappingsModal: PropTypes.func.isRequired,
  showUploadAccountMappingsModal: PropTypes.bool.isRequired,
  authHeader: PropTypes.object,
  //chartOfAccountsFormValues: PropTypes.object
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @param {Object} ownProps The properties of the component
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
  return {};
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
  return {
    trialBalanceActions: bindActionCreators(trialBalanceActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountMappingsUploadModal);
