import React from 'react';
import { Link } from 'react-router-dom';
import { MotifBadge, MotifCheckbox, MotifTextLink, MotifTooltip } from '@ey-xd/motif-react';

export default (props) => {
  let hasIncompleteSecuritySnapshot;
  if (props.hasIncompleteSecuritySnapshotsList?.length > 0 && props.column.colId == "reitName") {
    hasIncompleteSecuritySnapshot = props?.hasIncompleteSecuritySnapshotsList.find(reitId => reitId.reitid === props.data.reitid)?.hasIncompleteSecuritySnapshot;
  }

  const reit = props.node.data;
  const rowIndex = props.node.rowIndex + 2;
  const toggleActiveFlag = (e) => {
    props.handleToggleActiveFlag(e, reit);
  }

  if (reit !== undefined) {
    return (
      <div>
        {(props.column.colId == "reitName") &&
          <div aria-label="Press ENTER to open this reit." aria-rowindex={rowIndex}>
            <Link to={'/client/' + reit.clients[0].id + '/reit/' + reit.reitid} className='client-text-link'>
              <MotifTextLink variant='alt'> {reit.reitName}</MotifTextLink>
            </Link>
            {reit.discrepancyCount > 0 && <MotifBadge variant="alt" className="ml-1" value={reit.discrepancyCount} data-testid="badgeDiscrepancyCount" /> || null}
            {hasIncompleteSecuritySnapshot && <MotifTooltip
              placement="auto"
              trigger={<MotifBadge variant="alt" className="ml-1 incomplete-security-count-S-badge" value="S" data-testid="badgeIncompleteSecurityCount" /> || null}>
              {<span style={{ whiteSpace: 'nowrap' }}>REIT has incomplete securities. Review needed.</span>}
            </MotifTooltip>
            }
          </div>
        }

        {(props.column.colId == "reportPeriodDescription") &&
          <div aria-label="Press ENTER to open this reit." aria-rowindex={rowIndex}>
            {reit.periods.length > 0 ? reit.periods[0].reportPeriodDescription : "Not Found"}
          </div>
        }

        {(props.column.colId == "reportPeriodStatusDescription") &&
          <div aria-label="Press ENTER to open this reit." aria-rowindex={rowIndex}>
            {reit.periods.length > 0 ? reit.periods[0].reportPeriodStatusDescription : "Not Found"}
          </div>
        }

        {(props.column.colId == "isActive" &&
          <div>
            <MotifCheckbox
              id={`activate-client-${reit.reitid}`}
              name="activate-reit"
              aria-label="Activate Deactivate reit checkbox"
              checked={(reit.isActive && "checked") || ""}
              onChange={toggleActiveFlag} />
          </div>
        )
          || null

        }
      </div>
    );
  }
};
