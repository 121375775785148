import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../actions/reitActions';
import * as authActions from '../../actions/authActions';
import * as periodActions from '../../actions/periodActions';
import * as commonActions from '../../actions/commonActions';
import * as SpecialChars from '../../constants/specialCharacters';
import * as coaMgrActions from '../../actions/chartOfAccountsManagerActions';
import * as trialBalanceActions from '../../actions/trialBalanceActions';
import ReitList from '../../components/reits/ReitList';
import YesNoModal from '../../components/shared/YesNoModal';
import * as securitiesSnapshotActions from '../../actions/securitiesSnapshotActions';
import * as actionHelpers from '../../scripts/actionHelpers';
import * as formHelpers from '../../scripts/formHelpers';
import { SubmissionError } from 'redux-form';

const mappingModalStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    height: 'auto', // <-- This sets the height
    overflow: 'visible', // <-- This tells the modal to scroll
  },
};

/**
 * ReitsPage container component
 * @extends {React.Component}
 */
export class ReitsPage extends React.Component {
  /**
   * Creates a new ReitsPage
   * @constructor
   * @param {Object} props The component properties
   * @param {Object} context The component context
   */
  constructor(props, context) {
    super(props, context);

    this.state = {
      reitSearchText: '',
      filteredReits: [],
      yesNoModalData: { showModal: false },
      showAddClientAcctAllReitsModal: false,
      showUploadMappingOnlyTBModal: false,
      eyStandardAccounts: [],
      showSuccessMessage: false,
      successMessageText: '',
      charts: [],
      currentReitCheckboxHtmlId: '',
      gridCurrentState: [],
      hasIncompleteSecuritySnapshotsList: []
    };
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleClearSearchText = this.handleClearSearchText.bind(this);
    this.handleShowInactive = this.handleShowInactive.bind(this);
    this.handleToggleActiveFlag = this.handleToggleActiveFlag.bind(this);
    this.handleReactivateReit = this.handleReactivateReit.bind(this);
    this.hideYesNoModal = this.hideYesNoModal.bind(this);
    this.handleDownloadIdList = this.handleDownloadIdList.bind(this);
    this.handleDownloadTbTemplates = this.handleDownloadTbTemplates.bind(this);
    this.handleSubmitMappingAllReits = this.handleSubmitMappingAllReits.bind(this);
    this.handleCloseForm = this.handleCloseForm.bind(this);
    this.handleCloseMappingOnlyTBModal = this.handleCloseMappingOnlyTBModal.bind(this,
    );
    this.handleDismissErrorMessage = this.handleDismissErrorMessage.bind(this);
    this.handleDismissSuccessMessage = this.handleDismissSuccessMessage.bind(
      this,
    );
    this.handleGridChanges = this.handleGridChanges.bind(this);
    this.handleGridColumnStateChange = this.handleGridColumnStateChange.bind(this);
    this.populateHasIncompleteSecuritySnapshots = this.populateHasIncompleteSecuritySnapshots.bind(this);
  }

  /**
   * Invoked immediately after a component mounts
   */
  componentDidMount() {
    if (this.props.clientId) {
      this.props.actions.fetchReits(this.props.clientId);
      this.props.coaMgrActions.fetchCharts(this.props.clientId);
      this.setFilter();
      this.resetGridColumnState();
      this.populateHasIncompleteSecuritySnapshots(this.props.clientId, null, null,'REIT');
    }
  }

  /**
   * Invoked before a mounted component receives new props.
   * @param {Object} nextProps The properties that the component is receiving
   */
  componentDidUpdate(prevProps) {
    if (prevProps.clientId !== this.props.clientId && this.props.clientId > 0) {
      this.props.actions.fetchReits(this.props.clientId);
      this.props.coaMgrActions.fetchCharts(this.props.clientId);
      this.populateHasIncompleteSecuritySnapshots(this.props.clientId, null, null,'REIT');
    }

    if (
      prevProps.reits !== this.props.reits &&
      this.props.reits &&
      prevProps.reits
    ) {
      const list = Object.assign([], this.props.reits);
      this.setState({ filteredReits: list });
    }

    if (prevProps.charts != this.props.charts) {
      this.setState({ charts: this.props.charts });
    }

    if (prevProps.chartOfAccounts != this.props.chartOfAccounts) {
      this.setState({
        eyStandardAccounts: this.createEyStandardAccountItems(
          this.props.chartOfAccounts,
        ),
      });
    }
  }

  getCurrentPeriod(reit) {
    return periodActions.getCurrentPeriod(reit);
  }

  handleSearchChange(e) {
    this.setState({ reitSearchText: e.target.value }, this.setFilter);
  }

  handleClearSearchText(e) {
    this.setState({ reitSearchText: '' }, this.setFilter);
  }

  handleShowInactive(e) {
    this.setState({ showInactive: !this.state.showInactive }, function () {
      this.props.actions.fetchReits(
        this.props.clientId,
        this.state.showInactive,
      ).then(reits => { this.setFilter(); return reits; });
    });
  }

  handleToggleActiveFlag(e, reit) {
    if (e.target.checked) {
      this.setState({
        yesNoModalData: {
          identifier: reit.reitid,
          question: `Are you sure you want to reactivate ${reit.reitName}?`,
          showModal: true,
        },
        currentReitCheckboxHtmlId: e.target.id
      });

      return;
    }

    this.props.actions.toggleActiveFlagAndReloadReits(
      this.props.clientId,
      reit.reitid,
    );
  }

  handleReactivateReit(reitId) {
    this.props.actions.toggleActiveFlagAndReloadReits(
      this.props.clientId,
      reitId,
    );
    this.setState({ yesNoModalData: { showModal: false } });
  }

  hideYesNoModal() {
    if (this.state.currentReitCheckboxHtmlId !== '') { // REIT that is being currently activated/deactivated
      document.getElementById(this.state.currentReitCheckboxHtmlId).checked = false; // Resetting the checked attribute to 'False' if the user decides not to activate the REIT
    }
    this.setState({ yesNoModalData: { showModal: false }, currentReitCheckboxHtmlId: '' });
  }

  setFilter() {
    if (this.state.reitSearchText === '') {
      this.setState({ filteredReits: Object.assign([], this.props.reits) });
    } else {
      this.setState({
        filteredReits: this.props.reits.filter(
          reit =>
            reit.reitName
              .toLowerCase()
              .includes(this.state.reitSearchText.toLowerCase()) ||
            reit.reitTrackingID
              .toLowerCase()
              .includes(this.state.reitSearchText.toLowerCase()),
        ),
      });
    }
  }

  handleDownloadIdList(clientId, showInactive, fileName) {
    this.props.commonActions.beginTask();
    return actions.downloadIdList(clientId, showInactive, fileName).then(blob => {
      this.props.commonActions.endTask();
      return blob;
    });
  }

  handleDownloadTbTemplates(clientId, showInactive) {
    return this.props.trialBalanceActions.downloadTbTemplates(clientId, showInactive).then((blob) => {
      return blob;
    });
  }

  populateHasIncompleteSecuritySnapshots(clientId, reitId, periodId,pageName) {
    this.props.securitiesSnapshotActions.getIncompleteSecuritySnapshotDetails(clientId, reitId, periodId,pageName).then((data) => {
      this.setState({ hasIncompleteSecuritySnapshotsList: data });
    });
  }

  handleSubmitMappingAllReits(values) {
    const mapping = {
      clientId: this.props.clientId,
      clientAccountCode: values.clientAccountCode,
      clientAccountDescription: values.clientAccountDescription,
      id: null,
      internalAccountCode:
        values.internalAccountCode && values.internalAccountCode.value,
      periodId: 0,
      propertyId: null,
    };

    //Remove Control characters e.g. tab, new line characters
    let clientAC = mapping.clientAccountCode ? mapping.clientAccountCode.replace(SpecialChars.SPECIAL_CHARACTER_CODES, "") : "";

    //Replace dashes (FIGURE DASH, EN DASH, EM DASH and HORIZONTAL BAR) with standard hyphen
    clientAC = clientAC ? clientAC.replace(SpecialChars.HYPHEN_TYPES, "-") : "";

    mapping.clientAccountCode = clientAC ? clientAC.trim() : "";

    this.props.trialBalanceActions
      .createMappingAllReits(mapping)
      .then(response => {
        if (!actionHelpers.isErrorResponse(response)) {
          let message = `Added mapping '${values.clientAccountCode} - ${values.clientAccountDescription
            } : ${values.internalAccountCode &&
            values.internalAccountCode.label}' to all ${this.props.client.name
            } REITs.`;
          this.setState({
            showSuccessMessage: true,
            successMessageText: message,
          });
        }

        this.handleCloseForm();
      })
      .catch(error => {
        if (error instanceof SubmissionError) {
          throw error;
        }

        formHelpers.generateSubmissionError();
      });
  }

  handleCloseForm() {
    this.setState({ showAddClientAcctAllReitsModal: false });
  }

  handleCloseMappingOnlyTBModal() {
    this.setState({ showUploadMappingOnlyTBModal: false });
  }

  handleDismissErrorMessage() {
    this.handleCloseForm();
  }

  createEyStandardAccountItems(eyStdAccounts) {
    let items = [];
    eyStdAccounts.map(x =>
      items.push({
        value: x.internalAccountCode,
        label: x.internalAccountCode + ' - ' + x.internalAccountDescription,
      }),
    );
    return items;
  }

  handleDismissSuccessMessage() {
    this.setState({ showSuccessMessage: false, successMessageText: '' });
  }
  /**
       * Handle Grid changes
       * @returns {gridRowData}
      */
  handleGridChanges(gridRowData, gridRefApi) {
    let rowData = [];
    rowData =
      gridRowData &&
      gridRowData.length &&
      gridRowData.map((x) => {
        return {
          name: x.name,
          reitTrackingID: x.reitTrackingID,
          reportPeriodDescription: x.reportPeriodDescription,
          reportPeriodStatusDescription: x.reportPeriodStatusDescription,
        };
      });

    this.setState({ gridCurrentState: rowData });

    if (gridRefApi) {
      gridRefApi.refreshCells();
    }
  }

  resetGridColumnState() {
    localStorage.removeItem('REITSuiteReitListColumnState');
  }

  /**
  * Handle Grid column state chagne
  
  * @returns {undefined}
  */
  handleGridColumnStateChange(columnState) {
    this.setState({ gridColumnState: columnState });
  }
  /**
   * Render a React element
   * @returns {Object} A reference to the component
   */
  render() {
    return (
      <React.Fragment>
        <ReitList
          clientId={this.props.clientId}
          client={this.props.client}
          reits={this.state.filteredReits}
          getCurrentPeriodFunction={this.getCurrentPeriod}
          handleSearchChange={this.handleSearchChange}
          handleClearSearchText={this.handleClearSearchText}
          reitSearchText={this.state.reitSearchText}
          handleShowInactive={this.handleShowInactive}
          showInactive={this.state.showInactive}
          canInactivateReits={this.props.canInactivateReits}
          handleToggleActiveFlag={this.handleToggleActiveFlag}
          handleDownloadIdList={this.handleDownloadIdList}
          handleDownloadTbTemplates={this.handleDownloadTbTemplates}
          showSuccessMessage={this.state.showSuccessMessage}
          handleDismissSuccessMessage={this.handleDismissSuccessMessage}
          successMessageText={this.state.successMessageText}
          chartExistsForClient={(this.state.charts && this.state.charts.length > 0)}
          handleGridChanges={this.handleGridChanges}
          handleGridColumnStateChange={this.handleGridColumnStateChange}
          hasIncompleteSecuritySnapshotsList={this.state.hasIncompleteSecuritySnapshotsList}
        />
        <YesNoModal
          showModal={this.state.yesNoModalData.showModal}
          handleYesAction={this.handleReactivateReit}
          handleNoAction={this.hideYesNoModal}
          identifier={this.state.yesNoModalData.identifier}
          title="Reactivate Inactive REIT"
          question={this.state.yesNoModalData.question}
        />
      </React.Fragment>
    );
  }
}

ReitsPage.propTypes = {
  clientId: PropTypes.number.isRequired,
  client: PropTypes.object.isRequired,
  reits: PropTypes.array,
  actions: PropTypes.object,
  commonActions: PropTypes.object,
  trialBalanceActions: PropTypes.object,
  coaMgrActions: PropTypes.object.isRequired,
  canInactivateReits: PropTypes.bool,
  charts: PropTypes.array,
  chartOfAccounts: PropTypes.array,
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @param {Object} ownProps The properties of the component
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
  const reits = state.reits;
  return {
    clientId: ownProps.clientId,
    reits: state.reits,
    currentUserAuthorizations: state.currentUserAuthorizations,
    chartOfAccounts: state.chartOfAccounts,
    charts: state.chartOfAccountsManager.charts,
    canInactivateReits:
      Array.isArray(state.currentUserAuthorizations) &&
      (authActions.isSystemAdministrator(state.currentUserAuthorizations) ||
        authActions.isEngagementAdministrator(
          state.currentUserAuthorizations,
          ownProps.clientId,
        )),
  };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
    coaMgrActions: bindActionCreators(coaMgrActions, dispatch),
    trialBalanceActions: bindActionCreators(trialBalanceActions, dispatch),
    securitiesSnapshotActions: bindActionCreators(securitiesSnapshotActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReitsPage);
