import React from 'react';
import { MotifBadge,MotifTooltip } from "@ey-xd/motif-react";

const CustomHeaderWithBadge = (props) => {
  const { displayName, condition } = props;
  
  return (
    <div className="d-flex align-items-center">
      <span>{displayName}</span>
      {condition && (
         <MotifTooltip
         trigger={
        <MotifBadge
          variant="alt"
          className="ml-1 incomplete-security-count-S-badge"
          value="S"
          data-testid="badgeSecurityForIncompleteSecurities"
        /> 
         }
         >
         This is an incomplete security. Review needed.
         </MotifTooltip>
       )} 
    </div>
  );
};

export default CustomHeaderWithBadge;