import PropTypes from "prop-types";
import React from "react";
import { Header, Label, Button, Input, Dropdown } from "semantic-ui-react";
import ReactModal from "react-modal";
import { Field, reduxForm } from "redux-form";
import * as formHelpers from "../../scripts/formHelpers";
import * as formValidators from "../../scripts/formValidators";
import * as formParsers from "../../scripts/formParsers";
import FormattedCurrency from "../shared/FormattedCurrency";
import TrialBalanceAdjustmentRow from "./TrialBalanceAdjustmentRow";
import { MoneyField } from "../shared/formFields";
import { MotifIcon, MotifButton, MotifDropdown, MotifDropdownItem, MotifSelect, MotifOption, MotifLabel, MotifFormField, MotifModal, MotifModalHeader, MotifModalBody, MotifModalFooter  } from '@ey-xd/motif-react';


const TrialBalanceAdjustmentsModal = ({ showTrialBalanceAdjustmentsModal, handleSubmit, cancelTrialBalanceAdjustmentsModal, selectedItem, selectedItemAdjustments,
    selectedItemPropertyName, doShowAdjustmentFormAdd, doShowAdjustmentFormEdit, showAdjustmentForm, disableInputs, trialBalanceItemSelect,
    adjustmentFormPurpose, doShowAdjustmentFormDelete, doCloseAdjustmentForm, doSubmitOfAdjustments, tbMode, initialValues }) => {
    let trialBalance = 0;
    let adjustment = 0;
    let itemAdjustments = [];
    
    if (selectedItemAdjustments.length > 0) {
        itemAdjustments = selectedItemAdjustments.filter(a => a.fromTrialBalanceItemId === selectedItem.id || a.toTrialBalanceItemId == selectedItem.id);
    }

    if (selectedItem && selectedItem.balance) {
        trialBalance = selectedItem.balance;
    }

    for (let i = 0; i < itemAdjustments.length; i++) {
        if (itemAdjustments[i].toTrialBalanceItemId === selectedItem.id) {
            //Reciprocal
            adjustment = adjustment - 1 * itemAdjustments[i].adjustment;
            continue;
        }

        adjustment = adjustment + 1 * itemAdjustments[i].adjustment;
    }

    let adjustedBalance = trialBalance - adjustment;
    let percentAdjustedBalance = adjustedBalance * selectedItem.percentageOfOwnership / 100;

    let previewText = "Preview Adjustment";
    let closeText = "Close";
    let formDisplayClass = 'displayBlock';
    let deleteDisplayClass = 'displayNone';
    let selectDisplayClass = 'displayBlock';
    let selectedTextDisplayClass = 'displayNone';
    if (adjustmentFormPurpose === 'DELETE') {
        previewText = "Yes";
        closeText = "No";
        formDisplayClass = 'displayNone';
        deleteDisplayClass = 'displayBlock';
    }
    if (adjustmentFormPurpose === 'EDIT') {
        selectDisplayClass = 'displayNone';
        selectedTextDisplayClass = 'displayBlock';
    }

    let selectedAdjustmentText = <b>{initialValues.toTrialBalanceItemAccountCode} - {initialValues.toTrialBalanceItemAccountDescription} - {initialValues.toTrialBalanceItemInternalAccountCode} : {initialValues.toTrialBalanceItemInternalAccountDescription}</b>;
    if (selectedItem.id === initialValues.toTrialBalanceItemId) {
        selectedAdjustmentText = <b>{initialValues.fromTrialBalanceItemAccountCode} - {initialValues.fromTrialBalanceItemAccountDescription} - {initialValues.fromTrialBalanceItemInternalAccountCode} : {initialValues.fromTrialBalanceItemInternalAccountDescription}</b>;
    }
    const HeaderWrapper = ({ children }) => (
        <div
            style={{
                display: 'flex',
                alignItems: 'start',
                justifyContent: 'center'
            }}
        >
            {children}
        </div>
    );

    return (
        <MotifModal     
        style={{ maxWidth: '75rem' }}
        variant="alt"
        show={showTrialBalanceAdjustmentsModal}
        onClose={cancelTrialBalanceAdjustmentsModal}
        data-testid="trailbalanceadjustmentsmodal"
      >
        <MotifModalHeader>
          <HeaderWrapper>
            <span className="ml-2">{tbMode === 1 && <React.Fragment>Adjustment to {selectedItemPropertyName} Trial Balance </React.Fragment> || <React.Fragment>Adjustment to Consolidated Trial Balance</React.Fragment>}</span>
          </HeaderWrapper>
        </MotifModalHeader>
        <MotifModalBody>
        {true &&
                <div>
                <table>
                    <thead>
                        <tr>
                            <th style={{ whiteSpace: 'nowrap' }}>From Account</th>
                            <th>&nbsp;</th>
                            <th style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>Trial Balance</th>
                            <th style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>Adjustment</th>
                            <th style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>Adjusted Balance</th>
                            <th style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>{selectedItem.percentageOfOwnership ? formParsers.percentageOfOwnership(selectedItem.percentageOfOwnership.toString()) : ""}% Ownership</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                        <tbody>                            
                            <tr>
                                <td><b>{selectedItem.clientAccountCode} - {selectedItem.clientAccountDescription}</b></td>
                                <td><b>{selectedItem.internalAccountCode} : {selectedItem.internalAccountDescription}</b>
                                   <br />
                                       {selectedItem.subAttribute > 0 && <b>Sub Attribute - {selectedItem.subAttribute}</b>} 
                                </td>
                                <td style={{ whiteSpace: 'nowrap', textAlign: 'right' }}><FormattedCurrency value={trialBalance} currency="USD" minimumFractionDigits={0} maximumFractionDigits={0} /></td>
                                <td style={{ whiteSpace: 'nowrap', textAlign: 'right' }}><FormattedCurrency value={-adjustment} currency="USD" minimumFractionDigits={0} maximumFractionDigits={0} /></td>
                                <td style={{ whiteSpace: 'nowrap', textAlign: 'right' }}><FormattedCurrency value={adjustedBalance} currency="USD" minimumFractionDigits={0} maximumFractionDigits={0} /></td>
                                <td style={{ whiteSpace: 'nowrap', textAlign: 'right' }}><FormattedCurrency value={percentAdjustedBalance} currency="USD" minimumFractionDigits={0} maximumFractionDigits={0} /></td>
                                <td>&nbsp;</td>
                            </tr>
                            {itemAdjustments.map(item => <TrialBalanceAdjustmentRow className="adjustment" key={item.id} item={item} ownership={selectedItem.percentageOfOwnership}
                                selectedItemId={selectedItem.id} doShowAdjustmentFormEdit={doShowAdjustmentFormEdit} doShowAdjustmentFormDelete={doShowAdjustmentFormDelete}
                                disableButtons={showAdjustmentForm} itemAdjustmentId={initialValues.id * 1} />)}
                        </tbody>
                    </table>
                    <div className="field col-md-12">&nbsp;</div>

                    {!showAdjustmentForm &&
                        <div className="col-md-12">                    
                            <MotifButton variant="secondary" type="submit"
                                onClick={doShowAdjustmentFormAdd}>Add Adjustment</MotifButton>
                        </div> || null}
                    <br />
                </div>}
            {showAdjustmentForm &&
                <div className="col-md-12" style={{ border: '1px solid black', textAlign: 'center' }}>
                    <b>{adjustmentFormPurpose} ADJUSTMENT</b>
                    <form onSubmit={handleSubmit}>
                        <div className={formDisplayClass}>
                            <div className="row text-left">
                                <div className="field col-md-9">
                                    <div className={selectDisplayClass}>
                                             <Field
                                                    id="customField"
                                                    name="toTrialBalanceItemId"
                                                    component={formHelpers.SingleSelectComponent}
                                                    type="select"
                                                    filter
                                                    label={adjustmentFormPurpose === 'ADD'?"To Account:":''}
                                                    validate={[formValidators.requiredSelect]}
                                                    disabled={disableInputs || adjustmentFormPurpose === 'EDIT'}
                                                    options={trialBalanceItemSelect}
                                                    optionValue={(opt) => opt.value}
                                                    optionText={(opt) => opt.label}
                                                    className="add-adjustment-label"
                                             />
                                    </div>
                                    <div className={selectedTextDisplayClass} style={{ marginLeft: '10px' }}>
                                        {selectedAdjustmentText}
                                    </div>
                                </div>

                                <div className="field col-md-3">
                                    <label htmlFor="name">{adjustmentFormPurpose !== 'ADD' && <React.Fragment>Adjustment</React.Fragment>} Amount</label>
                                    <MoneyField inputClassName="motif-input-has-not-label" name="adjustment" disabled={disableInputs} placeholder="0.00" maxLength={14} validate={[formValidators.number, formValidators.required]} />
                                </div>
                            </div>
                            <div className="row text-left">
                                <div className="field col-md-12">
                                    <label htmlFor="name">Reason for Adjustment</label>
                                    <Field name="reason" disabled={disableInputs} component={formHelpers.renderField} aria-label="Reason for Adjustment" maxLength={4000} validate={[formValidators.maxLength4000]}/>
                                </div>
                            </div>
                        </div>
                        <div className={deleteDisplayClass}>
                            <p>Delete selected adjustment?</p>
                        </div>
                        <div className="field col d-flex justify-content-end"> 
                        <MotifButton variant="secondary" className="right"
                                onClick={doCloseAdjustmentForm} 
                            type="button">{closeText}</MotifButton>
                        <MotifButton variant="primary" className="right ml-2"
                            type="submit">{previewText}</MotifButton>  
                        </div>

                    </form>
                </div>
                ||
                <div className="field col d-flex justify-content-end" style={{ paddingTop: '15px', marginBottom: '0' }}>
                    <MotifButton variant="secondary" className="right"
                        onClick={cancelTrialBalanceAdjustmentsModal}
                    type="button">Cancel</MotifButton>
                    
                    <MotifButton variant="primary" className="right ml-2"
                        onClick={doSubmitOfAdjustments} 
                        type="submit">Submit</MotifButton>
                </div>

            }
            </MotifModalBody>
            </MotifModal>
    );
};

TrialBalanceAdjustmentsModal.propTypes = {
    showTrialBalanceAdjustmentsModal: PropTypes.bool,
    handleSubmit: PropTypes.func,
    cancelTrialBalanceAdjustmentsModal: PropTypes.func,
    initialValues: PropTypes.object,
    selectedItem: PropTypes.object,
    selectedItemAdjustments: PropTypes.array,
    selectedItemPropertyName: PropTypes.string,
    doShowAdjustmentFormAdd: PropTypes.func,
    doShowAdjustmentFormEdit: PropTypes.func,
    showAdjustmentForm: PropTypes.bool,
    disableButtons: PropTypes.bool,
    disableInputs: PropTypes.bool,
    trialBalanceItemSelect: PropTypes.array,
    adjustmentFormPurpose: PropTypes.string,
    doShowAdjustmentFormDelete: PropTypes.func,
    doCloseAdjustmentForm: PropTypes.func,
    doSubmitOfAdjustments: PropTypes.func,
    tbMode: PropTypes.number
};

export default reduxForm({ form: "addAdjustment", enableReinitialize: true })(TrialBalanceAdjustmentsModal);