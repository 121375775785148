import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import {
  PublicClientApplication,
  EventType,
} from '@azure/msal-browser';
import * as actions from '../actions/actionTypes';
import { msalConfig, loginRequest } from '../auth/authConfig';
import { CustomNavigationClient } from '../auth/NavigationClient';
import { store } from '../store/configureStore';
import * as authenticationActions from '../actions/authenticationActions';

function handleResponse(response, msalInstance) {
  //console.log('RESPONSE:', response);
  if (response !== null) {
    msalInstance.setActiveAccount(response.account);
  } else {
    const currentAccounts = msalInstance.getAllAccounts();
    if (currentAccounts.length === 0) {
      // no accounts signed-in, attempt to sign a user in
      msalInstance.loginRedirect(loginRequest).then((res) => {
        if (res) {
          msalInstance.setActiveAccount(res.account);
        }
      });

    } else if (currentAccounts.length > 0) {
      msalInstance.setActiveAccount(currentAccounts[0]);
      //console.log('xx:', msalInstance.getActiveAccount())
    }
  }
  return true;
}

const ConditionalMsalProvider = ({ children }) => {
  const location = useLocation();

  // Only include MsalProvider if the route is not /external
  if (location.pathname.startsWith('/external') && !location.pathname.startsWith('/external/logoff')) {
    return <>{children}</>;
  } else {
    const pca = useMemo(() => {
      const msalInstance = new PublicClientApplication(msalConfig);
      const navigationClient = new CustomNavigationClient();

      msalInstance.setNavigationClient(navigationClient);

      msalInstance.addEventCallback(message => {
        if (message.eventType === EventType.LOGIN_SUCCESS) {
          const { payload } = message;
          store.dispatch({ type: actions.SET_ID_TOKEN, idToken: payload.idToken });
          store.dispatch(authenticationActions.setCurrentUser(payload.idTokenClaims));
        }
      });

      msalInstance.handleRedirectPromise().then((res) => handleResponse(res, msalInstance));

      window.msalInstanceRef = msalInstance;

      return msalInstance;
    }, []); // Empty dependency array ensures pca is only created once

    return <MsalProvider instance={pca}>{children}</MsalProvider>;
  }
};

export default ConditionalMsalProvider;
