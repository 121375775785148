import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { IntlProvider } from 'react-intl';
import NavBar from './NavBar';
import Main from '../containers/Main';
import * as authActions from '../actions/authActions';
import * as bulkProcessManagementActions from '../actions/bulkProcessManagementActions';
import * as bulkRollForwardActions from '../actions/bulkRollForwardActions';
import * as notificationActions from '../actions/notificationActions';
import { store } from '../store/configureStore';
import * as authenticationActions from '../actions/authenticationActions';
import MaintainLoginModal from './MaintainLoginModal';
import * as actions from '../actions/actionTypes';
import { LicenseManager } from 'ag-grid-enterprise';
import axios from 'axios';
import * as signalR from '@microsoft/signalr';
import { MotifToast } from '@ey-xd/motif-react';
import { ToastContainer, toast, Slide } from 'react-toastify';
import ToastifyProgressBar from './notifications/ToastifyProgressBar';
import { withRouter } from '../common/withRouter';
import * as clientActions from '../actions/clientActions';
import * as featureManagementActions from '../actions/featureManagementActions';
import * as dataPurgeActions from '../actions/dataPurgeActions';
import {
    withMsal,
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
} from '@azure/msal-react';
import {
    InteractionStatus,
    InteractionRequiredAuthError,
} from '@azure/msal-browser';
import DataStewardAssignmentRequiredModal from '../components/dataStewardRoleManagement/dataStewardAssignmentRequiredModal';
import ReminderToSaveFilesOutsideOfREITSuiteModal from '../components/auth/ReminderToSaveFilesOutsideOfREITSuiteModal';
import UpcomingDataDeletionNoticeModal from '../components/auth/UpcomingDataDeletionNoticeModal';
import * as Constants from '../constants/other';
import { logUserAuditTrail } from '../actions/userAuditActions';

//Set AG Grid license key
LicenseManager.setLicenseKey(process.env.AG_GRID_UI_LICENSE);

/**
 * The App class.  This is the parent component for all routes.
 * @extends {React.Component}
 */
class App extends React.Component {
    /**
     * Creates a new App
     * @constructor
     * @param {Object} props The component properties
     * @param {Object} context The component context
     */
    constructor(props, context) {
        super(props, context);

        this.connection = {};
        this.queueItems = {
            pendingQueueItems: [],
            completedQueueItems: [],
        };
        this.toastSystem = React.createRef();
        this.processingToastNotification = null;
        this.lastPathname = '';
        this.establishedUser = false;
        this.fetchAuthorizations = false;
        // Indicates whether the operation of queue message in process
        this.isMessageProcessing = false;
        // Contains the messages in queue
        this.messageQueue = [];

        this.updateProcessingDataNotification =
            this.updateProcessingDataNotification.bind(this);
        this.getConnectionInfo = this.getConnectionInfo.bind(this);
        this.executeJoinClient = this.executeJoinClient.bind(this);
        this.executeAddToGroup = this.executeAddToGroup.bind(this);
        this.executeRemoveFromGroup = this.executeRemoveFromGroup.bind(this);
        this.handleJoinClient = this.handleJoinClient.bind(this);
        this.handleCompletedQueueItem = this.handleCompletedQueueItem.bind(this);
        this.handleResendChecklistCompletedQueueItem =
            this.handleResendChecklistCompletedQueueItem.bind(this);
        this.handleCompletedQueueBatch = this.handleCompletedQueueBatch.bind(this);
        this.handleAddNewQueueItems = this.handleAddNewQueueItems.bind(this);
        this.receiveCompletedQueueItem = this.receiveCompletedQueueItem.bind(this);
        this.receiveAddNewQueueItems = this.receiveAddNewQueueItems.bind(this);
        this.processNextMessage = this.processNextMessage.bind(this);

        this.handleCompletedBulkProcessForAllRequestsOfBatchGUID = this.handleCompletedBulkProcessForAllRequestsOfBatchGUID.bind(this);
        this.handleBulkProcessNotificationClose = this.handleBulkProcessNotificationClose.bind(this);

        this.handleCompletedBulkReportPackageStatus = this.handleCompletedBulkReportPackageStatus.bind(this);
        this.handleBulkReportPackageNotificationClose = this.handleBulkReportPackageNotificationClose.bind(this);
        this.handleBulkProcessStatusUpdate = this.handleBulkProcessStatusUpdate.bind(this);
        this.handleAddToGroup = this.handleAddToGroup.bind(this);
        this.handleRemoveFromGroup = this.handleRemoveFromGroup.bind(this);
        this.hideNotificationAndResetData =
            this.hideNotificationAndResetData.bind(this);
        this.toggleShowMaintainTemplateModal =
            this.toggleShowMaintainTemplateModal.bind(this);
        this.handleUserModalLogin = this.handleUserModalLogin.bind(this);
        this.handleUserLogOut = this.handleUserLogOut.bind(this);
        this.useIdToken = this.useIdToken.bind(this);
        this.toggleReminderToSaveFilesOutsideOfREITSuiteMsg = this.toggleReminderToSaveFilesOutsideOfREITSuiteMsg.bind(this);
        this.handleReminderToSaveFiles = this.handleReminderToSaveFiles.bind(this);
        this.toggleUpcomingDataDeletionNoticeMsg = this.toggleUpcomingDataDeletionNoticeMsg.bind(this);
        this.handleUpcomingDataDeletionNotice = this.handleUpcomingDataDeletionNotice.bind(this);
        this.documentTransferReminderDate = this.documentTransferReminderDate.bind(this);
        this.upcomingDataDeletionNoticeDate = this.upcomingDataDeletionNoticeDate.bind(this);
        this.handleUserPreferenceSelectedForReminderToSaveFiles = this.handleUserPreferenceSelectedForReminderToSaveFiles.bind(this);
        this.handleUserPreferenceSelectedForDataDeletionNotice = this.handleUserPreferenceSelectedForDataDeletionNotice.bind(this);
        this.handleUserAuthorizationUpdate = this.handleUserAuthorizationUpdate.bind(this);
        this.onFetchAuthorizationsForCurrentUser = this.onFetchAuthorizationsForCurrentUser.bind(this);
        this.createdRouterListener = false;
       
        this.state = {
            showMaintainLoginModal: false,
            message: 'Authentication in progress...',
            showEngagementAdminForDataStewardAssignmentModal: false,
            showUpcomingDataDeletionNoticeMsg: false,
            showReminderToSaveFilesOutsideOfREITSuiteMsg: false,
            reminderToSaveFilesAckChecked: false,
            upcomingDataDeletionckChecked: false,
        };       
    }

    async componentDidMount() {
        await this.useIdToken(false);

        if(this.fetchAuthorizations && this.props.currentUser){
            // Get upcoming data deletion notice details
            await this.props.dataPurgeActions.fetchUpcomingDataDeletionNoticeDetails(new Date().getFullYear());
        
            //Load all data configurations
            await this.props.dataPurgeActions.fetchDataPurgeConfigurationData();

            // Get user acknowledgement history details
            if (this.documentTransferReminderDate()) {
                await this.props.dataPurgeActions.fetchDataPurgeUserAcknowledgementHistory(this.props.currentUser, new Date().getFullYear(),Constants.REMINDER_TO_SAVE_FILE_OUTSIDE);
            }
            else if (this.upcomingDataDeletionNoticeDate()) {
                await this.props.dataPurgeActions.fetchDataPurgeUserAcknowledgementHistory(this.props.currentUser, new Date().getFullYear(), Constants.UPCOMING_DATA_DELETION_NOTICE);
            }               
                
            //Load all feature flags
            await this.props.featureManagementActions.loadFeatureFlags();
        }
    }

    componentDidUpdate(prevProps) {   
        if (!this.establishedUser && this.props.currentUser) {
            this.establishedUser = true;

            this.timer = null;

            // Token renewal modal toggle timeout
            this.idTokenRefreshTimer = setTimeout(() => {
                this.setState({
                    showMaintainLoginModal: true,
                });
            }, process.env.ACCESS_TOKEN_IDLE_MODAL_POPUP_MILLESECONDS);

            notificationActions.pollNotifications(
                store.dispatch,
                this.props.currentUser,
            );
        }

        if (this.props.router.location.pathname) {
            const pathname = location.pathname;
            if (pathname !== this.lastPathname) {
                this.lastPathname = pathname;
                const accounts = this.props.msalContext.instance.getAllAccounts();
                if (accounts[0]?.idTokenClaims.exp) {
                    // Look for page changes here to trigger that user is still "active"
                    const exp = accounts[0].idTokenClaims.exp;

                    let expDate = new Date(exp * 1000);
                    //expDate.setUTCSeconds(exp * 1000);

                    let curDate = new Date();

                    const diff = expDate.getTime() - curDate.getTime();

                    //console.log(`ROUTE CHANGED: Token Exp: ${expDate}. Diff: ${diff}. ACCESS_TOKEN_SILENT_REFRESH_MILLESECONDS: ${process.env.ACCESS_TOKEN_SILENT_REFRESH_MILLESECONDS}`)
                    if (diff < process.env.ACCESS_TOKEN_SILENT_REFRESH_MILLESECONDS) {
                        this.useIdToken(true).then(() => {
                            clearTimeout(this.idTokenRefreshTimer);
                            this.idTokenRefreshTimer = setTimeout(() => {
                                this.setState({
                                    showMaintainLoginModal: true,
                                });
                            }, process.env.ACCESS_TOKEN_IDLE_MODAL_POPUP_MILLESECONDS);
                        });
                    }
                }
            }
        }

        const isOnClientPage =
            this.props.router.location && this.props.router.location.pathname.includes('/client/');
        const wasOnClientPage =
            prevProps.router.location && prevProps.router.location.pathname.includes('/client/');

        //SignalR
        const previousClientId = prevProps.client ? prevProps.client.id : null;
        if (
            isOnClientPage &&
            this.props.client &&
            this.props.client.id &&
            this.props.client.id !== previousClientId
        ) {
            this.getConnectionInfo(this.props.client.id)
                .then((info) => {
                    // make compatible with old and new SignalRConnectionInfo
                    info.accessToken = info.accessToken || info.accessKey;
                    info.url = info.url || info.endpoint;

                    //data.ready = true;
                    var options = {
                        accessTokenFactory: () => info.accessToken,
                    };

                    this.connection = new signalR.HubConnectionBuilder()
                        .withUrl(info.url, options)
                        .withAutomaticReconnect()
                        .configureLogging(signalR.LogLevel.Error)
                        .build();

                    //Handle client events
                    this.connection.on('joinClient', this.handleJoinClient);
                    this.connection.on(
                        'completedQueueItem',
                        this.receiveCompletedQueueItem,
                    );
                    this.connection.on(
                        'completedResendChecklistQueueItem',
                        this.handleResendChecklistCompletedQueueItem,
                    );
                    this.connection.on(
                        'completedQueueBatch',
                        this.handleCompletedQueueBatch,
                    );
                    this.connection.on('completedBulkProcessForAllRequestsOfBatchGUID', this.handleCompletedBulkProcessForAllRequestsOfBatchGUID);
                    this.connection.on('completedBulkReportPackage', this.handleCompletedBulkReportPackageStatus);
                    this.connection.on('bulkProcessStatusUpdate', this.handleBulkProcessStatusUpdate);
                    this.connection.on('userAuthorizationUpdate', this.handleUserAuthorizationUpdate);
                    this.connection.on('addNewQueueItems', this.receiveAddNewQueueItems);
                    this.connection.on('addToGroup', this.handleAddToGroup);
                    this.connection.on('removeFromGroup', this.handleRemoveFromGroup);

                    // Handle SignalR disconnect
                    this.connection.onclose(() => {
                        this.hideNotificationAndResetData();
                    });

                    //Handle SignalR reconnect
                    this.connection.onreconnected((connectionId) => {
                        var reconnectConnectionId =
                            this.connection && this.connection.connectionId
                                ? this.connection.connectionId
                                : null;

                        this.executeJoinClient(connectionId);
                    });

                    //Start hub connection
                    this.connection
                        .start()
                        .then(() => {
                            var startConnectionId =
                                this.connection && this.connection.connectionId
                                    ? this.connection.connectionId
                                    : null;

                            this.executeJoinClient(startConnectionId);
                        })
                        .catch((e) => {
                            console.log('Error while establishing SignalR connection. ' + e);
                        });
                })
                .catch((e) => {
                    console.log('Error while establishing SignalR connection. ' + e);
                });
        } else if (!isOnClientPage && wasOnClientPage) {
            clearTimeout(this.processingToastNotificationTimeout);

            // Clear hub connection
            if (
                this.connection &&
                this.connection.state &&
                this.connection.state !== signalR.HubConnectionState.Disconnected
            ) {
                this.connection.stop();
            }
        } else {
            // alert('client is null, signalr disconnect');
        }
        // If user is logged in as external, but still on the "App" container, we need to route to external app.
        // This can happen if user manually navigates to an internal URL, then clicks that they are NOT an EY employee.
        if (this.props.authenticationScope === 'external') {
            this.props.router.navigate('/ext');
            return;
        }

        // Redirect unauthorized user to Access Denied Error page
        if (this.fetchAuthorizations && this.props.authenticationScope === 'internal'
            && this.props.currentUserAuthorizations && Array.isArray(this.props.currentUserAuthorizations)
            && this.props.currentUserAuthorizations.length > 0
            && !authActions.isSystemAdministrator(this.props.currentUserAuthorizations)) {

            // Though the user is on client page, the props.client or this.props.client.id could be null as the user
            // is not authorized to access that client,  
            // Also for authorized user, at this point the props.client details are not yet populated
            // hence get client id from url
            if (this.props.router.location && this.props.router.location.pathname.toLowerCase().indexOf("/client/") >= 0) {
                let clientPath = this.props.router.location.pathname.toLowerCase();
                clientPath = clientPath.replace('/client/', '');
                let clientId = -1;
                if (clientPath.indexOf("/") >= 0) {
                    clientId = parseInt(clientPath.substring(0, clientPath.indexOf("/") + 1));
                }
                else {
                    clientId = parseInt(clientPath);
                }
                if (clientId && clientId > 0) {
                    let path=this.props.router.location.pathname.toLowerCase();
                    // Redirect unauthorized user to Access Denied Error page                   
                    if (!authActions.isEngagementAdministrator(this.props.currentUserAuthorizations, clientId)
                        && !authActions.isUser(this.props.currentUserAuthorizations, clientId)) {
                        this.props.router.navigate(`/unauthorized`);
                        return;
                    }
                    //Redirect to Add Data Steward page if user is Engagement Admin and does not have Data Steward                    
                    else if (!authActions.isSystemAdministrator(this.props.currentUserAuthorizations) && authActions.isEngagementAdministrator(this.props.currentUserAuthorizations, this.props.client.id) && this.props.router.location && this.props.router.location.pathname !== `/client/${this.props.client.id}/dataStewardRoleManagement`) {
                        if (this.props.client && this.props.client.id && prevProps.client.id !== this.props.client.id) {
                            this.hasClientDatasteward(this.props.client.id)
                                .then(isLocked => {
                                    // Check if the client is locked for not having a data steward
                                    if (isLocked) {
                                        // If the client is locked, navigate to the data steward management page and show the modal
                                        this.props.router.navigate(`/client/${this.props.client.id}/dataStewardRoleManagement`);

                                    } 
                                     else if (path === `/client/${this.props.client.id}`) {
                                        // If the client is not locked, navigate to the client page
                                         this.props.router.navigate(`/client/${this.props.client.id}`);
                                     }                                     
                                })
                                .catch(error => {
                                    console.error('Error fetching client data:', error);
                                    return false;
                                });
                        }
                    }
                    //Display Data Steward Assignment required popup if Engagement user does not have Data Steward
                    else if (!authActions.isSystemAdministrator(this.props.currentUserAuthorizations) && authActions.isUser(this.props.currentUserAuthorizations, this.props.client.id)) {
                        // Check if the client is locked for not having a data steward
                        if (this.props.client && this.props.client.id && prevProps.client.id !== this.props.client.id) {
                            this.hasClientDatasteward(this.props.client.id)
                                .then(isLocked => {
                                    if (isLocked) {
                                        this.props.router.navigate("/");
                                        if (!this.state.showEngagementAdminForDataStewardAssignmentModal) {
                                            this.setState({
                                                showEngagementAdminForDataStewardAssignmentModal: true,
                                            });
                                        }
                                        return;

                                    }  else if (path === `/client/${this.props.client.id}`) {
                                        // If the client is not locked, navigate to the client page
                                        this.props.router.navigate(`/client/${this.props.client.id}`);
                                    }
                                })
                                .catch(error => {
                                    console.error('Error fetching client data:', error);
                                    return false;
                                });
                        }
                    }
                }
                else {
                    this.props.router.navigate(`/unauthorized`);
                    return;
                }
            }
        }

        // Show data purge notification message
        if (prevProps.dataPurgeUserAcknowledgementHistoryData !== this.props.dataPurgeUserAcknowledgementHistoryData
            && this.props.router.location && this.props.router.location.pathname === '/'
        ) {
            // The current user is valid, now check if the user has already acknowledged the reminder based on last record
            let lastHistoryItem = Array.isArray(this.props.dataPurgeUserAcknowledgementHistoryData) && this.props.dataPurgeUserAcknowledgementHistoryData.length > 0
                && this.props.dataPurgeUserAcknowledgementHistoryData[this.props.dataPurgeUserAcknowledgementHistoryData.length - 1];

            // Show reminder to save files outside of REITSuite message
            if (this.documentTransferReminderDate()) {
                // Additional check to see if the user has already acknowledged the reminder 
                if (lastHistoryItem && lastHistoryItem.isAcknowledgementReceived === true) {
                    this.setState({
                        showReminderToSaveFilesOutsideOfREITSuiteMsg: false,
                    });
                }
                else {
                    // Passed all validations to show the reminder to save files outside of REITSuite message
                    this.setState({
                        showReminderToSaveFilesOutsideOfREITSuiteMsg: true,
                    });
                }
            }
            else if (this.upcomingDataDeletionNoticeDate()) {
                // Additional check to see if the user has already acknowledged the reminder   
                if (lastHistoryItem && lastHistoryItem.isAcknowledgementReceived === true) {
                    this.setState({
                        showUpcomingDataDeletionNoticeMsg: false,
                    });
                }
                else {
                    // Passed all validations to show the upcoming data deletion notice message
                    this.setState({
                        showUpcomingDataDeletionNoticeMsg: true,
                    });
                }
            }
        }

        // if we have not already fetched the authorizations
        // and both idToken and currentUser are populated
        // then fetch authorizations
        if (
            !this.fetchAuthorizations &&
            this.props.idToken &&
            this.props.currentUser
        ) {
            this.fetchAuthorizations = true;

            // Fetch authorizations
            this.props.authActions
                .fetchAuthorizationsForCurrentUser(this.props.currentUser)
                .then((currentUserAuthorizations) => {
                    this.onFetchAuthorizationsForCurrentUser(currentUserAuthorizations);  
                })
                .catch((err) => {
                    console.log(err);
                    this.setState({ message: 'Failed to authorize. Please try again.' });
                });
        }
    }

    componentWillUnmount() {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }

        clearTimeout(this.processingToastNotificationTimeout);
        clearTimeout(this.idTokenRefreshTimer);
        // Clear hub connection
        if (
            this.connection &&
            this.connection.state &&
            this.connection.state !== signalR.HubConnectionState.Disconnected
        ) {
            this.connection.stop();
        }
    }

    async useIdToken(isSilentRefresh = false) {
        const { instance } = this.props.msalContext;
        const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

        while (this.props.msalContext.inProgress !== InteractionStatus.None) {
            await sleep(100);
        }

        const accounts = instance.getAllAccounts();

        if (accounts.length > 0) {
            const request = {
                scopes: ['user.read', 'openid', 'profile', 'offline_access'],
                account: accounts[0],
                forceRefresh: true,
            };

            const silentResponse = await instance
                .acquireTokenSilent(request)
                .then((response) => response)
                .catch(async (error) => {
                    if (error instanceof InteractionRequiredAuthError) {
                        return await instance.acquireTokenRedirect(request);
                    } else {
                        throw error;
                    }
                });

            await store.dispatch({
                type: actions.SET_ID_TOKEN,
                idToken: silentResponse.idToken,
            });

            await store.dispatch({
                type: actions.SET_ACCESS_TOKEN,
                accessToken: silentResponse.accessToken,
            });

            await this.props.authenticationActions.setCurrentUser(
                silentResponse.idTokenClaims,
            );

            const auditData = {
                email: silentResponse.idTokenClaims.email,
                isSilentRefresh: isSilentRefresh,
                pageUrl: window.location.href,
            };
            await store.dispatch(logUserAuditTrail(auditData));
        } else {
            const authRequest = {
                scopes: ['user.read', 'openid', 'profile', 'offline_access'],
                state: window.location.href,
            };

            instance.loginRedirect(authRequest);
        }
    }

    /*
     * SignalR methods
     */

    hideNotificationAndResetData() {
        try {
            if (this.processingToastNotification && toast.isActive(this.processingToastNotification)) {
                toast.dismiss(this.processingToastNotification);
            }
        } catch (err) {
            // do nothing
        } finally {
            this.processingToastNotification = null;

            // Reset items arrays
            this.queueItems.pendingQueueItems = [];
            this.queueItems.completedQueueItems = [];
        }
    }

    toggleShowMaintainTemplateModal() {
        this.setState({
            showMaintainLoginModal: !this.state.showMaintainLoginModal,
        });
    }

    toggleReminderToSaveFilesOutsideOfREITSuiteMsg() {
        this.setState({
            showReminderToSaveFilesOutsideOfREITSuiteMsg: !this.state.showReminderToSaveFilesOutsideOfREITSuiteMsg,
        });
    }

    handleReminderToSaveFiles() {
        if (this.state.reminderToSaveFilesAckChecked) {
            let request = {
                userId: this.props.currentUser,
                calendarYear: (new Date().getFullYear()),
                dataPurgeNotificationTypeDescription: Constants.REMINDER_TO_SAVE_FILE_OUTSIDE,
                isAcknowledgementReceived: true,
            };

            this.props.dataPurgeActions
                .createDataPurgeUserAcknowledgementHistory(request)
                .then(() => {
                    this.toggleReminderToSaveFilesOutsideOfREITSuiteMsg();
                });
        }
        else {
            this.toggleReminderToSaveFilesOutsideOfREITSuiteMsg();
        }
    }

    toggleUpcomingDataDeletionNoticeMsg() {
        this.setState({
            showUpcomingDataDeletionNoticeMsg: !this.state.showUpcomingDataDeletionNoticeMsg,
        });        
    }

    handleUpcomingDataDeletionNotice() {        
        if (this.state.upcomingDataDeletionckChecked) {
            let request = {
                userId: this.props.currentUser,
                calendarYear: (new Date().getFullYear()),
                dataPurgeNotificationTypeDescription: Constants.UPCOMING_DATA_DELETION_NOTICE,
                isAcknowledgementReceived: true,
            };

            this.props.dataPurgeActions
                .createDataPurgeUserAcknowledgementHistory(request)
                .then(() => {
                    this.toggleUpcomingDataDeletionNoticeMsg();
                });
        }
        else{
            this.toggleUpcomingDataDeletionNoticeMsg();
        }
    }

    handleUserPreferenceSelectedForReminderToSaveFiles() {
        this.setState({
            reminderToSaveFilesAckChecked: !this.state.reminderToSaveFilesAckChecked,
        });
    }

    handleUserPreferenceSelectedForDataDeletionNotice() {
        this.setState({
            upcomingDataDeletionckChecked: !this.state.upcomingDataDeletionckChecked,
        });
    }


    /**
    * Check today's date is between January 1st and March 15th of the current year.    
    * @returns {function} A function that returns a boolean value.
    */
    documentTransferReminderDate() {
        // Get start date
        let startDateFromConfig = this.props.dataPurgeConfigurationData.find(x => x.dataPurgeConfigurationParameter == Constants.REMINDER_START_DATE_PARAMETER)?.dataPurgeConfigurationValue;
        if(!startDateFromConfig || startDateFromConfig.length == 0){
            startDateFromConfig = Constants.DEFAULT_DOCUMENT_TRANSFER_REMINDER_START_DATE;
        }

        // Get end date
        let endDateFromConfig = this.props.dataPurgeConfigurationData.find(x => x.dataPurgeConfigurationParameter == Constants.REMINDER_END_DATE_PARAMETER)?.dataPurgeConfigurationValue;
        if(!endDateFromConfig || endDateFromConfig.length == 0){
            endDateFromConfig = Constants.DEFAULT_DOCUMENT_TRANSFER_REMINDER_END_DATE;
        }
        
        const currentYear = new Date().getFullYear();
        const startDateTime = new Date(`${currentYear}-${startDateFromConfig}`).getTime();        
        const endDateTime = new Date(`${currentYear}-${endDateFromConfig}`).getTime();
        const today = new Date().getTime();        
        return today >= startDateTime && today <= endDateTime;
    }

    /**
    * Check today's date is between October 1st and December 31st of the current year.    
    * @returns {function} A function that returns a boolean value.
    */
    upcomingDataDeletionNoticeDate() {      

        // Get start date
        let startDateFromConfig = this.props.dataPurgeConfigurationData.find(x => x.dataPurgeConfigurationParameter == Constants.UPCOMING_DATA_DELETION_NOTICE_START_DATE_PARAMETER)?.dataPurgeConfigurationValue;
        if(!startDateFromConfig || startDateFromConfig.length == 0){
            startDateFromConfig = Constants.DEFAULT_UPCOMING_DATA_DELETION_NOTICE_START_DATE;
        }

        // Get scheduled data purge date
        const scheduledDataPurgeDate = this.props.upcomingDataDeletionNoticeData && this.props.upcomingDataDeletionNoticeData.scheduledDataPurgeDate;

        // Get end date additional days
        let endDateAdditionalDaysFromConfig = this.props.dataPurgeConfigurationData.find(x => x.dataPurgeConfigurationParameter == Constants.UPCOMING_DATA_DELETION_NOTICE_END_DATE_PARAMETER)?.dataPurgeConfigurationValue;
        if(!endDateAdditionalDaysFromConfig || endDateAdditionalDaysFromConfig.length == 0){
            endDateAdditionalDaysFromConfig = Constants.DEFAULT_UPCOMING_DATA_DELETION_NOTICE_END_DATE_ADDNL_DAYS;
        }

        // Calculate end date
        const endDate = new Date(scheduledDataPurgeDate);
        endDate.setDate(endDate.getDate() + parseInt(endDateAdditionalDaysFromConfig));       
        
        const currentYear = new Date().getFullYear();
        const startDateTime = new Date(`${currentYear}-${startDateFromConfig}`).getTime();
        const endDateTime = endDate.getTime();
        const today = new Date().getTime();       
        return today >= startDateTime && today <= endDateTime;
    }

    handleUserModalLogin(modalExpired) {
        if (!modalExpired) {
            this.toggleShowMaintainTemplateModal();

            // Calling useIdToken, which will issue silent renew or redirect if needed
            this.useIdToken(true).then(() => {
                clearTimeout(this.idTokenRefreshTimer);
                this.idTokenRefreshTimer = setTimeout(() => {
                    this.setState({
                        showMaintainLoginModal: true,
                    });
                }, process.env.ACCESS_TOKEN_IDLE_MODAL_POPUP_MILLESECONDS);
            });
        } else {
            // We are expired. Reload entire page to login again.
            window.location.reload();
        }
    }

    handleUserLogOut() {
        this.props.router.navigate(`/external/logoff`);
    }

    // Execute SignalR negotiate method and get connection info
    getConnectionInfo(clientId) {
        // negotiate connection with clientId added to username
        // so that you can have individual connections to each client with multiple browser windows/tabs at the same time
        return axios
            .post(
                `${process.env.ROOT_REITSUITE_AZURE_FUNCTION_API_URL}/negotiate`,
                null,
                {
                    headers: {
                        'x-ms-signalr-userid': `${this.props.currentUserAuthorizations[0].userId}-${clientId}`,
                    },
                },
            )
            .then((resp) => resp.data);
    }

    // Join client
    executeJoinClient(hubConnectionId) {
        return axios
            .post(
                `${process.env.ROOT_REITSUITE_AZURE_FUNCTION_API_URL}/joinclient`,
                {
                    clientName: this.props.client.name,
                    clientId: this.props.client.id,
                    connectionId: hubConnectionId,
                    processType: 1,
                    userId: this.props.currentUserAuthorizations[0].userId,
                },
                {
                    headers: {},
                },
            )
            .then((resp) => resp);
    }

    // Add user id to group
    executeAddToGroup() {
        return axios
            .post(
                `${process.env.ROOT_REITSUITE_AZURE_FUNCTION_API_URL}/addToGroup`,
                {
                    clientName: this.props.client.name,
                    clientId: this.props.client.id,
                    userId: this.props.currentUserAuthorizations[0].userId,
                },
                {
                    headers: {
                        'x-ms-signalr-userid':
                            this.props.currentUserAuthorizations[0].userId,
                    },
                },
            )
            .then((resp) => resp)
            .catch((e) => {
                console.log('Error in adding user to SignalR group' + e);
            });
    }

    // Remove user id from group
    executeRemoveFromGroup(client) {
        return axios
            .post(
                `${process.env.ROOT_REITSUITE_AZURE_FUNCTION_API_URL}/removeFromGroup`,
                {
                    clientName: this.props.client.name,
                    clientId: this.props.client.id,
                    userId: this.props.currentUserAuthorizations[0].userId,
                },
                {
                    headers: {
                        'x-ms-signalr-userid':
                            this.props.currentUserAuthorizations[0].userId,
                    },
                },
            )
            .then((resp) => resp)
            .catch((e) => {
                console.log('Error in removing user from SignalR group' + e);
            });
    }

    // Handle client event of client has connected to hub
    handleJoinClient(message) {
        this.queueItems = message;

        this.updateProcessingDataNotification();
    }

    // Process message of type "completedBatchQueueItem"
    handleCompletedQueueItem(message, retry = false) {
        if (
            this.queueItems &&
            this.queueItems.pendingQueueItems &&
            message &&
            message.BatchQueueItemID
        ) {
            var removeIndex = this.queueItems.pendingQueueItems
                .map(function (item) {
                    return item.Id;
                })
                .indexOf(message.BatchQueueItemID);
            if (removeIndex != -1) {
                // remove queue item
                var removedItem = this.queueItems.pendingQueueItems.splice(
                    removeIndex,
                    1,
                );
                if (
                    removedItem &&
                    removedItem.length > 0 &&
                    this.queueItems.completedQueueItems
                ) {
                    let newQueueItem = removedItem[0];
                    newQueueItem.st = 'cq';
                    this.queueItems.completedQueueItems.push(newQueueItem);

                    this.updateProcessingDataNotification();
                    this.isMessageProcessing = false;
                    this.processNextMessage();                    
                }
            }
            else if( retry ){
                // Retry to get the queue item                
                this.updateProcessingDataNotification();
                this.isMessageProcessing = false;
                this.processNextMessage();
            }
            else{
                // Retry after a 3-second delay
                setTimeout(() => {
                    // Re-insert the message with retry flag
                    this.messageQueue.push({ type: "completedBatchQueueItem", message: message, retry: true }); 
                    
                    this.updateProcessingDataNotification();
                    this.isMessageProcessing = false;
                    this.processNextMessage(); 
                    
                }, 3000);
            }            
            
        }
    }

    // Handle client event of Resend Checklist queue item is completed
    handleResendChecklistCompletedQueueItem(clientId) {
        // Update the state on redux to notify user to refresh manage checklist page
        this.props.authActions.refreshManageChecklistClient(clientId);
    }

    // Handle client event of queue batch is completed
    handleCompletedQueueBatch(message) { }

    // Handle client event of bulk process for all requests of batch guid is completed 
    handleCompletedBulkProcessForAllRequestsOfBatchGUID(message) {

        if (message && message.ClientID && this.props.client && message.ClientID === this.props.client.id && this.toastSystem) {

            let toastVariant = 'info';
            let bulkProcessRequestStatusMsg = '';
            if (message.ActionType == "roll forwarding") {
                const rollForwarddetails = this.getRollForwardMessageForBottomCorner(message);
                toastVariant = rollForwarddetails.toastVariant;
                bulkProcessRequestStatusMsg = rollForwarddetails.bulkProcessRequestStatusMsg;
            }
            else {
                if (message.FailedRequestsCount === message.TotalRequestsCount) {
                    // All requests are failed
                    bulkProcessRequestStatusMsg = `Bulk updating the ${message.ActionType} is failed.`;
                    toastVariant = 'error';
                }
                else if (message.CompletedRequestsCount === message.TotalRequestsCount) {
                    // All requests are completed successfully
                    bulkProcessRequestStatusMsg = `Bulk updating the ${message.ActionType} is processed successfully.`;
                    toastVariant = 'success';
                }
                else if (message.FailedRequestsCount > 0 && message.NotApplicableRequestsCount > 0) {
                    // Alteast 1 request of failed type and not applicable type
                    bulkProcessRequestStatusMsg = `Bulk updating the ${message.ActionType} is completed. ${message.NotApplicableRequestsCount} REITs are not applicable for the selected action. Failed to update ${message.FailedRequestsCount} REITs.`;
                    toastVariant = 'info';
                }
                else if (message.FailedRequestsCount > 0 && message.NotApplicableRequestsCount == 0) {
                    // Alteast 1 request of failed type
                    bulkProcessRequestStatusMsg = `Bulk updating the ${message.ActionType} is completed. Failed to update ${message.FailedRequestsCount} REITs.`;
                    toastVariant = 'info';
                }
                else if (message.FailedRequestsCount == 0 && message.NotApplicableRequestsCount > 0) {
                    // Alteast 1 request of not applicable type
                    bulkProcessRequestStatusMsg = `Bulk updating the ${message.ActionType} is completed. ${message.NotApplicableRequestsCount} REITs are not applicable for the selected action.`;
                    toastVariant = 'info';
                }
            }

            var bulkProcessRequestStatusNotification = toast(
                (
                    <MotifToast position="right"
                        variant={toastVariant}
                        onClose={() => this.handleBulkProcessNotificationClose(bulkProcessRequestStatusNotification)}
                    >
                        <div>
                            <div className="row"><div className="mb-2 ml-2 font-weight-bold">Bulk Process Request Status</div></div>
                            <div className="row"><div className="ml-3 mr-2">
                                {bulkProcessRequestStatusMsg}
                            </div></div>
                        </div>
                    </MotifToast>
                )
                , {
                    autoClose: 5000,
                });
        }
    }

    getRollForwardMessageForBottomCorner(message) {
        let toastVariant = 'info';
        let bulkProcessRequestStatusMsg = '';
        if (message.FailedRequestsCount === message.TotalRequestsCount) {
            // All requests are failed
            bulkProcessRequestStatusMsg = `Bulk roll forwarding REITs is failed.`;
            toastVariant = 'error';
        }
        else if (message.CompletedRequestsCount === message.TotalRequestsCount) {
            // All requests are completed successfully
            bulkProcessRequestStatusMsg = `Bulk roll forwarding REITs is processed successfully.`;
            toastVariant = 'success';
        }
        else if (message.FailedRequestsCount > 0 && message.NotApplicableRequestsCount > 0) {
            // Alteast 1 request of failed type and not applicable type
            bulkProcessRequestStatusMsg = `Bulk roll forwarding REITs is completed. ${message.NotApplicableRequestsCount} REITs are not applicable for the selected action. Failed to roll forward ${message.FailedRequestsCount} REITs.`;
            toastVariant = 'info';
        }
        else if (message.FailedRequestsCount > 0 && message.NotApplicableRequestsCount == 0) {
            // Alteast 1 request of failed type
            bulkProcessRequestStatusMsg = `Bulk roll forwarding REITs is completed. Failed to roll forward ${message.FailedRequestsCount} REITs.`;
            toastVariant = 'info';
        }
        else if (message.FailedRequestsCount == 0 && message.NotApplicableRequestsCount > 0) {
            // Alteast 1 request of not applicable type
            bulkProcessRequestStatusMsg = `Bulk roll forwarding REITs is completed. ${message.NotApplicableRequestsCount} REITs are not applicable for the selected action.`;
            toastVariant = 'info';
        }

        const rollForwardMessage = {
            toastVariant: toastVariant,
            bulkProcessRequestStatusMsg: bulkProcessRequestStatusMsg
        }

        return rollForwardMessage;

    }


    // Handle close event of bulk process notification
    handleBulkProcessNotificationClose(bulkProcessRequestStatusNotification) {
        if (bulkProcessRequestStatusNotification && toast.isActive(bulkProcessRequestStatusNotification)) {
            toast.dismiss(bulkProcessRequestStatusNotification);
        }
    }   
   
    // Handle client event of authorization updated/deleted
    handleUserAuthorizationUpdate(message) {
        if (message && message.UserID && this.props.currentUser && this.props.currentUser === message.UserID) {                       
        this.props.authActions
                .fetchAuthorizationsForCurrentUser(this.props.currentUser)
                .then((currentUserAuthorizations) => {                    
                    this.onFetchAuthorizationsForCurrentUser(currentUserAuthorizations);
                })
                .catch((err) => {
                    console.log(err);
                    this.setState({ message: 'Failed to authorize. Please try again.' });
                });
        }        
    }

    // Handle client event of bulk report package process completed
    handleCompletedBulkReportPackageStatus(message) {
        // Send message to Redux, so the table data can be updated with new status
        store.dispatch({
            type: actions.RECEIVE_BULK_REPORT_PACKAGE_MESSAGE,
            message: message
        });

        const isCompletedOrFailed = message && message.BulkReportStatusDescription &&
            (message.BulkReportStatusDescription.toLowerCase() == 'completed' || message.BulkReportStatusDescription.toLowerCase() == 'failed');

        if (message && message.ClientID && this.props.client && message.ClientID === this.props.client.id
            && message.BulkReportStatusDescription && message.BulkReportStatusDescription.length > 0 && this.toastSystem && isCompletedOrFailed) {

            let packageStatusMsg = message.BulkReportStatusDescription.toLowerCase() == 'completed'
                ? `"${message.BulkReportPackageName}" is complete, and the extract is ready to download.`
                : `"${message.BulkReportPackageName}" is failed.`;

            if (packageStatusMsg.length > 0) {
                var bulkReportPackageStatusNotification = toast(
                    (
                        <MotifToast position="right"
                            variant={message.BulkReportStatusDescription.toLowerCase() == 'completed' ? 'success' : 'error'}
                            onClose={() => this.handleBulkReportPackageNotificationClose(bulkReportPackageStatusNotification)}
                        >
                            <div>
                                <div className="row"><div className="mb-2 ml-2 font-weight-bold">Bulk Report Package Status</div></div>
                                <div className="row"><div className="ml-3 mr-2">
                                    <span style={{ wordBreak: 'break-word' }}>  {packageStatusMsg} </span>
                                </div></div>
                            </div>
                        </MotifToast>
                    )
                    , {
                        autoClose: 5000,
                    });
            }
        }
    }

    handleBulkProcessStatusUpdate(message) {
        if (message.BulkProcessManagementCurrentStatusData && message.BulkProcessManagementCurrentStatusData !== null) {
            // Send message to Redux, so the bulk process management table data can be updated with new status
            this.props.bulkProcessManagementActions.dispatchBulkProcessData(message.BulkProcessManagementCurrentStatusData, actions.RECEIVE_BULK_PROCESS_STATUS_UPDATE_MESSAGE);
            this.props.bulkRollForwardActions.dispatchBulkRollForwardData(message.BulkProcessManagementCurrentStatusData, actions.RECEIVE_BULK_ROLL_FORWARD_STATUS_CONCURRENT_UPDATE_MESSAGE);
        }

        if (message.BulkRollForwardCurrentStatusData && message.BulkRollForwardCurrentStatusData !== null) {
            // Send message to Redux, so the bulk roll forward table data can be updated with new status
            this.props.bulkRollForwardActions.dispatchBulkRollForwardData(message.BulkRollForwardCurrentStatusData, actions.RECEIVE_BULK_ROLL_FORWARD_STATUS_UPDATE_MESSAGE);
            this.props.bulkProcessManagementActions.dispatchBulkProcessData(message.BulkRollForwardCurrentStatusData, actions.RECEIVE_BULK_PROCESS_STATUS_CONCURRENT_UPDATE_MESSAGE);
        }
    }



    // Handle close event of bulk report package notification
    handleBulkReportPackageNotificationClose(bulkReportPackageStatusNotification) {
        if (bulkReportPackageStatusNotification && toast.isActive(bulkReportPackageStatusNotification)) {
            toast.dismiss(bulkReportPackageStatusNotification);
        }
    }

    // Process next message in the queue
    processNextMessage() {
        if (!this.isMessageProcessing && this.messageQueue.length > 0) {
            this.isMessageProcessing = true;
            const nextMessage = this.messageQueue.shift();
            if (nextMessage.type === "addBatchQueueItem") {
                this.handleAddNewQueueItems(nextMessage?.message);
            } else if (nextMessage.type === "completedBatchQueueItem") {
                this.handleCompletedQueueItem(nextMessage?.message, nextMessage?.retry);
            }
        }
    }

    // Handle client event of adding new queue item
    receiveAddNewQueueItems(message) {        
        for (let msg of message) {
            this.messageQueue.push({ type: "addBatchQueueItem", message: msg });            
        }        
        
        this.processNextMessage();
    }

    // Handle client event of queue item is completed
    receiveCompletedQueueItem(message) {
        this.messageQueue.push({ type: "completedBatchQueueItem", message: message });
        this.processNextMessage();       
    }

    // Process message of type "addBatchQueueItem"
    handleAddNewQueueItems(message) {
        if (message) {
            let newItem = {};
            
            let itemFound = this.queueItems.pendingQueueItems.some(
                (el) => el.ID === message.BatchQueueItemID,
            );

            if (!itemFound) {
                newItem = {};
                newItem.Id = message.BatchQueueItemID;
                newItem.BatchId = message.BatchQueueID;
                newItem.ProcessTypeId = message.ProcessTypeID;
                newItem.st = 'aq';

                this.queueItems.pendingQueueItems.push(newItem);
            }

            this.updateProcessingDataNotification();

            this.isMessageProcessing = false;
            this.processNextMessage();
        }
    }

    handleAddToGroup(message) { }

    handleRemoveFromGroup(message) { }

    updateProcessingDataNotification() {
        clearTimeout(this.processingToastNotificationTimeout);
        
        if(this.queueItems.pendingQueueItems.length > 0 || this.queueItems.completedQueueItems.length > 0){ 
            // Calculate percentage
            const numOfPending = this.queueItems.pendingQueueItems.length;
            const numOfCompleted = this.queueItems.completedQueueItems.length;
            let percent =
                numOfCompleted === 0
                    ? 0
                    : Math.round((numOfCompleted / (numOfCompleted + numOfPending)) * 100);
            percent = percent > 100 ? 100 : percent;

            const autoDismiss = percent == 100;

            const progress = (percent / 100) === 0 ? 0.01 : (percent / 100);
            // Show progress bar when percent is greater than 0 
            if (this.toastSystem) {
                if (this.processingToastNotification && toast.isActive(this.processingToastNotification)) {
                    //Update toast
                    toast.update(this.processingToastNotification,
                        {
                            render: () => <ToastifyProgressBar percent={percent} />,
                            progress
                        });

                }
                else {
                    // Do not even show notification if it is 100%
                    const processingToastNotification = toast(
                        (
                            <ToastifyProgressBar percent={percent} />
                        )
                        , {
                            progress,
                        });

                    this.processingToastNotification = processingToastNotification;
                }

                if (autoDismiss) {
                    this.processingToastNotificationTimeout = setTimeout(() => {
                        this.hideNotificationAndResetData();
                    }, 3000);
                }
            }
        }
    }

    // Update status after fetching the authorizations details for current user
    onFetchAuthorizationsForCurrentUser(currentUserAuthorizations) {
        // If authorizations contain any External Permanent User role (role == 4)
        if (currentUserAuthorizations.some((x) => x.role === 4)) {
            this.props.authenticationActions.setAuthenticationScope('external');
        } else {
            this.props.authenticationActions.setAuthenticationScope('internal');
        }

        // If they have any authorizations, continue with redirect
        // else, they do not have authorization to the App
        if (currentUserAuthorizations.length > 0 && currentUserAuthorizations.some((x) => x.role !== 3)) {
            // If authorizations found, clear message
            this.setState({ message: null });
        } else {
            this.setState({
                message:
                    'You are not authorized to use this application. Please contact your Administrator to obtain access.',
            });
        }
    }

    hasClientDatasteward(clientId) {
        return clientActions.fetchClient(clientId)
            .then(client => client.isLockedForNotHavingDataSteward !== false)
            .catch(error => {
                console.error('Error fetching client data:', error);
                return false;
            });
    }
    toggleShowEngagementAdminForDataStewardAssignmentModal = () => {
        this.setState({
            showEngagementAdminForDataStewardAssignmentModal: !this.state.showEngagementAdminForDataStewardAssignmentModal,
        });

    };
    render() {
        let userId = this.props.currentUser;

        return (
            <IntlProvider locale="en">
                {this.state.message ? (
                    <p>{this.state.message}</p>
                ) : (
                    <React.Fragment>
                        {this.props.idToken ? (
                            <AuthenticatedTemplate>
                                <ToastContainer
                                    ref={this.toastSystem}
                                    position="bottom-right"
                                    closeOnClick={false}
                                    pauseOnHover={false}
                                    draggable={false}
                                    closeButton={false}
                                    hideProgressBar={true}
                                    transition={Slide}
                                />
                                <div className="container-fluid no-padding">
                                    <MaintainLoginModal
                                        showMaintainTemplateModal={
                                            this.state.showMaintainLoginModal
                                        }
                                        handleYes={this.handleUserModalLogin}
                                        handleNo={this.handleUserLogOut}
                                        toggleShowMaintainTemplateModal={
                                            this.toggleShowMaintainTemplateModal
                                        }
                                    />
                                    <div className="container-fluid no-padding">
                                        <ReminderToSaveFilesOutsideOfREITSuiteModal
                                            showReminderToSaveFilesOutsideOfREITSuiteMsg={
                                                this.state.showReminderToSaveFilesOutsideOfREITSuiteMsg
                                            }
                                            toggleReminderToSaveFilesOutsideOfREITSuiteMsg={
                                                this.toggleReminderToSaveFilesOutsideOfREITSuiteMsg
                                            }
                                            reminderToSaveFilesAckChecked={this.state.reminderToSaveFilesAckChecked}
                                            handleReminderToSaveFiles={this.handleReminderToSaveFiles}
                                            handleUserPreferenceSelectedForReminderToSaveFiles={this.handleUserPreferenceSelectedForReminderToSaveFiles}
                                        />
                                    </div>
                                    <div className="container-fluid no-padding">
                                        <UpcomingDataDeletionNoticeModal
                                            showUpcomingDataDeletionNoticeMsg={
                                                this.state.showUpcomingDataDeletionNoticeMsg
                                            }
                                            toggleUpcomingDataDeletionNoticeMsg={
                                                this.toggleUpcomingDataDeletionNoticeMsg
                                            }
                                            upcomingDataDeletionckChecked={this.state.upcomingDataDeletionckChecked}
                                            handleUpcomingDataDeletionNotice={this.handleUpcomingDataDeletionNotice}
                                            handleUserPreferenceSelectedForDataDeletionNotice={this.handleUserPreferenceSelectedForDataDeletionNotice}
                                            upcomingDataDeletionNoticeData={this.props.upcomingDataDeletionNoticeData}
                                        />
                                    </div>
                                    <NavBar />
                                    <Main userId={userId}><Outlet /></Main>
                                </div>
                            </AuthenticatedTemplate>
                        ) : (
                            <p>Authentication in progress...</p>
                        )}
                        <UnauthenticatedTemplate>
                            Authentication in progress...
                        </UnauthenticatedTemplate>
                        <DataStewardAssignmentRequiredModal
                            showEngagementAdminForDataStewardAssignmentModal={this.state.showEngagementAdminForDataStewardAssignmentModal}
                            toggleShowEngagementAdminForDataStewardAssignmentModal={this.toggleShowEngagementAdminForDataStewardAssignmentModal}
                            dataStewardAssignmentWarningHeader={Constants.dataStewardAssignmentWarningHeader}
                            dataStewardAssignmentWarningBody={Constants.dataStewardAssignmentWarningBody}
                        />
                    </React.Fragment>
                )}
            </IntlProvider>
        );
    }
}

App.propTypes = {
    authActions: PropTypes.object,
    bulkProcessManagementActions: PropTypes.object,
    bulkRollForwardActions: PropTypes.object,
    authenticationActions: PropTypes.object,
    authenticationScope: PropTypes.string,
    currentUserAuthorizations: PropTypes.array,
    client: PropTypes.object,
    idToken: PropTypes.string,
    router: PropTypes.object,
    clientActions: PropTypes.object,
    featureManagementActions:PropTypes.object,
    dataPurgeActions: PropTypes.object,
    dataPurgeUserAcknowledgementHistoryData: PropTypes.array,
    dataPurgeConfigurationData: PropTypes.array,
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @param {Object} ownProps The properties of the component
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {  
    return {
        authenticationScope: state.authenticationScope,
        currentUserAuthorizations: state.currentUserAuthorizations,
        client: state.client,
        currentUser: state.authentication.currentUser,
        idToken: state.authentication.idToken,
        dataPurgeUserAcknowledgementHistoryData: state.dataPurgeUserAcknowledgementHistoryData,
        upcomingDataDeletionNoticeData: state.upcomingDataDeletionNoticeData,
        dataPurgeConfigurationData: state.dataPurgeConfigurationData,
    };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        authActions: bindActionCreators(authActions, dispatch),
        bulkProcessManagementActions: bindActionCreators(bulkProcessManagementActions, dispatch),
        bulkRollForwardActions: bindActionCreators(bulkRollForwardActions, dispatch),
        authenticationActions: bindActionCreators(authenticationActions, dispatch),
        clientActions: bindActionCreators(clientActions, dispatch),
        dataPurgeActions: bindActionCreators(dataPurgeActions, dispatch),
        featureManagementActions: bindActionCreators(featureManagementActions, dispatch),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withMsal(App)));
