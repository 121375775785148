export default {
  authentication: {
    remainingTime: null,
    currentUser: null,
    idToken: null,
    accessToken: null,
    msalInstance: null,
  },
  pendingTasks: 0,
  customQuestionnaire: {
    templates: [],
    templateQuestions: [],
    customQuestionList: [],
  },
  //Here is where we are going to add more lookup types as required
  lookupTypes: {
    propertyTypes: [],
    psqPropertyTypes: [],
    propertyClasses: [],
    tenantTypes: [],
    propertyParkingTypes: [],
    reitTypes: [],
    organizationTypes: [],
    clientAccountCategories: [],
    reitTestingAttributes: [],
    prePopulateAnswerOptions: [],
  },
  customFdrTemplate: {
    templates: [],
    duplicate: false,
  },
  chartOfAccounts: [],
  chartMappings: [],
  chartOfAccountsManager: {
    charts: [],
    duplicate: false,
    duplicateClientAccount: false,
  },
  lastClickedTabChecklistQuestionId: null,
  activeArqcChecklist: null,
  previousArqcChecklist: null,
  activePsqChecklist: null,
  previousPsqChecklist: null,
  arqcChecklists: [],
  checklistFiles: [],
  allowUpdateReportPeriodVisibilityConstraintResult: {},
  checklistList: [],
  assetDetails: [],
  varianceDetails: {
    addedAccounts: [],
    modifiedAccounts: [],
    removedAccounts: [],
  },
  distributionDetails: [],
  distributionTaxableIncome: {
    reportPeriod: null,
    reportPeriodId: 0,
    value: 0,
  },
  incomeDetails: [],
  propertyDetails: null,
  deletePropertyDetail: {},
  propertyItsi: null,
  assetSummary: {
    tests: [
      {
        title: null,
        isPassing: false,
        percent: 0,
      },
    ],
    newAccounts: 0,
    deletedAccounts: 0,
  },
  distributionTestSummary: {
    tests: [
      {
        title: '90% Test',
        isPassing: false,
        percent: 0,
      },
    ],
    newAccounts: 0,
    deletedAccounts: 0,
  },
  incomeTestSummary: {
    tests: [
      {
        title: null,
        isPassing: false,
        percent: 0,
      },
    ],
    newAccounts: 0,
    deletedAccounts: 0,
  },
  files: null, // Return null instead of empty array.  Null checked in FileManagementPage
  reitFiles: null,
  testSnapshots: null,
  isAuthenticated: false,
  taxableReitSubsidiaries: [],
  clients: [],
  client: {},
  reitsForMO: [],
  reits: [],
  externalReits: [],
  reit: {},
  period: {},
  periods: [],
  properties: [],
  property: { purchaseDate: new Date('01/01/0001').toISOString(), purchasePrice: 0 }, // Year 0001 matches the current default in database
  psqChecklists: [],
  selectedReportItems: [],
  availableReportItems: [],
  securitiesSnapshots: [],
  errorResponse: null,
  dismissUsersMessage: false,
  services: [],
  navigationServices: [],
  lastTrialBalance: null,
  lastPropertyTrialBalance: null,
  lastConsolidatedTrialBalance: null,
  finalReport: null,
  trialBalanceSummary: null,
  trialBalanceItem: {},
  trialBalanceAdjustments: [],
  trialBalanceItems: [],
  isAuthorized: false,
  authorization: {},
  authorizations: [],
  currentUserAuthorizations: [],
  deletedChecklist: null,
  periodMappings: [],
  periodMappingsBalances: [],
  mapping: {},
  coaDgms: [],
  detailGroupMappings: [],
  trialBalanceStatus: null,
  notifications: [],
  showNotifications: false,
  showBulkUploadModal: false,
  uploadProgress: [],
  uploadQueue: [],
  uploadProcesses: 0,
  processingQueueCount: 0,
  checklistDeliveryCalendar: [],
  checklistCalendarYears: [],
  authenticationScope: null,
  psqTemplates: [],
  bulkReportPackages: {
      bulkReportPackages: [],
      toggleReloadBulkReportPackages: false
  },
  bulkReportPackagesETA: [],
  manageChecklists: [],
  extChecklists: [],
  externalChecklists: {
    clients: [],
    calendarYears: [],
  },
  refreshManageChecklistClientId: 0,
  selectedCustomPsqTemplate: {
    clientID: 0,
    templateID: 0,
    templateName: '',
    templateInitializationType: 'blankTemplate',
    copiedFromTemplateName: '',
    isCommitted: true,
    sections: [
      {
        sectionID: 0,
        sectionName: '',
        sectionSortOrder: 1,
        sectionIsLocked: false,
        questions: [
          {
            checklistTemplateSectionQuestionID: 0,
            questionID: 0,
            questionSortOrder: 1,
            parentQuestionID: null,
            questionText: '',
            questionHelpText: '',
            questionTag: '',
            questionTypeID: 1,
            questionTypeDescription: '',
            systemRuleJSON: null,
            childQuestionDisplayRule: {},
            sectionDisplayRuleJSON: null,
            isLocked: false,
            picklistItems: [
              {
                picklistItemName: ''
              }
            ],
            childQuestions: [
              {
                checklistTemplateSectionQuestionID: 0,
                questionID: 0,
                questionSortOrder: 1,
                parentQuestionID: null,
                questionText: '',
                questionHelpText: '',
                questionTag: '',
                questionTypeID: 1,
                questionTypeDescription: '',
                systemRuleJSON: null,
                childQuestionDisplayRule: {},
                sectionDisplayRuleJSON: null,
                isLocked: false,
                picklistItems: [
                { 
                  picklistItemName: ''
                }]
              }
            ]
          }
        ]
      }
    ]
  },
  customPsqTemplateDetails: {
    checklistTemplateName: ''
  },
  availableQuestion: [],
  availableQuestionByID: [],
  createdCustomTemplateId: 0,
  checklistDeliverySchedules: [],
  attachmentDetails:[],
  activeCustomPsqTemplateContent: null,
  questionComments: [],
  checklistCounter:[],
  discrepanciesDetail: [],
  lastServiceProviderQuestionId: 0,
  userProfilePictureUrl: '',
  customQuestionLockStatus: {
    isQuestionAvailableForEdit: false,
    isIntentExtensionAvailable: false,
    lockMode: '',
    lockExpirationDateTime: null,
    validationResult: ''
  },
  intentLockUpdateResult: {
    isIntentLockUpdateSuccessful: false,
    lockExpirationDateTime: null,
    validationResult: ''
  },
  bulkProcessManagementData: [],
  dataRetentionManagementData: [],
  bulkRollForwardData: [],
  dataPurgeConfigurationData:[],
  dataPurgeUserAcknowledgementHistoryData:[],  
  upcomingDataDeletionNoticeData:{},
  motifThemes: 'motifLight',
  featureFlags:[],
  dataDeletionDiagnosticData:[],
  externalUserVisibilityData : [],
  incompleteSecuritiesSnapshots: [],
};
