import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Header, Button } from "semantic-ui-react";
import { Table } from "react-bootstrap";
import { MotifButton, MotifModal, MotifModalHeader, MotifModalBody, MotifModalFooter, MotifLabel, MotifInput  } from '@ey-xd/motif-react';
import { Field, } from 'redux-form';
import * as formHelpers from "../../scripts/formHelpers";

const ChartAssignmentConfirmationModal = ({
    showChartAssignmentConfirmationModal,
    toggleShowChartAssignmentConfirmationModal,
    modifiedMappingsArray,
    handleConfirm,
    type,
}) => {
    const [rowRecords, setrowRecords] = useState([]);
    const [confirmationInputText, setConfirmationInputText] = useState('');
    const [saveEnabled, setSaveEnabled] = useState(false);
    useEffect(() => {
        let processedModifiedRecordsArray = [];
        //The first part of the object toggles the set betwwen reitName and propertyName property of the object
        //Then sets the new value and checks if the new value is inherit from REIT so it can places Default(REIT Chart) text if not it togles between the property and reit value
        //The same for the old chart check if the id the old value isInheritFromReit flag and places the Default(REIT Chart) text
        modifiedMappingsArray.forEach(mapping => {
            if (mapping.isDirty.Quarter1) {
                processedModifiedRecordsArray.push({ [type == 'reit' ? 'reitName' : 'propertyName']: mapping.isInheritFromReit == true ? 'Default(REIT Chart)' : type == 'reit' ? mapping.reitName : mapping.propertyName, quarter: "Q1", oldChart: mapping.originalState.Quarter1.isInheritFromReit == true ? 'Default(REIT Chart)' : mapping.originalState.Quarter1.chartName, newChart: mapping.Quarter1.chartName })
            }
            if (mapping.isDirty.Quarter2) {
                processedModifiedRecordsArray.push({ [type == 'reit' ? 'reitName' : 'propertyName']: mapping.isInheritFromReit == true ? 'Default(REIT Chart)' : type == 'reit' ? mapping.reitName : mapping.propertyName, quarter: "Q2", oldChart: mapping.originalState.Quarter2.isInheritFromReit == true ? 'Default(REIT Chart)' : mapping.originalState.Quarter2.chartName, newChart: mapping.Quarter2.chartName })
            }
            if (mapping.isDirty.Quarter3) {
                processedModifiedRecordsArray.push({ [type == 'reit' ? 'reitName' : 'propertyName']: mapping.isInheritFromReit == true ? 'Default(REIT Chart)' : type == 'reit' ? mapping.reitName : mapping.propertyName, quarter: "Q3", oldChart: mapping.originalState.Quarter3.isInheritFromReit == true ? 'Default(REIT Chart)' : mapping.originalState.Quarter3.chartName, newChart: mapping.Quarter3.chartName })
            }
            if (mapping.isDirty.Quarter4) {
                processedModifiedRecordsArray.push({ [type == 'reit' ? 'reitName' : 'propertyName']: mapping.isInheritFromReit == true ? 'Default(REIT Chart)' : type == 'reit' ? mapping.reitName : mapping.propertyName, quarter: "Q4", oldChart: mapping.originalState.Quarter4.isInheritFromReit == true ? 'Default(REIT Chart)' : mapping.originalState.Quarter4.chartName, newChart: mapping.Quarter4.chartName })
            }
        })
        setrowRecords(processedModifiedRecordsArray);
    }, [modifiedMappingsArray])

    const handleConfirmationInput = (e) => {
        setConfirmationInputText(e.target.value);
        if (/^Delete$/gi.test(e.target.value)) {
            setSaveEnabled(true);
        } else {
            setSaveEnabled(false);
        }
    }
    const showHideConfirmationModal = () => {
        setConfirmationInputText('');
        toggleShowChartAssignmentConfirmationModal();
    };

    const closeButtonProps = {
        title: 'Edit Account Notification',
        'aria-label': 'Edit Account Notification',
        'data-testid': 'close',
      };

    return (
        <MotifModal show={showChartAssignmentConfirmationModal} 
                     onClose={showHideConfirmationModal} variant="default" size="lg">
            <MotifModalHeader closeButtonProps={closeButtonProps}>
                Confirm Chart of Accounts Changes
            </MotifModalHeader>
            <MotifModalBody>
                 <form>
                    <Table striped bordered hover>
                        <thead  >
                            <tr>
                                <th>REIT/Property</th>
                                <th>Period</th>
                                <th>Old Chart</th>
                                <th>New Chart</th>
                            </tr>
                            </thead>
                            <tbody>
                                {
                                    rowRecords.map((mapping, index) => {
                                        return <tr key={index}>
                                            <td>{type == 'reit' ? mapping.reitName : mapping.propertyName}</td>
                                            <td>{mapping.quarter}</td>
                                            <td>{mapping.oldChart}</td>
                                            <td>{mapping.newChart}</td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </Table>
                        <MotifLabel>If you change the chart for a period that has TB data, then all the existing TB data, system-created securities, and the associated test results will be deleted. 
                            Type 'Delete' in the box below to confirm you want to make these changes.</MotifLabel>
                        <MotifInput
                            type="text"
                            name="confirmationInput"
                            id="confirmationInput"
                            onChange={handleConfirmationInput}
                            value={confirmationInputText}
                            required={true}
                            placeholder="Delete"
                            style={{ paddingTop: '0', marginTop: '10px' }}
                        />
                        {/* <input type="text" onChange={handleConfirmationInput} value={confirmationInputText} required={true}
                        placeholder="Delete" />    */}
                     </form>
            </MotifModalBody>
            <MotifModalFooter>
                <MotifButton variant="secondary" className="right mt-3"
                        onClick={() => {
                                            setConfirmationInputText('');
                                            toggleShowChartAssignmentConfirmationModal();
                                        }}
                        type="button" >Cancel
                </MotifButton>
                <MotifButton variant="primary" className="right mt-3" onClick={handleConfirm} type="submit" disabled={!saveEnabled}>Confirm</MotifButton>
            </MotifModalFooter>
        </MotifModal>
    );
};

ChartAssignmentConfirmationModal.propTypes = {
    showChartAssignmentConfirmationModal: PropTypes.bool,
    toggleShowChartAssignmentConfirmationModal: PropTypes.func,
    modifiedMappingsArray: PropTypes.array,
    handleConfirm: PropTypes.func,
    theme: PropTypes.string,
};

export default ChartAssignmentConfirmationModal;