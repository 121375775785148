// NOTE: <Field validate={[isRequired('an argument to this function'), maxLength(15)]}/>  is incorrect - ref: https://github.com/erikras/redux-form/issues/2453
import * as formatters from "./formFormatters";
import { generateSubmissionError } from "./formHelpers";

const maxLength = max => value =>
    value && value.length > max ? `Must be ${max} characters or less` : undefined;

const minLength = min => value =>
    value && value.toString().trim().length < min ? `Must be ${min} characters or more` : undefined;

const minValue = min => value =>
    value && value < min ? `Must be at least ${min}` : undefined;

const maxValue = max => value =>
    value && value > max ? `Must be less than or equal to ${max}` : undefined;

const greaterThanValue = min => value =>
    value && value <= min ? `Value must be greater than ${min}.` : undefined;

export const greaterThanValueWhen = (min, action) => value => {
    if (!action()) {
        return undefined;
    }

    return value && value <= min ? `Value must be greater than ${min}.` : undefined;
};

export const lessThanValueWhen = (max, action) => value => {
    if (!action()) {
        return undefined;
    }

    return value && value >= max ? `Value must be less than ${max}.` : undefined;
};

export const required = value => (value && value.toString().trim().length > 0 ? undefined : 'Required');

export const requiredSelect = (value) => {
    if (typeof value === "undefined" || value === null) {
        return "Required";
    }

    if (
        typeof value === "string" &&
        ["", "NotSelected"].includes(value.trim())
    ) {
        return "Required";
    }
};

export const requiredMultiSelect = (value) => {
    if (typeof value === "undefined" || value === null) {
        return "Required";
    }

    if (typeof value === "string" && ["", "NotSelected"].includes(value.trim())) {
        return "Required";
    }

    if (typeof value === "object" && value.length <= 0) {
        return "Required";
    }
};

export const minLength2 = minLength(2);

export const minValue0 = minValue(0);

export const minValue1 = minValue(1);

export const maxLength50 = maxLength(50);

export const maxLength255 = maxLength(255);

export const maxLength900 = maxLength(900);

export const maxLength4000 = maxLength(4000);

export const maxLength12000 = maxLength(12000);

export const maxValue100 = maxValue(100);

export const greaterThan0 = value =>
    value && value <= 0 ? `Value must be greater than 0.` : undefined;

export const greaterThan0AndLessThan10 = value =>
    value && (value <= 0 || value >= 10) ? `Value must be greater than 0 and less than 10.` : undefined;

//SQL INT
export const maxValueInt = value =>
    value && value > 2147483646 ? `Must be less than or equal to 2147483646` : undefined;

export const maxValueDecimal = value =>
    value > BigInt("9999999999999999") ? `Must be less than or equal to 9,999,999,999,999,999` : undefined;

export const number = value =>
    value && isNaN(Number(value)) ? 'Must be a number' : undefined;

export const email = value => {
    if (value && !/^((?=[a-z0-9@.!#$%&'*+/=?^_‘{|}~-]{6,254})(?=[a-z0-9.!#$%&'*+/=?^_‘{|}~-]{1,64}@)[a-z0-9!#$%&'*+/=?^_‘{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_‘{|}~-]+)*@(?:(?=[a-z0-9-]{1,63}\.)[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+(?=[a-z0-9-]{1,63})[a-z0-9](?:[a-z0-9-]*[a-z0-9]))$/gi.test(value)) {
        return 'Invalid email address';
    }

    if (value && value.replace(' ', '') === '') {
        return 'Invalid email address';
    }

    return undefined;
};

export const alphaNumeric = value =>
    value && /[^a-zA-Z0-9 ]/i.test(value)
        ? 'Only alphanumeric characters'
        : undefined;

export const phoneNumber = value =>
    value && !/^([0-9][0-9]{9})$/i.test(value)
        ? 'Invalid phone number, must be 10 digits'
        : undefined;

export const zipCode = value => value && !/^\d{5}(?:[-\s]\d{4})?$/i.test(value)
    ? 'Invalid zip code, must be 99999 or 99999-9999'
    : undefined;

 export const decimal = value => value && !/^\d{0,16}(\.\d{0,2})?$/i.test(value)
    ? 'Input should be a number with up to 16 digits and 2 decimal places'
    : undefined;
export const isValidDate = (value) => {
    if (!value) return undefined;
    //Date could be UTC when loading form, so format it to expected date format
    let formattedDate = formatters.formatDate(value);

    let message = !/^((0?[1-9]|11|10|12)(-|\/)(([1-9])|(0[1-9])|([12])([0-9]?)|(3[01]?))(-|\/)(\d{4}))$/g.test(formattedDate)
        ? 'Must be a valid date'
        : undefined;

    if (message !== undefined)
        return message;

    try {
        let idx = formattedDate.lastIndexOf('/');
        if (idx >= 3 && formattedDate.length > idx + 1) {
            let year = formattedDate.slice(idx + 1);
            if (year.length !== 4) {
                return 'Must be a valid date';
            }
            if (parseInt(year) < 1800) {
                return 'Must be a valid date';
            }
        } else {
            return 'Must be a valid date';
        }

        let dateParts = formattedDate.split("/");
        if (dateParts.length != 3) {
            return 'Must be a valid date';
        }

        let month = parseInt(dateParts[0]);
        let day = parseInt(dateParts[1]);

        let d = new Date(formattedDate);

        if (d.getMonth() != (month - 1) || d.getDate() != day) {
            return 'Must be a valid date';
        }
    }
    catch (e) {
        return 'Must be a valid date';
    }

};

export const multipleEmails = (value) => {
    if (!value) return undefined;

    let sendToEmailValidation = false;
    const sendToEmailsList = (Array.isArray(value) && value) || value.split(";");
    for (let emailItem of sendToEmailsList) {
        if (email(emailItem.trim()) === 'Invalid email address') {
            return 'Invalid email address';
        }
    }

    return undefined;
};

export const ein = (value) => {
    if (!value) return undefined;

    const onlyNums = value.replace(/[^\d]/g, '');
    if (onlyNums.length <= 2) {
        value = onlyNums;
    }
    else {
        value = `${onlyNums.slice(0, 2)}-${onlyNums.slice(2)}`;
    }

    return !/^\d{2}\-\d{7}$/g.test(value)
        ? 'Must be a valid EIN'
        : undefined;
};
export const isWholeNumber = value => value && /[^\d]/g.test(value)
? 'Input should be a whole Number'
: undefined;


export const percentageOfOwnership = (value) => {
    value = value.trim();
    if (value === "") {
        return "";
    }

    if (isNaN(value) || (value > 99.99999999999 && value.length > 14) || (value >= 10 && value <= 99.99999999999 && value.length > 13) || (value < 10 && value.length > 12)) {
        return;
    }

    let indexOfDot = value.indexOf('.');
    if (indexOfDot === value.length - 1) {
        return value;
    }

    if (value > 100.000) {
        return "100";
    }

    //if (value < 1) {
    //    return "1";
    //}

    return value;
};

// This is specific to the Auto Delivery Calendar email fields
// Which should only be required if one of the quarter dates is populated.
// It's used in the context of a form submission validation, not field specific
export const requiredIfQuarterDatePopulated = (id, value, allValues) => {
    const split_ids = id.split('_');
    const propertyTypeAppend = split_ids[3] ? `_${split_ids[3]}` : '';
    const rowId = `${split_ids[1]}_${split_ids[2]}${propertyTypeAppend}`;

    const allQuarterFieldsForThisRecord = Object.keys(allValues).filter(key =>
        key.includes('sendDateQ') && key.includes(rowId));


    const anyQuarterDatePopulated = allQuarterFieldsForThisRecord.some(key => allValues[key] && allValues[key].trim() !== '');
    const valueIsPopulated = value && value.length > 0;

    if (!valueIsPopulated && anyQuarterDatePopulated) {
        return id;
    }

    return null;
}

export const noDoubleQuotes = value => {
    if (!value) return undefined;
};

export const noWindowSpecialChars = value => {
    if (!value) return undefined;

    if (value.indexOf("\\") != -1)
     return 'Backward slash not allowed';

     if (value.indexOf("\/") != -1)
     return 'Forward slash not allowed';

     if (value.indexOf("\|") != -1)
     return 'Pipe symbol not allowed';
};

// This is specific to the Add/Edit Question Picklist Item Text field
// This is to avoid duplicate picklist item getting added to the question
export const noDuplicatePicklistItems = (value, _, props) => {
    if (!value) return undefined;

    if (props && props.currentPicklistItems && props.currentPicklistItems.some(item => item.picklistItemName == value.trim())) {
        return "Given picklist item already exists.";
    }
}


export const maxValueBigInt = value =>
    value && value > 9223372036854775807n ? `Must be less than or equal to 9223372036854775807` : undefined;

export const maxIntValue = value =>
    value && value > 2147483647 ? `Must be less than or equal to 2147483647` : undefined;

export const zeroOrGreater = value =>
    value && value < 0 ? `Value must be 0 or greater.` : undefined;